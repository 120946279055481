<template>
  <div>
    <form-section>
      <template #form-header>
        <div class="absolute top-0 right-0">
          <span class="text-red-400">*</span>
          <span class="normal-case font-thin text-xxs sm:text-sm text-gray-500">
            indicates required
          </span>
        </div>
        <form-section-header>
          <!-- prettier-ignore -->
          <template #title>General</template>
        </form-section-header>
      </template>

      <template #form-body>
        <div id="general" class="w-3/4 xl:w-7/12 px-2 lg:px-0">
          <yes-no-buttons :name="name" @yes="click('yes')" @no="click('no')">
            <template #question>
              <span :class="{ mandatory: !selected }" class="inline-block ">
                Does your child have any particular fears or phobias?
              </span>
            </template>
          </yes-no-buttons>

          <form-text-input
            v-if="phobias"
            :area="true"
            name="general.phobia_details"
            class="md:mt-2"
            title="Nature of the fears or phobias"
            placeholder="Please describe"
          ></form-text-input>

          <form-text-input
            :area="true"
            class="mt-4"
            name="general.other_details"
            placeholder="Please describe"
            title="Are there any other details you think it would be helpful for us to know to help ensure a safe and enjoyable time for your child?"
          ></form-text-input>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import YesNoButtons from '@/components/button-group-yes-no-row.vue';
import { store } from '@/store.js';

export default {
  components: {
    YesNoButtons,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      name: 'general.fears',
      phobias: undefined,
      selected: store.booking.find('general.fears') !== undefined,
    };
  },

  watch: {
    selected: {
      handler: function(val) {
        this.$root.$emit(
          val ? 'mandatory-complete' : 'mandatory-incomplete',
          this.section
        );
      },
      immediate: true,
    },
  },

  created() {
    // Inital selected state comes from the data source
    this.phobias = store.booking.find('general.fears') || false;
    // Section is complete as long as either button has been clicked.
    this.$emit('entry', this.section, this.selected);
  },

  methods: {
    click(choice) {
      this.phobias = choice === 'yes';
      this.selected = true;
      this.$emit('entry', this.section, true);
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  margin-left: 3px;
  color: #fc8181;
  content: '*';
}
</style>
