<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <template #title>
            Activities
          </template>
        </form-section-header>
      </template>

      <template #form-body>
        <div id="activities" class="md:mb-4 px-2 lg:px-0">
          <form-text-input
            :area="true"
            name="activities.ok"
            title="What activities does your child generally enjoy?"
            placeholder="Please describe"
          ></form-text-input>

          <form-text-input
            :area="true"
            name="activities.nok"
            title="Are there any activities that your child does NOT enjoy?"
            placeholder="Please describe"
          ></form-text-input>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phobias: false,
    };
  },

  methods: {
    selectedYes() {
      this.phobias = true;
    },

    selectedNo() {
      this.phobias = false;
    },
  },
};
</script>
