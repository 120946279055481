<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <!-- prettier-ignore -->
          <template #title>Disability and/or Special Needs</template>
          <template #info>
            <span class="hidden lg:block">
              Please provide as much relevant information as possible
            </span>
          </template>
        </form-section-header>
      </template>

      <template #form-body>
        <div
          class="flex flex-col flex-wrap px-2 md:flex-row md:w-2/3 lg:px-0 md:mb-2"
        >
          <form-text-input
            :area="true"
            name="disability.behaviour_details"
            :title="title('behaviour')"
          ></form-text-input>
        </div>
      </template>
    </form-section>

    <!-- Not true sections but break down sub-sections for each type of disability -->
    <!-- Epileptic? -->
    <div class="md:mb-6">
      <disability-section-question-block
        question="Does your child have epilepsy or seizures?"
        :prompt="title('epilepsyDetails')"
        :mandatory-details="true"
        name="disability.epilepsy"
        @select="selected"
      >
        <div v-if="epileptic" class="w-full md:w-3/4">
          <button-group-yes-only name="disability.epilepsy.disclaimer">
            <template #question>
              <span class="inline-block mr-8 text-base font-semibold">
                I have disclosed everything I can about my child's epilepsy or
                seizures. I understand that, in order to keep my child safe at
                club, I <span class="font-extrabold">must</span> also email a
                care plan and/or epilepsy or seizure protocol at least two weeks
                before Club.<br />
                If I do not do so, I understand that my child will not be able
                to attend club as it would be unsafe.
                <span style="color:#fc8181">*</span>
              </span>
            </template>
          </button-group-yes-only>
        </div>
      </disability-section-question-block>

      <!-- Asthmatic? -->
      <disability-section-question-block
        question="Does your child have asthma"
        name="disability.asthma"
      ></disability-section-question-block>

      <!-- Diabetic? -->
      <disability-section-question-block
        question="Is your child diabetic?"
        :prompt="title('diabetesDetails')"
        name="disability.diabetes"
      ></disability-section-question-block>

      <!-- Allergies? -->
      <disability-section-question-block
        question="Does your child have any allergies?"
        :prompt="title('allergiesDetails')"
        name="disability.allergies"
      ></disability-section-question-block>
    </div>
  </div>
</template>

<script>
import DisabilitySectionQuestionBlock from '@/components/disability-section-question-block.vue';
import { store } from '@/store.js';
import ButtonGroupYesOnly from '../button-group-yes-only.vue';

export default {
  components: {
    DisabilitySectionQuestionBlock,
    ButtonGroupYesOnly,
  },

  data() {
    return {
      allergies: false,
      asthma: false,
      diabetes: false,
      epilepsy: false,
      epileptic: false,
      other: false,
    };
  },

  created() {
    this.epileptic = store.booking.find('disability.epilepsy') === true;
  },

  methods: {
    selected(opts) {
      if (opts.name === 'disability.epilepsy') {
        this.epileptic = opts.selected;
      }
    },

    title(name) {
      return {
        allergiesDetails: 'Please give details',
        behaviour:
          'Please include all diagnoses and any additional needs/concerns you feel we should know about',
        epilepsyDetails:
          'Please give details of medication required and describe the form seizures/fits take',
        diabetesDetails:
          'Please give details of medication or special requirements',
        otherDetails: 'Please give details',
      }[name];
    },
  },
};
</script>
