<template>
  <form-section>
    <template #form-header>
      <div class="absolute top-0 right-0">
        <span class="text-red-400">*</span
        ><span class="font-thin text-gray-500 normal-case text-xxs sm:text-sm">
          indicates required</span
        >
      </div>
      <form-section-header>
        <template #title>
          <div class="relative">
            <div>
              Who is coming to the club?
            </div>
          </div>
        </template>
        <template #info>
          <div class="flex flex-col">
            <div class="flex flex-col">
              <div class="hidden sm:block">
                Basic information about your young person
              </div>
            </div>
            <div v-if="canremove" class="mt-6" @click="remove">
              <div
                class="flex items-center text-red-300 justify-left hover:text-red-600"
              >
                <icon-trash></icon-trash>
                <span
                  class="ml-2 text-red-300 text-xxs md:text-xs hover:text-red-600"
                  >Remove
                  <span class="text-blue-400 uppercase semibold">{{
                    name
                  }}</span>
                  from booking request</span
                >
              </div>
            </div>
          </div>
        </template>
      </form-section-header>
    </template>

    <template #form-body>
      <div
        id="participant"
        class="flex flex-col flex-wrap w-full px-2 lg:flex-row lg:px-0 lg:mt-6"
      >
        <div class="md:w-1/3">
          <form-text-input
            name="participant.name"
            placeholder="Name"
            title="First Name"
            @user-entry="capture"
          ></form-text-input>
        </div>
        <div class="md:w-1/3">
          <form-text-input
            name="participant.surname"
            placeholder="Surname"
            title="Last Name"
            @user-entry="capture"
          ></form-text-input>
        </div>
        <div class="w-1/3">
          <form-dob-input
            :range="dobRange"
            @user-entry="capture"
          ></form-dob-input>
        </div>
        <div class="flex flex-col w-full md:flex-row md:mt-3">
          <div class="w-1/2 pr-12">
            <form-select
              name="participant.gender"
              title="Gender"
              :options="options.gender"
              @user-entry="capture"
            ></form-select>
          </div>
          <div class="w-1/2">
            <div class="mr-4">
              <form-select
                name="participant.ethnicity"
                title="Ethnic Origin"
                info="ethnicity"
                :options="options.ethnicity"
                @user-entry="capture"
                @info="showInfo"
              ></form-select>
            </div>
          </div>
        </div>
      </div>

      <modal-help v-if="infoEthnicity" @ok="infoEthnicity = false">
        <div slot="header">Why are we asking this?</div>
        <div slot="helptext">{{ info.ethnicity }}</div>
      </modal-help>
    </template>
  </form-section>
</template>

<script>
import IconTrash from '@/assets/icons/trash.vue';
import moment from 'moment';
import ModalHelp from '@/components/modal-help.vue';
import { store } from '@/store.js';

import FormDobInput from '@/components/form-dob-input';

export default {
  components: {
    FormDobInput,
    IconTrash,
    ModalHelp,
  },

  props: {
    multi: { type: Boolean, default: false },
    section: { type: String, default: '' },
  },

  data() {
    return {
      entered: {
        'participant.name': false,
        'participant.surname': false,
        'participant.dob': false,
        'participant.gender': false,
        'participant.ethnicity': false,
      },

      info: {
        ethnicity:
          'The reason for asking this question is that when we apply for grants etc we are often asked to provide this information and it can assist us in securing funding',
      },

      infoEthnicity: false,

      noinput: store.request.locked(),

      options: {
        ethnicity: [
          { id: 1, value: '-', text: 'Please choose', selected: false },
          {
            id: 2,
            value: 'white-british',
            text: 'White British',
            selected: false,
          },
          {
            id: 3,
            value: 'white-other',
            text: 'White Other',
            selected: false,
          },
          {
            id: 4,
            value: 'asian',
            text: 'Asian',
            selected: false,
          },
          {
            id: 5,
            value: 'chinese',
            text: 'Chinese',
            selected: false,
          },
          {
            id: 6,
            value: 'black',
            text: 'Black',
            selected: false,
          },
          {
            id: 7,
            value: 'not-stated',
            text: 'Not stated',
            selected: false,
          },
        ],
        gender: [
          { id: 1, value: '-', text: 'Please choose', selected: false },
          { id: 2, value: 'male', text: 'Male', selected: false },
          { id: 3, value: 'female', text: 'Female', selected: false },
          { id: 4, value: 'other', text: 'Other', selected: false },
        ],
      },
      dobRange: {
        min: undefined,
        max: undefined,
      },
    };
  },

  computed: {
    name() {
      return store.booking.find('participant.name');
    },

    canremove() {
      return this.multi && !store.request.locked();
    },
  },

  created() {
    // store.dates is being populated in the dates component
    const dayone = store.dates.get()[0].date;
    // Setup disabled date range based on the first day club will open.
    // As long as they are not yet 20 on club start date then they can come
    this.dobRange.min = moment(dayone)
      .subtract(20, 'y')
      .add(1, 'd');
    this.dobRange.max = moment(dayone).subtract(3, 'y');

    Object.keys(this.entered).forEach(k => {
      this.entered[k] = store.booking.find(k) ? true : false;
    });
    this.check_complete();
  },

  methods: {
    capture(...[name, val]) {
      this.entered[name] = val ? true : false;
      this.check_complete();
    },

    check_complete() {
      const done = Object.values(this.entered).every(b => b);
      this.$emit('entry', this.section, done);
    },

    remove() {
      this.$emit('remove', store.booking.data.id);
    },

    showInfo(type) {
      switch (type) {
        case 'ethnicity':
          this.infoEthnicity = true;
          break;

        default:
          break;
      }
    },

    toString(name) {
      const date = moment(store.booking.find(name));
      return date.format('D MMM YYYY');
    },
  },
};
</script>
