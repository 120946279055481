<template>
  <page-accordion class="md:mt-2" :open="newBooking">
    <header slot="title">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center">
          <!-- prettier-ignore -->
          <missing-icon v-if="!completed" class="w-4 h-4 mr-2 text-red-500"></missing-icon>
          <div v-if="children.length === 1" class="mr-4">Participant</div>
          <div v-else class="mr-4">Participants</div>
        </div>
        <div
          v-if="children.length"
          class="flex flex-col items-end sm:flex-row sm:items-center"
        >
          <div
            v-for="(child, idx) in children"
            :key="child.id"
            :class="idx === 0 ? 'ml-4' : 'ml-2 mt-2 sm:mt-0'"
          >
            <form-tab-button
              :id="child.id"
              active
              :immediate="false"
              :title="child['participant.name']"
              @selected="selected(child.id)"
            ></form-tab-button>
          </div>
        </div>
      </div>
    </header>
    <section
      slot="content"
      class="flex flex-col py-2 space-y-2 md:space-y-6 md:py-6"
    >
      <participant-section
        section="participant"
        :multi="children.length > 1"
        @entry="updateMandatory"
        @remove="removeChild"
      ></participant-section>
      <address-section
        section="address"
        @entry="updateMandatory"
      ></address-section>
      <school-section
        section="school"
        @entry="updateMandatory"
      ></school-section>
    </section>
  </page-accordion>
</template>

<script>
import AddressSection from '@/components/sections/address-section.vue';
import FormTabButton from '@/components/form-tab-button.vue';
import MissingIcon from '@/assets/icons/star.vue';
import ParticipantSection from '@/components/sections/participant-section.vue';
import SchoolSection from '@/components/sections/school-section.vue';
import { store } from '@/store.js';

export default {
  components: {
    AddressSection,
    FormTabButton,
    MissingIcon,
    ParticipantSection,
    SchoolSection,
  },

  props: {
    children: { type: Array, default: () => [] },
  },

  data() {
    return {
      mandatory: {
        participant: true,
        address: false,
        school: true,
      },
    };
  },

  computed: {
    // Only exists to switch on/off the star icon in the banner.
    completed() {
      return Object.values(this.mandatory).every(b => b);
    },

    newBooking() {
      if (this.children.length <= 0) return true;
      return (
        Object.prototype.hasOwnProperty.call(store, 'booking') &&
        Object.keys(store.booking.data).length > 0
      );
    },
  },

  methods: {
    removeChild(id) {
      this.$emit('remove', id);
    },

    selected(id) {
      this.$emit('switch', id);
    },

    updateMandatory(...[name, complete]) {
      this.mandatory[name] = complete;
    },
  },
};
</script>
