<template>
  <div class="flex flex-col mt-4  mb-2 w-full">
    <div class="flex items-center">
      <div class="w-1/2 inline-block text-gray-600 text-base md:text-lg">
        <span>Has your child been known to bite?</span>
      </div>
      <div class="bg-blue-400x">
        <yes-no-button
          :name="name"
          @yes="selected('yes')"
          @no="selected('no')"
        ></yes-no-button>
      </div>
    </div>

    <biting-questions v-if="bites">
      <div
        slot="message"
        class="my-2 p-2 rounded border-2 border-gray-300 bg-gray-100 text-sm italic text-gray-600"
      >
        {{ message }}
      </div>
    </biting-questions>
  </div>
</template>

<script>
import BitingQuestions from '@/components/sections/behaviour-biting-questions.vue';
import YesNoButton from '@/components/button-group-yes-no-row.vue';
import { store } from '@/store.js';

export default {
  components: {
    BitingQuestions,
    YesNoButton,
  },

  data() {
    return {
      bites: false,
      message: [
        'In the case of a human bite, local NHS services will likely ask for the following information about the ',
        'person who bit. This information is provided in confidence and will only be passed (anonymised) to medical ',
        'professionals asking these questions in order to best treat the person who has received the bite',
      ].join(''),
      name: 'behaviour.options_1',
    };
  },

  mounted() {
    this.bites = store.booking.find(this.name);
  },

  methods: {
    selected(val) {
      this.bites = val === 'yes' ? true : false;
    },
  },
};
</script>

<style scoped>
.exg__details {
  outline: 1px solid tomato;
}
</style>
