<template>
  <div
    class="flex flex-col justify-between items-center w-full h-screen bg-indigo-100"
  >
    <div
      class="flex flex-col items-center mt-8 lg:mt-16 p-4 w-11/12 lg:w-3/5 xl:w-1/3 rounded-lg shadow-xl bg-white"
    >
      <div class="w-full flex flex-col items-center ">
        <h2 class="text-xl lg:text-3xl text-center text-indigo-800">
          Register with Thumbs Up Club
        </h2>
        <h4 class="text-sm italic text-indigo-700">
          Enter a valid email address to create your account with us
        </h4>
      </div>
      <div class="w-full flex flex-col p-3 lg:px-8 lg:py-8">
        <div v-if="message" class="flex mb-4 text-red-800">
          <svg
            class="h-6 w-6 p-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              class="fill-current"
              d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
            />
          </svg>
          <span class="ml-1 font-semibold">{{ message }}</span>
        </div>

        <div class="flex mt-2 items-center justify-left text-center">
          <div class="w-full">
            <input
              v-model="userid"
              class="w-full py-1 pl-10 text-lg border-2 border-gray-300"
              type="email"
              name="userid"
              placeholder="Email"
            />
          </div>
          <div class="absolute">
            <svg
              class="h-6 w-6 p-1 ml-2 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                class="fill-current"
                d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"
              />
            </svg>
          </div>
        </div>

        <div class="mt-8 mb-4">
          <button
            class="w-full py-2 bg-green-500 rounded text-white font-semibold"
            @click="register"
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      userid: '',
    };
  },

  // All the methods for registering which were cloned from the TVAP app expect
  // to find a token in the request. The TVAP app manages this in the state
  // machine but TUC pre-dates that so get a token every time the user asks to
  // register.
  async created() {
    try {
      const session = await this.$http.get('session/start');
      this.$http.defaults.headers.common['Authorization'] = session.data.token;
    } catch (error) {
      this.message = 'There was an error, please try again';
    }
  },

  methods: {
    dismiss() {
      window.location.replace('email-sent.html');
    },

    async register() {
      if (this.userid) {
        try {
          await this.$http.post('invite', {
            user: { id: this.userid },
          });
          this.dismiss();
        } catch (error) {
          if (error.response.data.message.toLowerCase() === 'user exists') {
            this.message = 'Email has already been registered';
          } else {
            this.message = 'There was an error, please try again';
          }
        }
      } else {
        this.message = 'Please enter a valid email address';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
