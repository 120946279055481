<template>
  <page-accordion>
    <header slot="title">
      <div class="flex items-center">
        <!-- prettier-ignore -->
        <missing-icon v-if="!completed" class="h-4 w-4 text-red-500 mr-2"></missing-icon>
        <div>General</div>
      </div>
    </header>
    <section slot="content">
      <general-section
        section="general"
        @entry="updateMandatory"
      ></general-section>
      <activities-section></activities-section>
    </section>
  </page-accordion>
</template>

<script>
import ActivitiesSection from '@/components/sections/activities-section.vue';
import GeneralSection from '@/components/sections/general-section.vue';
import MissingIcon from '@/assets/icons/star.vue';

export default {
  components: {
    ActivitiesSection,
    GeneralSection,
    MissingIcon,
  },

  data() {
    return {
      mandatory: {
        general: false,
      },
    };
  },

  computed: {
    // Only exists to switch on/off the star icon in the banner.
    completed() {
      return Object.values(this.mandatory).every(b => b);
    },
  },

  methods: {
    // Each section is responsible for knowing when it's mandatory fields have
    // been entered and sends true/false after every user input.
    updateMandatory(...[name, complete]) {
      this.mandatory[name] = complete;
    },
  },
};
</script>
