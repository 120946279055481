<template>
  <form-section>
    <template #form-header>
      <form-section-header>
        <template #title> {{ season }}</template>
        <template #info>
          <div class="flex flex-col">
            <div v-if="!submitted" class="flex flex-col">
              <span>{{ info }}</span>
              <p v-if="!grant" class="mt-4 italic text-red-400">
                You must enter a date of birth before you can choose dates
              </p>
            </div>
            <!-- 
                Alternate dates - implemented for Summer 2021 but ultimately 
                switched off
            -->
            <div v-if="false" class="flex flex-col mt-8">
              <p>Choose alternate dates?</p>
              <p class="text-xs text-gray-500">
                These will only be used if we cannot accomodate any of your
                first choice dates. Choose up to {{ maxChoices }} (optional)
              </p>
              <div class="grid grid-cols-2">
                <div
                  v-for="(dt, idx) in unselected()"
                  :key="idx"
                  class="flex items-center justify-around py-1"
                >
                  <input
                    v-if="
                      !requestLocked && (moreChoices() || choices[idx] === true)
                    "
                    type="checkbox"
                    :checked="tickedChoice(dt)"
                    :name="`choice-${idx}`"
                    @click="selectedSecondChoice"
                  />
                  <input
                    v-else
                    type="checkbox"
                    :checked="tickedChoice(dt)"
                    name="`choice-${idx}`"
                    class="cursor-not-allowed"
                    disabled
                  />
                  <label
                    :for="`choice-${idx}`"
                    class="w-full ml-2 text-sm"
                    :class="{ 'text-gray-500': requestLocked }"
                    >{{ dt | dt }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </form-section-header>
    </template>

    <template #form-body>
      <div id="dates" class="flex justify-center w-full mt-4">
        <div
          class="flex flex-col flex-wrap items-center justify-between w-full sm:flex-row xl:justify-end md:w-full"
        >
          <div
            v-for="(date, idx) in available"
            :key="`${date.date}.${date.special ? 1 : 0}`"
          >
            <dates-section-date
              ref="date"
              :accepted="accepted[idx] || false"
              :date="date"
              :name="date.date"
              @selected="selectDate"
              @deselected="deselectDate"
            ></dates-section-date>
          </div>
        </div>
      </div>
    </template>
  </form-section>
</template>

<script>
import DatesSectionDate from '@/components/dates-section-date';
import { eventBus } from '@/main.js';
import moment from 'moment';
import { store } from '@/store.js';

//TODO this cannot be hard-coded!!!
const MAX_DATES = 4;

export default {
  filters: {
    dt(d) {
      return moment(d).format('ddd, MMM D');
    },
  },

  components: {
    DatesSectionDate,
  },

  props: {
    dates: { type: Array, default: () => [] },
    section: { type: String, default: '' },
  },

  data() {
    return {
      accepted: [],
      altdates: [],
      available: Array.from(this.dates),
      choices: Array(15).fill(false),
      countSelected: 0,
      grant: false,
      info: '',
      requestLocked: store.request.locked(),
      season: '',
      showSecondChoice: false,
    };
  },

  computed: {
    chosenMaxDates() {
      return this.countSelected >= MAX_DATES;
    },

    maxChoices() {
      return 3;
    },

    submitted() {
      return store.request.locked();
    },
  },

  watch: {
    countSelected(val) {
      this.$emit('entry', this.section, val > 0);
    },
  },

  mounted() {
    //TODO db call to get this text
    this.info = `Click on the dates you would like to book (click again to deselect). You can request up to a maximum of ${MAX_DATES} days.`;
    this.season = 'Easter Club 2024';

    // Start with disabled dates unless there already is a date of birth and
    // the maximum allowed has not already been reached.
    this.grant = !!this.getBookingProperty('participant.dob');
    this.enableDates((this.countSelected < MAX_DATES) & this.grant);

    // Trying to use the dob-entry event to clear selected dates had the
    // side-effect of messing up the localStorage so use a dedicated event.
    eventBus.$on('form-clear', cleared => {
      if (cleared) {
        this.grant = false;
        this.resetDates();
      }
    });

    // Listen for when the date of birth has been entered - only enable date
    // selection when this happens
    eventBus.$on('dob-entry', enable => {
      this.grant = enable;

      if (enable) {
        // Some dates are only shown for kids over a certain age
        this.getAge().then(age => {
          //TODO Get the age limit into a .env file. Obviously.
          this.available =
            age < 13
              ? this.dates.filter(dt => !dt.restricted)
              : (this.available = [...this.dates]);

          this.enableDates(enable);
        });
      } else {
        // DOB has been deleted so all booked dates must also now be cleared
        this.$root.$emit('formEntry', {
          name: 'booking.dates',
          value: '-',
        });
        this.resetDates();
      }
    });

    // altdates is an array of "YYYY-MM-DD" dates - use it to update the choices
    // array corresponding to each of the available, unselected dates
    this.altdates = this.getBookingProperty('booking.altdates');
    const unselected = this.unselected();
    if (
      Array.isArray(unselected) &&
      Array.isArray(this.altdates) &&
      unselected.length > 0
    ) {
      for (const dt of this.altdates) {
        const idx = unselected.findIndex(un => moment(un).isSame(dt));
        if (idx >= 0) {
          this.choices[idx] = true;
        }
      }
    }
  },

  created() {
    // Highlight dates already selected
    if (!this.getBookingProperty('booking.dates')) return;

    //!
    // Bugfix. If a request had > 1 booking then viewing the first booking dates
    // would be fine but the second booking would show the 'on' dates for the
    // first booking as well as it's own (ie if the two bookings were for
    // different days).
    // It looks as though the available array is being retained when the booking
    // selection is changed so just clear it down here.
    this.available = this.available.map(avail => ({ ...avail, on: false }));
    //!

    this.getBookingProperty('booking.dates').forEach((d, didx) => {
      let idx = this.available.findIndex(el => moment(el.date).isSame(d));
      if (idx >= 0) {
        this.available[idx]['on'] = true;
        this.accepted[idx] = store.dates.accepted(0)[didx] === 1 ? true : false;
        this.countSelected++;
      }
    });

    this.$emit('entry', this.section, this.countSelected > 0);
  },

  methods: {
    deselectDate() {
      if (this.countSelected > 0) {
        this.countSelected--;
      } else {
        this.countSelected = 0;
      }
      this.updateDates();
    },

    enableDates(enable = true) {
      if (this.$refs.date) {
        this.$refs.date.forEach(dt => (enable ? dt.enable() : dt.disable()));
      }
    },

    /**
     * Get the age of the child as at the first day of club.
     * Needs to run in next event loop because the dob may not yet have
     * arrived in the root and made it's way into the `store`
     */
    getAge() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (!store || !Object.prototype.hasOwnProperty.call(store, 'booking')) {
          return resolve(0);
        } else {
          this.$nextTick(() => {
            return resolve(store.booking.age(this.dates[0].date));
          });
        }
      });
    },

    getBookingProperty(name) {
      if (
        !store ||
        !Object.prototype.hasOwnProperty.call(store, 'booking') ||
        !Object.prototype.hasOwnProperty.call(store.booking.json, name)
      ) {
        return undefined;
      } else {
        return store.booking.json[name];
      }
    },

    moreChoices() {
      return this.choices.filter(Boolean).length < this.maxChoices;
    },

    resetDates() {
      if (this.$refs.date) {
        this.$refs.date.forEach(dt => dt.disable(true));
      }
      this.countSelected = 0;
      this.choices.length = 0;
    },

    selectDate() {
      // eslint-disable-next-line
      console.log('selected');
      this.countSelected++;
      this.updateDates();
    },

    selectedSecondChoice(ev) {
      const idx = Number(ev.target.name.split('-')[1]);
      const newval = !this.choices[idx];
      this.$set(this.choices, idx, newval);

      /**
       * Second choice dates are being added for Summer 2021 but there's not
       * much time and I don't want to disturb the existing code. These dates
       * will be stored separately in the database too since they are only needed
       * if one or more of the 5 first choice dates is not available.
       */

      this.$root.$emit('formEntry', {
        name: 'booking.altdates',
        value: this.unselected()
          .map((dt, idx) => (this.choices[idx] ? dt : false))
          .filter(Boolean),
      });
    },

    tickedChoice(dt) {
      if (!this.altdates || !Array.isArray(this.altdates)) return;
      return this.altdates.findIndex(alt => moment(dt).isSame(alt)) >= 0;
    },

    unselected() {
      // Once the max dates have been selected, offer the option to choose
      // choices dates
      if (!this.$refs.date) return [];
      return this.$refs.date.filter(dt => !dt.selected).map(dt => dt.date.date);
    },

    updateDates() {
      this.enableDates(this.countSelected < MAX_DATES);

      // Pass all currently selected dates back to the root listener
      //* As an array of dates ["2020-04-07", "2020-04-08"]
      this.$root.$emit('formEntry', {
        name: 'booking.dates',
        value: this.$refs.date
          .filter(dt => dt.selected)
          .map(dt => dt.date.date),
      });
    },
  },
};
</script>
