<template>
  <modal>
    <div slot="body" class="mb-2 md:mb-4">
      <div class="flex flex-col">
        <div class="text-gray-600 text-sm md:text-base">
          <div class="flex flex-col">
            <div
              class="flex w-full justify-between items-center px-2 py-2 md:px-4 md:py-2 bg-indigo-500 text-white font-bold text-xs md:text-xl rounded-none md:rounded-t shadow-lg"
            >
              <slot name="header"></slot>
              <div class="h-4 w-4 md:h-6 md:w-6 cursor-pointer" @click="close">
                <close-icon type="outline" @click="close"></close-icon>
              </div>
            </div>
            <div class="mx-4 mt-2 md:mt-4">
              <div v-if="processed">
                <div v-if="success">
                  <p class="my-2">
                    Thank you. Please arrange payment within 5 days
                  </p>
                </div>
                <div v-if="error">
                  <p class="my-2">
                    Sorry - we are unable to handle your request at the moment.
                  </p>
                  <p>Please try again later.</p>
                </div>
              </div>
              <div v-else>
                <p class="mt-2 mb-2">
                  The cost of this booking will be &#163;{{
                    request.payment.amount_due
                  }}
                </p>
                <p>
                  After you click the button to pay, you will have 5 days in
                  which to arrange payment by one of our registered Childcare
                  Voucher providers.
                </p>
              </div>
            </div>
            <div
              v-if="!processed"
              style="min-height:min-content"
              class="flex justify-around mt-2 md:mt-4 mb-2 md:mb-4 pt-2 md:pt-4"
            >
              <form-select-button
                allow-input
                silent
                classes="px-8 py-4 hover:text-white hover:bg-indigo-400"
                title="Pay"
                @selected="pay"
              ></form-select-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseIcon from '@/assets/icons/close.vue';
import FormSelectButton from '@/components/form-select-button.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    CloseIcon,
    FormSelectButton,
    Modal,
  },

  props: {
    request: { type: Object, default: () => {} },
  },

  data() {
    return {
      error: false,
      processed: false,
      success: false,
    };
  },

  methods: {
    close() {
      this.$emit('ok');
    },

    pay() {
      this.$http
        .post(`request/voucher/${this.request.id}`)
        .then(res => {
          this.success = res.data.success;
          this.processed = true;
        })
        .catch(() => {
          this.error = true;
          this.processed = true;
        });
    },
  },
};
</script>
