<template>
  <div class="flex flex-col justify-between items-center w-full h-screen">
    <div class="flex flex-col items-center mt-2 lg:mt-4 w-full">
      <div
        class="flex flex-col w-11/12 lg:w-3/5 xl:w-1/3 p-3 lg:px-8 lg:py-8 bg-white rounded-lg shadow-xl"
      >
        <div class="mb-1 lg:mb-2">
          <h2 class="text-xl lg:text-3xl text-center text-blue-800">
            Finish setting up your account
          </h2>
        </div>

        <div v-if="error !== ''" class="flex mb-4 text-red-800">
          <svg
            class="h-12 w-12 p-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              class="fill-current"
              d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
            />
          </svg>
          <span class="ml-1 font-semibold">{{ error }}</span>
        </div>

        <div class="bg-white p-4 pb-6">
          <div class="flex flex-col mt-2 mb-6">
            <span class="font-base text-xs uppercase">email address</span>
            <span class="pl-2 text-lg font-base text-gray-600">
              {{ userid }}
            </span>

            <span class="mandatory mt-4 font-base text-xs uppercase"
              >Your first name</span
            >
            <input
              v-model="name[0]"
              type="text"
              class="pl-2 py-1 text-lg border-2 border-gray-200"
            />

            <span class="mandatory mt-2 font-base text-xs uppercase"
              >Your last name</span
            >
            <input
              v-model="name[1]"
              type="text"
              class="pl-2 py-1 text-lg border-2 border-gray-200"
            />
          </div>

          <div class="mt-4 text-gray-600">
            <span class="mandatory text-xs uppercase font-base">
              new password for your account</span
            >
          </div>

          <div class="flex items-center justify-left text-center">
            <div class="w-full">
              <input
                ref="password"
                v-model="password"
                class="w-full py-1 pl-10 text-lg border-2 border-gray-300 "
                type="password"
                name="password"
                placeholder="Password"
              />
            </div>
            <div class="absolute">
              <svg
                class="h-6 w-6 p-1 ml-2 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  class="fill-current"
                  d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
                />
              </svg>
            </div>
            <div class="text-blue-400" @click="toggle('password')">
              <template v-if="showing.password">
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M 0.2 10 a 11 11 0 0 1 19.6 0 A 11 11 0 0 1 0.2 10 Z m 9.8 4 a 4 4 0 1 0 0 -8 a 4 4 0 0 0 0 8 Z m 0 -2 a 2 2 0 1 1 0 -4 a 2 2 0 0 1 0 4 Z"
                  />
                </svg>
              </template>
            </div>
          </div>

          <div class="mt-2 text-gray-600">
            <span class="mandatory text-xs uppercase font-base">
              please confirm password</span
            >
          </div>
          <div class="flex items-center justify-left text-center">
            <div class="w-full">
              <input
                ref="confirm"
                v-model="confirm"
                class="w-full py-1 pl-10 text-lg border-2 border-gray-300 "
                type="password"
                name="confirm"
                placeholder="Enter password again"
              />
            </div>
            <div class="absolute">
              <svg
                class="h-6 w-6 p-1 ml-2 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  class="fill-current"
                  d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
                />
              </svg>
            </div>
            <div class="text-blue-400" @click="toggle('confirm')">
              <template v-if="showing.confirm">
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M 0.2 10 a 11 11 0 0 1 19.6 0 A 11 11 0 0 1 0.2 10 Z m 9.8 4 a 4 4 0 1 0 0 -8 a 4 4 0 0 0 0 8 Z m 0 -2 a 2 2 0 1 1 0 -4 a 2 2 0 0 1 0 4 Z"
                  />
                </svg>
              </template>
            </div>
          </div>
        </div>

        <div v-if="ready" class="mt-8 mb-4">
          <button
            class="w-full py-2 bg-green-500 rounded text-white font-semibold"
            @click="save"
          >
            Save
          </button>
        </div>
        <div
          v-else
          class="flex justify-center mt-8 mb-4 w-full py-2 bg-gray-500 rounded text-white font-semibold"
        >
          <span>Save</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jwtdecode from 'jwt-decode';

export default {
  data() {
    return {
      confirm: '',
      error: '',
      name: ['', ''],
      password: '',
      showing: { confirm: false, password: false },
      userid: '',
    };
  },

  computed: {
    ready() {
      return !!(
        this.valid() &&
        this.name[0].length > 1 &&
        this.name[1].length > 1
      );
    },
  },

  created() {
    try {
      const token = this.$route.query.token;
      this.$http.defaults.headers.common['Authorization'] = token;
      this.userid = jwtdecode(token).id;

      // Guard against the registration email being clicked again after user
      // has completed registration
      this.$http
        .get(`user/${this.userid}`)
        .then(() => this.$router.push({ name: 'login-form' }));
    } catch (error) {
      this.error = 'Unable to process your registration. Please email support.';
    }
  },

  methods: {
    async save() {
      // 2 possible user statuses:
      //   'new' The user has signed up and a temp record has been created
      //   'active' The user has chosen a pw and completed their personal info

      const body = [
        {
          id: this.userid,
          user: {
            id: this.userid,
            password: this.password,
            status: 'active',
          },
        },
        {
          id: this.userid,
          user: {
            name: this.name[0],
            surname: this.name[1],
          },
        },
      ];

      // TUC is simpler than TVAP so updating password and personal info is done in
      // one shot - but using the same API as TVAP
      try {
        await this.$http.post('password/set', body[0]);
        await this.$http.post('info', body[1]);
        this.$router.push({ name: 'login-form' });
      } catch (error) {
        this.error =
          error.response.status === 401
            ? 'Password must be 8-16 characters and must contain a mix of uppercase and lowercase characters and at least one number'
            : 'An error has occurred . please try again';
      }
    },

    toggle(field) {
      // show/hide the password being entered
      const input = this.$refs[field];
      input.type = input.type === 'text' ? 'password' : 'text';

      // set a flag to control which icon is used to toggle the password display
      this.showing[field] = this.$refs[field].type === 'text';
    },

    valid() {
      return this.password.length && this.password === this.confirm;
    },
  },
};
</script>

<style scoped>
span.mandatory::after {
  color: #e53e3e;
  content: '*';
  margin-left: 3px;
}
</style>
