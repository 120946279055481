<template>
  <page-accordion>
    <header slot="title">
      <div class="flex items-center">
        <!-- prettier-ignore -->
        <missing-icon v-if="!completed" class="h-4 w-4 text-red-500 mr-2"></missing-icon>
        <div>Medical Information</div>
      </div>
    </header>
    <section slot="content">
      <gp-section section="gp" @entry="updateMandatory"></gp-section>
      <medication-consent-section
        class="md:mt-2"
        section="medicationconsent"
        @entry="updateMandatory"
      ></medication-consent-section>
      <medication-section
        placeholder="Medication required to be administered by club staff"
        @entry="updateMandatory"
      ></medication-section>
      <disability-section></disability-section>
      <medication-treatment-consent-section
        section="treatmentconsent"
        @entry="updateMandatory"
      ></medication-treatment-consent-section>
    </section>
  </page-accordion>
</template>

<script>
import DisabilitySection from '@/components/sections/disability-section.vue';
import GpSection from '@/components/sections/gp-section.vue';
import MedicationConsentSection from '@/components/sections/medication-consent-section.vue';
import MedicationSection from '@/components/sections/medication-section.vue';
import MedicationTreatmentConsentSection from '@/components/sections/medication-treatment-consent-section.vue';
import MissingIcon from '@/assets/icons/star.vue';

export default {
  components: {
    DisabilitySection,
    GpSection,
    MedicationConsentSection,
    MedicationSection,
    MedicationTreatmentConsentSection,
    MissingIcon,
  },

  data() {
    return {
      mandatory: {
        gp: false,
        medicationconsent: false,
        treatmentconsent: false,
      },
    };
  },

  computed: {
    // Only exists to switch on/off the star icon in the banner.
    completed() {
      return Object.values(this.mandatory).every(b => b);
    },
  },

  methods: {
    // Each section is responsible for knowing when it's mandatory fields have
    // been entered and sends true/false after every user input.
    updateMandatory(...[name, complete]) {
      this.mandatory[name] = complete;
    },
  },
};
</script>
