<template>
  <div>
    <div
      class="w-full flex items-center justify-between sm:font-bold text-xs sm:text-base md:text-xl shadow md:rounded h-12 mb-1 py-1 md:py-2 px-2 md:px-4"
      :class="theme"
      @click="showContent = !showContent"
    >
      <div>
        <slot name="title"></slot>
      </div>
      <div>
        <span class="hidden btn-pill sm:block text-sm">{{
          showContent ? 'hide' : 'show'
        }}</span>
      </div>
    </div>
    <div v-show="showContent">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      theme: ['text-white', 'bg-indigo-800'],
      showContent: this.open,
    };
  },
};
</script>
