<template>
  <div class="flex flex-col justify-center text-center">
    <div>
      <button
        class="h-10 px-2 text-xs font-semibold text-white bg-indigo-700 rounded shadow-2xl sm:px-4 lg:px-6 xl:px-8 sm:h-16 sm:text-base md:text-lg lg:text-xl xl:text-2xl hover:cursor-pointer hover:bg-indigo-600"
        @click="click"
      >
        <slot></slot>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
