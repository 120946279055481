<template>
  <navbar-container>
    <template slot="tabs">
      <div class="relative flex items-center justify-center w-full">
        <div class="absolute left-0 ml-4">
          <slot name="search"></slot>
        </div>

        <div class="flex items-center">
          <slot name="tabs"></slot>
          <div
            class="absolute right-0 flex items-center justify-center w-10 h-10 mr-8 text-indigo-400 cursor-pointer hover:text-indigo-600"
          >
            <div class="hidden w-6 h-6 sm:block " @click="cancel">
              <close-icon type="outline" @click="cancel"></close-icon>
            </div>
          </div>
        </div>
      </div>
    </template>
  </navbar-container>
</template>

<script>
import CloseIcon from '@/assets/icons/close.vue';
import NavbarContainer from '@/components/navbar-container.vue';

export default {
  components: {
    CloseIcon,
    NavbarContainer,
  },

  props: {},

  data() {
    return {};
  },

  computed: {},

  methods: {
    cancel() {
      this.$router.push({ name: 'home-screen' });
    },
  },
};
</script>
