<template>
  <form-section>
    <template #form-header>
      <div
        class="hidden lg:block text-indigo-400 lg:mt-4 text-xs sm:text-sm md:text-base"
      >
        <span>Home address</span>
      </div>
    </template>

    <template #form-body>
      <div class="flex flex-col lg:flex-row w-full px-2 lg:px-0">
        <div class="flex flex-col w-full md:w-2/3 lg:pt-3">
          <form-text-input
            :area="true"
            name="address.address"
            required
            placeholder=""
            title="Your home address"
            @user-entry="capture"
          ></form-text-input>
          <div class="w-1/2 sm:w-1/4 md:w-1/3">
            <form-postcode-input
              name="address.postcode"
              required
              placeholder="e.g. RG1 2ZY"
              title="Your postcode"
              @user-entry="capture"
            ></form-postcode-input>
          </div>
        </div>

        <div class="flex flex-col w-1/2 sm:w-1/3  mt-0 md:pt-3">
          <form-select
            name="school.lea"
            title="Your Local Authority"
            :options="options.lea"
            @lea-selected="lea_selected"
            @user-entry="capture"
          ></form-select>

          <div v-if="selected_lea === 'other'" class="md:mt-3">
            <form-text-input
              class="mb-0"
              name="lea.other"
              title="Other LEA"
              placeholder="Please enter name"
            ></form-text-input>
          </div>
        </div>
      </div>
    </template>
  </form-section>
</template>

<script>
import FormPostcodeInput from '@/components/form-postcode-input';
import { store } from '@/store.js';

export default {
  components: {
    FormPostcodeInput,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      entered: {
        'address.address': false,
        'address.postcode': false,
        'school.lea': false,
      },

      selected_lea: store.booking.find('school.lea') || '',

      options: {
        lea: [
          { id: 1, value: '-', text: 'Please choose', disabled: false },
          {
            id: 2,
            value: 'bracknell-forest',
            text: 'Bracknell Forest',
            disabled: false,
          },
          { id: 3, value: 'hampshire', text: 'Hampshire', disabled: false },
          { id: 4, value: 'reading', text: 'Reading', disabled: false },
          {
            id: 5,
            value: 'west-berks',
            text: 'West Berkshire',
            disabled: false,
          },
          {
            id: 6,
            value: 'windsor-maidenhead',
            text: 'Windsor & Maidenhead',
            disabled: false,
          },
          { id: 7, value: 'wokingham', text: 'Wokingham', disabled: false },
          { id: 8, value: 'other', text: 'Other', disabled: false },
        ],
      },
    };
  },

  created() {
    Object.keys(this.entered).forEach(k => {
      this.entered[k] = store.booking.find(k) ? true : false;
    });
    this.check_complete();
  },

  methods: {
    capture(...[name, val]) {
      this.entered[name] = val ? true : false;
      this.check_complete();
    },

    check_complete() {
      const done = Object.values(this.entered).every(b => b);
      this.$emit('entry', this.section, done);
    },

    lea_selected(id) {
      this.selected_lea = this.options.lea.filter(o => o.id === id + 1)[0][
        'value'
      ];
      // Just in case a different LEA had been entered but now a value has been
      // selected, remove the previously entered 'other' LEA name.
      if (this.selected_lea !== 'other') {
        store.booking.update({ name: 'lea.other', value: '-' });
      }
    },
  },
};
</script>
