<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <template #title>
            Social Behaviour
          </template>
          <template #info>
            <div class="hidden lg:block flex flex-col">
              <p>Choose as many as apply</p>
              <br />
              <p class="text-gray-500">{{ note }}</p>
            </div>
          </template>
        </form-section-header>
      </template>

      <template #form-body>
        <div class="flex flex-col flex-wrap md:flex-row px-2 lg:px-0 md:mt-2">
          <div>
            <span
              class="text-gray-600 inline-block text-base md:text-lg mb-2 md:mb-4"
              >Does your child...</span
            >
            <div class="flex flex-wrap">
              <div v-for="(opt, idx) in options" :key="opt.label">
                <form-select-button
                  active
                  classes="mr-4 mb-2"
                  :immediate="options[idx].selected"
                  :title="opt.label"
                  :name="opt.id"
                  pill
                  @selected="selectBehaviour(idx)"
                ></form-select-button>
              </div>
            </div>
            <div v-if="options[4].selected" class="flex flex-col">
              <span class="text-gray-600 text-base md:text-lg mt-2 mb-2"
                >Select type of one-to-one assistance</span
              >
              <div class="flex">
                <div v-for="(opt, idx) in assistance" :key="opt.label">
                  <form-select-button
                    active
                    classes="mr-4 mb-2"
                    :immediate="assistance[idx].selected"
                    :title="opt.label"
                    :name="opt.id"
                    pill
                    @selected="selectAssistance(idx)"
                  ></form-select-button>
                </div>
              </div>
            </div>

            <biting-section></biting-section>
          </div>
          <div class="w-full mt-2">
            <form-text-input
              :area="true"
              name="behaviour.extra_info"
              title="Anything else you feel we should be aware of?"
              placeholder="Please include any relevant details"
            ></form-text-input>
          </div>
          <div class="w-full mt-5">
            <form-text-input
              :area="true"
              name="behaviour.triggers"
              title="Please note any particular triggers for your child"
              placeholder="e.g. being too hot, loud noises, overcrowding, being hungry"
            ></form-text-input>
          </div>
          <div class="w-full mt-5">
            <form-text-input
              :area="true"
              name="behaviour.calming"
              title="Are there any specific calming techniques that work well for your child when agitated or upset?"
              placeholder="e.g. deep pressure, a quiet space, a snack, change of face"
            ></form-text-input>
          </div>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import BitingSection from './behaviour-biting-section.vue';
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    BitingSection,
    FormSelectButton,
  },

  data() {
    return {
      note: '',
      options: [
        {
          id: 'behaviour.options_3',
          label: 'Interact with other children',
          selected: false,
        },
        {
          id: 'behaviour.options_4',
          label: 'Prefer to play on their own',
          selected: false,
        },
        {
          id: 'behaviour.options_5',
          label: 'Have a tendency to wander',
          selected: false,
        },
        {
          id: 'behaviour.options_2',
          label: 'Express him/herself through physical aggression',
          selected: false,
        },
        {
          id: 'behaviour.options_6',
          label: 'Receive one-to-one assistance at school',
          selected: false,
        },
      ],
      assistance: [
        {
          id: 'behaviour.assistance_1',
          label: 'Social Behaviour',
          selected: false,
        },
        {
          id: 'behaviour.assistance_2',
          label: 'Learning Related',
          selected: false,
        },
        { id: 'behaviour.assistance_3', label: 'N/A', selected: false },
      ],
    };
  },

  created() {
    // Inital selected state comes from the data source
    this.options.forEach(o => (o.selected = store.booking.find(o.id) || false));
    this.assistance.forEach(
      a => (a.selected = store.booking.find(a.id) || false)
    );
    this.note = [
      "Please understand that the more information we have about your child's needs,",
      ' the better prepared we can be to support them at the club. ',
      "It is important to give us a 'worst case scenario' picture so that we can ",
      'be prepared for anything and everything - we are happy to accept that many behaviours ',
      'are unlikely to ever occur but we would rather know!',
    ].join('');
  },

  methods: {
    selectAssistance(idx) {
      this.assistance[idx].selected = !this.assistance[idx].selected;
    },

    selectBehaviour(idx) {
      this.options[idx].selected = !this.options[idx].selected;
    },
  },
};
</script>
