<template>
  <form-section>
    <template #form-header>
      <form-section-header></form-section-header>
    </template>

    <template #form-body>
      <div class="flex flex-col w-full px-2 pb-2 lg:px-0">
        <div class="w-full lg:w-7/12">
          <yes-no-buttons :name="name" @yes="select(true)" @no="select(false)">
            <template #question>
              <div
                class="md:mr-4 "
                :class="{
                  mandatory: mandatoryDetails,
                }"
              >
                {{ question }}
              </div>
            </template>
          </yes-no-buttons>
        </div>
        <div>
          <form-text-input
            v-if="selected"
            :area="true"
            :name="infoName()"
            :placeholder="placeholder"
          ></form-text-input>
        </div>
        <slot></slot>
      </div>
    </template>
  </form-section>
</template>

<script>
import { store } from '@/store.js';
import YesNoButtons from '@/components/button-group-yes-no-row.vue';

export default {
  components: {
    YesNoButtons,
  },

  props: {
    mandatoryDetails: { type: Boolean, default: false },
    name: { type: String, default: '' },
    question: { type: String, default: '' },
    prompt: { type: String, default: '' },
  },

  data() {
    return {
      selected: this.load(this.name),
    };
  },

  computed: {
    placeholder() {
      return `Please describe ${this.mandatoryDetails ? '(required)' : ''}`;
    },
  },

  methods: {
    buttonStyle() {
      const buttonClass = ['font-extrabold rounded border  mb-2  px-4 py-1'];
      const selectedClass = ['bg-green-200 text-green-400 border-green-100'];
      const defaultClass = [
        'bg-blue-200 text-blue-400 hover:bg-green-200 hover:text-green-400 hover:border-green-100 border-blue-100',
      ];
      return this.selected
        ? [...selectedClass, ...buttonClass]
        : [...defaultClass, ...buttonClass];
    },

    infoName() {
      return `${this.name}.info`;
    },

    load(id) {
      return !!store.booking.find(id) || false;
    },

    select(opt) {
      this.selected = opt;
      this.$emit('select', { name: this.name, selected: this.selected });
    },

    title(name) {
      return {
        allergiesDetails: 'Please give details',
        moreInfo: 'Anything else you feel we should be aware of',
      }[name];
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  margin-left: 1px;
  color: #fc8181;
  content: '*';
}
</style>
