<template>
  <div>
    <dt
      class="mt-4 text-sm md:text-base font-semibold text-indigo-600 underline cursor-pointer"
      @click="visible = !visible"
    >
      <slot name="question"></slot>
    </dt>
    <dd v-if="visible" class="text-sm md:text-base text-indigo-500">
      <slot name="answer"></slot>
    </dd>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style scoped>
p {
  line-height: 1.4rem;
}
</style>
