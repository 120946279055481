<template>
  <form-section>
    <template #form-header>
      <form-section-header>
        <!-- prettier-ignore -->
        <template #title>Treatment Consent</template>
      </form-section-header>
    </template>

    <template #form-body>
      <div class="flex flex-col px-2 lg:px-0">
        <div :class="[accepted ? 'mb-6' : 'mb-2']">
          <yes-no-buttons
            :name="name"
            vertical
            @yes="clicked(true)"
            @no="clicked(false)"
          >
            <template #question>
              <div class="md:mr-4 mb-4">
                <span :class="{ mandatory: !selected }" class="font-semibold"
                  >This waiver applies <span class="underline">only</span> in
                  the event that neither parent/guardian can be reached in the
                  case of emergency.</span
                ><br />
                If my child becomes ill or has an accident that requires
                emergency treatment, I give consent for the manager, or other
                nominated representative of the club, to act on my behalf and I
                waive my right to be informed of consent for treatment.<br />
                In such circumstances, I authorise any medical and surgical
                treatment and other medical and/or hospital procedures as may be
                performed or prescribed on the advice of the attending medical
                professionals and/or paramedics for my child
              </div>
              <!-- eslint-enable -->
            </template>
          </yes-no-buttons>
        </div>
        <div
          v-if="!accepted"
          class="md:w-5/6 mb-4 uppercase text-xs md:text-base p-2 border-2 border-red-300 rounded bg-red-100 text-gray-600 italic font-semibold"
        >
          {{ message }}
        </div>
      </div>
    </template>
  </form-section>
</template>

<script>
import YesNoButtons from '@/components/button-group-yes-no-row.vue';
import { store } from '@/store.js';

export default {
  // eslint-disable-next-line
  components: {
    YesNoButtons,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    const name = 'treatment.consent';
    const consent =
      typeof store.booking.find(name) === 'boolean'
        ? store.booking.find(name)
        : true;

    return {
      accepted: consent,
      message: [
        "By clicking 'No' you are accepting full responsibility for ensuring that you or another named person ",
        'are always contactable whilst your child is at the club',
      ].join(''),
      name,
      selected: store.booking.find(name) !== undefined,
    };
  },

  created() {
    this.$emit(
      'entry',
      this.section,
      store.booking.find(this.name) !== undefined
    );
  },

  methods: {
    clicked(val) {
      this.accepted = val;
      this.selected = true;
      this.$emit('entry', this.section, true);
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  margin-left: 1px;
  color: #fc8181;
  content: '*';
}
</style>
