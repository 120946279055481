<template>
  <div id="form-section" class="mt-0">
    <div class="relative flex flex-col lg:flex-row">
      <div
        class="lg:w-1/4 lg:min-w-1/4 font-semibold md:font-normal uppercase md:normal-case text-xs md:text-base text-white lg:mx-4 lg:mr-10 lg:py-1 px-2 lg:px-0 mb-1"
      >
        <slot name="form-header"></slot>
      </div>
      <div class="flex items-center lg:w-3/4 lg:px-2 lg:py-1">
        <slot name="form-body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
