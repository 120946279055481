<template>
  <form-section>
    <template #form-header>
      <form-section-header>
        <template #title>
          Medication Details
        </template>
        <template #info>
          <span class="hidden lg:block">
            Please enter details
          </span>
        </template>
      </form-section-header>
    </template>

    <template #form-body>
      <div
        class="flex flex-col flex-wrap md:flex-row px-2 lg:px-0 md:pb-6 md:mt-2"
      >
        <form-text-input
          v-model="medication"
          :placeholder="placeholder"
          :area="true"
          name="medication.details"
        ></form-text-input>
        <info-alert v-if="medication.length">
          <template #text>
            <div class="flex flex-col">
              <p class="font-bold">PLEASE NOTE:</p>
              <p>
                All medication must be provided in the original packaging,
                displaying your child's name etc.
              </p>
            </div>
          </template>
        </info-alert>
      </div>
    </template>
  </form-section>
</template>

<script>
import { store } from '@/store.js';

export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      medication: '',
    };
  },

  mounted() {
    this.medication = store.booking.find('medication.details') || '';
  },
};
</script>
