<template>
  <div class="relative flex flex-row items-center justify-start w-full ml-4 text-xs text-center lg:justify-center sm:ml-0 sm:text-base">
    <div class="w-auto mr-2 lg:mr-4">
      <div v-if="open"
           class="h-8 border-l border-r border-indigo-200 sm:h-16 sm:w-64">
        <a href="#"
           class="flex flex-col items-center justify-center w-full h-full bg-indigo-100 exg--navbar-tab"
           @click="handle('payNow')">
          <span class="text-xs exg--navbar-tab-text sm:text-sm md:text-base lg:text-lg">Pay by credit card</span>
        </a>
      </div>
    </div>
    <div class="w-auto ml-2 lg:ml-4">
      <div v-if="open"
           class="h-8 border-l border-r border-indigo-200 sm:h-16 sm:w-64 ">
        <a href="#"
           class="flex flex-col items-center justify-center w-full h-full bg-indigo-100 exg--navbar-tab"
           @click="handle('payVoucher')">
          <span class="text-xs exg--navbar-tab-text sm:text-sm md:text-base lg:text-lg">Use Childcare Vouchers</span>
        </a>
      </div>
    </div>
    <div class="absolute right-0 flex items-center justify-start">
      <navbar-close @closeView="handle('closeView')"></navbar-close>
    </div>
  </div>
</template>

<script>
import NavbarClose from '@/components/navbar-close.vue';

export default {
  components: {
    NavbarClose,
  },

  data() {
    return {
      open: false, // Summer 2021 - I think this was put here last Easter as a way to stop payment?
    };
  },

  methods: {
    handle(action) {
      this.$emit(action);
    },
  },
};
</script>
