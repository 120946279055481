<template>
  <div>
    <booking-form
      :booking-request="request"
      :can-add-child="canAddChild"
      :form-complete="complete"
      :locked="locked"
      :posting="posting"
      @clear="clearForm"
      @remove="removeChild"
      @submit="submitRequest"
      @switch="switchChild"
      @update="updateRequest"
      @updateBooking="updateBooking"
    ></booking-form>

    <div @click="dismiss">
      <modal-help v-if="infoConfirm">
        <div slot="header">
          <div
            class="flex items-center w-full py-2 text-white md:py-0 md:w-2/3"
          >
            <div class="">
              <svg
                class="h-3 fill-current md:h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M11 0h1v3l3 7v8a2 2 0 01-2 2H5c-1.1 0-2.31-.84-2.7-1.88L0 12v-2a2 2 0 012-2h7V2a2 2 0 012-2zm6 10h3v10h-3V10z"
                />
              </svg>
            </div>
            <span class="ml-4 text-xs font-normal sm:text-base lg:text-lg"
              >Booking request has been accepted</span
            >
          </div>
        </div>
        <div slot="helptext">
          <div
            class="flex flex-col items-center text-xs text-indigo-500 sm:text-base lg:text-lg"
          >
            <div class="flex flex-col items-center font-normal">
              <p class="">
                You will receive an email confirming which dates you have been
                allocated by 20th February 2024.
              </p>
            </div>
          </div>
        </div>
      </modal-help>
    </div>
  </div>
</template>

<script>
import Booking from '@/models/booking.js';
import BookingForm from '@/views/booking-form.vue';
import ModalHelp from '@/components/modal-help.vue';
import { connector, eventBus } from '@/main.js';
import { store } from '@/store.js';

export default {
  components: {
    BookingForm,
    ModalHelp,
  },

  data() {
    return {
      named: 0,
      request: store.request.get(),
      locked: false,
      infoConfirm: false,
      posting: false,
      updated: 0,
    };
  },

  computed: {
    canAddChild() {
      //eslint-disable-next-line
      const refresh = this.named;

      return store.booking && store.booking.find('participant.name')
        ? !!store.booking.find('participant.name').length > 0 || false
        : false;
    },

    complete() {
      //eslint-disable-next-line
      const refresh = this.updated;

      // check state of every booking in the request
      // eslint-disable-next-line
      const allComplete = store.request
        .get()
        .bookings.map(b => new Booking(b))
        .every(b => b.complete);

      return store.booking.incomplete;
      // return store.booking && store.booking.complete
      //   ? allComplete || false
      //   : false;
    },
  },

  watch: {
    request: {
      deep: true,
      immediate: true,
      handler: function(req) {
        this.request = req;
      },
    },
  },

  created() {
    this.locked = store.request.locked();

    // Unset a flag which clears the navbar as soon as they submit - preventing
    // then from clicking over and over while the server does it's thing.
    this.posting = false;

    // Any existing request for the logged in user gets loaded during login so
    // they cannot then change it (unless they are an admin).
    const req = store.request.get();
    if (req.bookings.length === 0) {
      store.request.create();
      store.booking = new Booking();
    } else {
      store.booking = new Booking(req.bookings[0]);
    }
  },

  methods: {
    clearForm(force, cb) {
      store.booking = new Booking();
      if (force) {
        store.request.delete();
        store.request.create();
        this.request = store.request.get();
        // This is needed to get the dates section to deselect it's dates
        //TODO issue #63
        eventBus.$emit('form-clear', true);
      }
      // Force re-computation of the props passed to the UI
      //TODO look at deep watchers
      this.named += 1;
      this.updated += 1;
      cb();
    },

    dismiss() {
      this.infoConfirm = false;
      this.$router.push({ name: 'home-screen' });
    },

    async loadBookings(ids = []) {
      //!TEMP - need some way to load the screen when a user has already submitted a request
      //! but the other way in here is for an admin who has picked one booking to look at and
      //! possibly amend.
      alert('loadBookings has been activated');
      return await connector.getBookings(ids);
    },

    removeChild(id, cb) {
      let request = store.request.get();
      const idx = request.bookings.findIndex(book => book.id === id);
      if (idx === -1) return;

      request.bookings.splice(idx, 1);
      this.updateRequest(request);

      // Can we switch to next available child?
      this.named += 1;
      if (request.bookings.length) {
        this.switchChild(request.bookings[0].id, cb);
      }
    },

    switchChild(id, cb = () => {}) {
      // Find the selected child based on the timestamp id (the child being
      // displayed could also be selected and this will still work)
      const child = store.request.get().bookings.filter(kid => kid.id === id);

      // Reset the booking state and display data for the selected child
      store.booking = new Booking(child.length > 0 ? child[0] : {});
      cb();
    },

    submitRequest(cb) {
      this.posting = true;
      this.$http
        .post('bookings/request', store.request.json())
        .then(async response => {
          const { data, success } = response.data;
          if (success) {
            //TODO may need some progress indicator here?
            store.booking = null;

            // Reload each booking in this request by fetching from the db and
            // not relying on what was sent.
            const request = await this.$http.get(`request/${data.id}/bookings`);

            store.request.rebuild(request.data.data);
            this.request = store.request.get();
            store.booking = new Booking(request.data.data[0]);
            this.locked = store.request.locked();

            this.infoConfirm = true;

            cb();
          } else {
            let error = `
            Sorry - we cannot process this request at the moment.\n\n
            Your form has not been cleared so please try again later.\n\n
            `;
            alert(error);
            this.$router.push({ name: 'login-form' });
          }
        })
        .catch(err => {
          const { message, id } = err.response.data;
          // eslint-disable-next-line
          console.log(`error ${err.response.status}:`, message, id);
          if (Number(err.response.status) === 500) {
            let fatal = `
            Sorry - we cannot process this request at the moment.\n\n
            Your form has not been cleared so please try again later.\n\n
            `;
            if (id) fatal += `Our reference is "${id}"`;
            alert(fatal);
          } else {
            // probably token expiry but NOT tampered token or bad data
            alert(
              `Sorry, your session has timed out.\n\nPlease login again and try again.\n\nThank you `
            );
          }
          this.$router.push({ name: 'login-form' });
        });
    },

    updateBooking(args) {
      // Add the entered data to the model and update the copy in storage
      store.booking.update(args);

      // Force refresh of names in the banner
      if (args.name === 'participant.name') this.named += 1;
      this.updated += 1;

      // The request array maintains a sync'd copy of the on-screen booking
      // so update that as well.
      let request = store.request.get() || store.request.create();

      const idx = request.bookings.findIndex(
        book => book.id === store.booking.data.id
      );
      if (idx >= 0) {
        request.bookings[idx] = { ...store.booking.json };
      } else {
        request.bookings.push(store.booking.json);
      }
      this.updateRequest(request);
    },

    updateRequest(request) {
      this.request = store.request.set(request);
    },
  },
};
</script>
