'use strict';

import moment from 'moment';

class Booking {
  constructor(data) {
    this.data = data ? { ...data } : { id: moment().unix(), status: 'new' };
  }

  update(fields) {
    //* note to self - I was returning on !fields.value but this was stopping
    //* updates of 'false'. Not sure why I was returning before though?
    if (!fields.name) return;
    if (fields.value === '-') return delete this.data[fields.name];
    return (this.data[fields.name] = fields.value);
  }

  /**
   * The form is complete when there is a property in the json for every
   * required field.
   *
   * return boolean
   */
  get complete() {
    return (
      Booking.requiredFields
        .map(f => f.name)
        .filter(f => this.find(f) === undefined || this.find(f).length < 1)
        .length === 0
    );
  }

  /**
   * Return an array of all the sections containing missing fields.
   */
  get incomplete() {
    if (this.complete) return {};
    const empty = name => {
      const val = this.find(name);
      return val === undefined || val.length < 1;
    };

    /**
     * Summer 2022.
     * Epilepsy=yes means that two other fields become mandatory.
     */
    const _hasEpilepsy = () => {
      return this.find('disability.epilepsy') === true
        ? [
            {
              name: 'disability.epilepsy.info',
              section: 'medical information',
            },
            {
              name: 'disability.epilepsy.disclaimer',
              section: 'medical information',
            },
          ]
        : [];
    };

    return [...Booking.requiredFields, ..._hasEpilepsy()].reduce(
      (fields, field) => {
        if (empty(field.name)) {
          if (fields.hasOwnProperty(field.section)) {
            fields[field.section].push(field.name);
          } else {
            fields[field.section] = [field.name];
          }
        }
        return fields;
      },
      {}
    );
  }

  get json() {
    return this.data;
  }

  /**
   * Given a date, calculate the age of the child at that date in whole years.
   * @param {*} at Date string
   */
  age(at) {
    const dob = 'participant.dob';
    if (this.find(dob)) {
      return moment(at).diff(moment(this.find(dob)), 'years');
    }
    return 0;
  }

  /**
   * Given a dot separated parent.child key, find the matching value from the
   * json data structure for a booking.
   *
   * @param {String} key The key to find e.g. participant.name
   */
  find(key) {
    const val = this.json[key];
    // Special treatment for Booleans which come back from the database in the
    // form they were sent
    //TODO can I send true/false to the db and save conversion there and here?
    if (['yes', 'no'].includes(val)) {
      return val === 'yes' ? true : false;
    }
    return val;
  }

  reset() {
    this.data = {};
  }

  // 'Private' helpers
  _split = key => key.split('.');
}

Booking.requiredFields = [
  { name: 'participant.name', section: 'participant' },
  { name: 'participant.surname', section: 'participant' },
  { name: 'address.address', section: 'participant' },
  { name: 'address.postcode', section: 'participant' },
  { name: 'booking.dates', section: 'dates' },
  { name: 'carers.carer_one_name', section: 'contact information' },
  { name: 'carers.carer_one_surname', section: 'contact information' },
  { name: 'carers.carer_one_mobilePhone', section: 'contact information' },
  { name: 'carers.emergency_contact_name', section: 'contact information' },
  { name: 'carers.emergency_contact_surname', section: 'contact information' },
  { name: 'general.fears', section: 'general' },
  {
    name: 'carers.emergency_contact_mobilePhone',
    section: 'contact information',
  },
  { name: 'gp.fullname', section: 'medical information' },
  { name: 'gp.address', section: 'medical information' },
  { name: 'gp.phone', section: 'medical information' },
  { name: 'medication.consent', section: 'medical information' },

  // Added for Summer 2022 - if the child has epilepsy, then we need to ask for
  // more information and the parent has to tick an extra disclaimer box.
  // This was not possible using the existing code (May 2022) but had to get
  // around it for Summer 2022
  { name: 'disability.epilepsy', section: 'medical information' },

  { name: 'participant.ethnicity', section: 'participant' },
  { name: 'participant.gender', section: 'participant' },
  { name: 'participant.dob', section: 'participant' },
  { name: 'permissions.permission_1', section: 'declaration/consents' },
  { name: 'permissions.permission_2', section: 'declaration/consents' },
  { name: 'permissions.permission_3', section: 'declaration/consents' },
  { name: 'permissions.permission_4', section: 'declaration/consents' },
  { name: 'permissions.permission_5', section: 'declaration/consents' },
  { name: 'permissions.permission_6', section: 'declaration/consents' },
  { name: 'permissions.permission_7', section: 'declaration/consents' },
  { name: 'school.name', section: 'participant' },
  { name: 'school.lea', section: 'participant' },
  { name: 'treatment.consent', section: 'medical information' },
];

export default Booking;
