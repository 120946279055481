<template>
  <page-accordion>
    <header slot="title">Behaviour and communication</header>
    <section slot="content">
      <behaviour-section></behaviour-section>
      <communication-section></communication-section>
    </section>
  </page-accordion>
</template>

<script>
import BehaviourSection from '@/components/sections/behaviour-section.vue';
import CommunicationSection from '@/components/sections/communication-section.vue';

export default {
  components: {
    BehaviourSection,
    CommunicationSection,
  },
};
</script>
