'use strict';

import jwtdecode from 'jwt-decode';

function clear() {
  this.booking = {};
  localStorage.removeItem(this.request.REQUEST_KEY);
  this.user = {};
  this.dates.set();
}

// The available dates for the season. Loaded by home-screen-controller.
const dates = {
  DATES_KEY: 'available_dates',

  accepted: booking => {
    const req = JSON.parse(localStorage.getItem('request'));
    const bookings = req.bookings || [];
    if (!bookings.length) return [];
    const accepted = bookings[booking].accepted || [];
    return accepted;
  },

  set(dates = []) {
    this.dates = [...dates];
    localStorage.setItem(
      this.DATES_KEY,
      typeof dates === 'string' ? dates : JSON.stringify(dates)
    );
  },

  get() {
    return this.dates || JSON.parse(localStorage.getItem(this.DATES_KEY)) || [];
  },

  season: {
    start() {
      return dates.get()[0].date;
    },
  },
};

// Save the logged in user
const user = {};

// This will hold a Booking instance - used to populate on-screen values but
// not stored locally.
const booking = {};

// Payment object - ideally this should have been exposed as a property of the
// booking object.
const payment = {
  payment() {
    return request.payment();
  },

  canBePaid() {
    if (!this.payment()) return false;
    return !this.payment().paid_on && this.payment().amount_due ? true : false;
  },

  hasBeenPaid() {
    if (!this.payment()) return false;
    return this.payment().paid_on ? true : false;
  },

  hasReceipt() {
    if (!this.hasBeenPaid()) return false;
    return this.payment().receipt_url !== null;
  },
};

/**
 * Object exposing access to the local state storage mechanism for the request.
 */
const request = {
  REQUEST_KEY: 'request',

  readonly: false,

  build(state = 'new') {
    return {
      id: '',
      state,
      userid: this.token.userid(),
      season: '',
      payment: {},
      bookings: [],
    };
  },

  create(state = 'new') {
    this.set(this.build(state));
  },

  get() {
    if (!JSON.parse(localStorage.getItem(this.REQUEST_KEY))) {
      this.create();
    }
    return JSON.parse(localStorage.getItem(this.REQUEST_KEY));
  },

  delete() {
    localStorage.removeItem(this.REQUEST_KEY);
  },

  payment() {
    const req = this.get();
    return req.hasOwnProperty('payment') ? req.payment : null;
  },

  /**
   * After creating a request the user can only view the details. The request
   * gets reconstructed by being passed all the bookings associated with that
   * request.
   *
   * @param {*} data May be a request object or an array of bookings
   * @param {*} readonly True is being called by the admin view function
   */
  rebuild(data, readonly = false) {
    this.readonly = readonly;
    let req = this.build('submitted');
    if (Array.isArray(data)) {
      req.bookings = [...data];
    } else {
      if (typeof data === 'object') {
        req.id = data.id || '';
        req.season = data.season || '';
        if (data.hasOwnProperty('bookings')) {
          req.bookings = [...data.bookings];
        }
        if (data.hasOwnProperty('payment')) {
          req.payment = { ...data.payment };
        }
      }
    }
    this.set(req);
  },

  set(value) {
    localStorage.setItem(
      this.REQUEST_KEY,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
    return this.get();
  },

  // May be different to the token userid() - if two users share the same
  // device.
  userid() {
    return this.json().userid;
  },

  json() {
    return this.get();
  },

  // A request is locked when it has a) been submitted and b) is being viewed
  // by a non-admin user.
  locked() {
    // The readonly flag is set when an admin asks to view a booking.
    if (this.readonly) return true;

    // Otherwise admin can amend any field.
    if (this.token.admin()) return false;

    // If not admin then user can amend at any time before submission.
    return this.submitted();
  },

  submitted() {
    return !(this.json().state === 'new');
  },

  token: {
    TOKEN_KEY: 'token',
    // Only store the token so watchers can react to changes when logging in
    // and out
    token: null,

    admin() {
      return jwtdecode(this.get()).role === 'admin';
    },

    get() {
      return localStorage.getItem(this.TOKEN_KEY);
    },

    set(token) {
      this.token = token;
      localStorage.setItem(this.TOKEN_KEY, token);
    },

    userid() {
      return jwtdecode(this.get()).sub || '';
    },

    username() {
      return jwtdecode(this.get()).name || '';
    },
  },
};

const version = {
  TOKEN_KEY: 'version',

  get() {
    return localStorage.getItem(this.TOKEN_KEY);
  },

  set(version) {
    localStorage.setItem(this.TOKEN_KEY, version);
  },
};

export const store = { booking, dates, clear, payment, request, user, version };
