<script>
import FormTextInput from '@/components/form-text-input';
import { helpers } from 'vuelidate/lib/validators';
import Phone from 'phone';

export default {
  extends: FormTextInput,

  props: {
    landline: { type: Boolean, default: true },
  },

  data() {
    return {
      telephone: '',
    };
  },

  validations: {
    telephone: {
      valid: function(number) {
        return (
          !helpers.req(number) || Phone(number, 'GBR', this.landline).length > 0
        );
      },
    },
  },

  methods: {
    enter(ev) {
      // Force css to be re-evaluated
      this.update = !this.update;

      this.telephone = ev.target.value;

      // This manually flags the telephone as 'dirty' so the validator will run.
      this.$v.telephone.$touch();

      if (this.$v.telephone.$anyError) {
        this.errmsg = 'Invalid telephone number';

        // There may be a possibly correct value already in the store so get rid
        // of it
        this.$root.$emit('formEntry', {
          name: this.name,
          value: '-',
        });
      } else {
        // Clear the message from the parent text input component
        this.errmsg = '';

        // This is trapped by sections containing mandatory fields
        this.$emit('user-entry', this.name, this.telephone || '');

        this.$root.$emit('formEntry', {
          name: this.name || '401-5',
          value: this.telephone || '-',
        });
      }
    },
  },
};
</script>
