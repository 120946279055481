<template>
  <div :class="width">
    <form-input-label
      :mandatory="mandatory"
      :name="name"
      :info="info"
      :title="title"
      @info="showInfo"
    ></form-input-label>
    <div v-if="textarea" class="mr-1 lg:mr-4">
      <textarea
        style="box-sizing:border-box;max-width:100%"
        class="sm:pl-2 text-blue-500 rounded-md border md:border-2 border-gray-300 text-sm sm:text-base md:text-lg placeholder-gray-500"
        :name="name"
        :value="load(name)"
        :disabled="noinput"
        :placeholder="placeholder"
        cols="48"
        rows="3"
        @input="input"
        @keyup.enter="enter"
        @blur="enter"
      ></textarea>
    </div>
    <div v-else class="mr-1 lg:mr-4">
      <form-text-noinput v-if="noinput" :value="load(name)"></form-text-noinput>
      <div v-else>
        <input
          type="text"
          :name="name"
          :value="load(name)"
          :placeholder="placeholder"
          :class="css"
          :disabled="noinput"
          @input="input"
          @keyup.enter="enter"
          @blur="enter"
        />
      </div>
    </div>
    <div v-if="errmsg">
      <span class="text-xs font-semibold italic text-red-400">
        {{ errmsg }}
      </span>
    </div>
  </div>
</template>

<script>
import Booking from '@/models/booking.js';
import { store } from '@/store.js';

export default {
  props: {
    area: { type: Boolean, default: false },
    info: { type: String, default: '' },
    name: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    title: { type: String, default: '' },
    size: { type: String, default: '' },
    value: { type: String, default: '' },
  },

  data() {
    return {
      css: [],
      errmsg: '',
      mandatory: false,
      requestLocked: store.request.locked(),
      update: false,
    };
  },

  computed: {
    noinput() {
      return this.requestLocked;
    },

    textarea() {
      return this.area;
    },

    width() {
      if (this.size === 'sm') return 'w-full sm:w-1/3 md:w-1/4 text-base';
      if (this.size === 'md')
        return 'w-full sm:w-1/2 md:w-1/3 md:mr-4 text-base';
      if (this.size === 'lg') return 'w-full md:mr-4';
      return 'w-full';
    },
  },

  watch: {
    //* See note on input method for reason for this watcher.
    update() {
      this.mandatory =
        this.required() && !store.booking.find(this.name) && !this.noinput;
    },
  },

  created() {
    this.css = this.classes();
    this.update = true;
  },

  methods: {
    classes() {
      let base = [
        'w-full',
        'h-6',
        'sm:h-10',
        'pl-1',
        'sm:pl-2',
        'mb-2',
        'lg:mb-0',
        'text-blue-500',
        'rounded-md',
        'border md:border-2',
        'border-gray-300',
        'text-xs',
        'sm:text-base',
        'md:text-lg',
        'placeholder-gray-500',
      ];
      if (this.requestLocked) base.push('cursor-not-allowed');
      return base;
    },

    load(id) {
      const val = store.booking.find(id);
      return typeof val === 'string' ? val.trimStart() : val;
    },

    enter(ev) {
      // Toggle value just to force update the classes (basically to remove
      // required class if data is now present in this field).
      //* This is the only way I could find to force the classes to be refreshed
      //* after autofill in Chrome.
      this.update = !this.update;

      // This is trapped by sections containing mandatory fields
      this.$emit('user-entry', this.name, ev.target.value);

      this.$root.$emit('formEntry', {
        name: this.name || '401-5',
        value: ev.target.value,
      });
    },

    input(ev) {
      this.$emit('input', ev.target.value);
    },

    required() {
      return Booking.requiredFields.map(f => f.name).includes(this.name);
    },

    showInfo(type) {
      this.$emit('info', type);
    },
  },
};
</script>

<style>
textarea:disabled {
  background-color: #f7fafc;
  border: 1px solid #cbd5e0 !important;
  border-radius: 0.25rem !important;
  color: #718096 !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
}
</style>
