<template>
  <div :class="direction" class="w-full md:my-1 md:pr-2">
    <div class="w-full">
      <label class="text-gray-600 text-xs sm:text-base md:text-lg">
        <slot name="question"></slot>
      </label>
    </div>
    <div class="text-center">
      <div class="flex justify-between">
        <form-select-button
          ref="yes"
          silent
          :name="name"
          :immediate="loadYes(name)"
          class="mr-1 md:mr-2 w-12 h-8 sm:w-16 sm:h-10"
          title="Yes"
          @selected="selected('yes')"
        ></form-select-button>
        <form-select-button
          ref="no"
          silent
          :immediate="loadNo(name)"
          :name="name"
          class="w-12 h-8 sm:w-16 sm:h-10"
          title="No"
          @selected="selected('no')"
        ></form-select-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    FormSelectButton,
  },

  props: {
    name: { type: String, default: '' },
    vertical: { type: Boolean, default: false },
  },

  computed: {
    direction() {
      if (this.vertical) {
        return 'flex flex-col md:flex-row justify-center items-start md:items-center';
      } else {
        return 'flex items-center justify-between';
      }
    },
  },

  methods: {
    loadYes(id) {
      return store.booking.find(id) || false;
    },

    loadNo(id) {
      const val = store.booking.find(id);
      return val === undefined ? false : !val;
    },

    selected(name) {
      if (this.$refs[name].selected === false) {
        this.$refs[name].selected = true;
        this.$refs[name === 'yes' ? 'no' : 'yes'].selected = false;
        this.$emit(name);
      }
      this.$root.$emit('formEntry', {
        name: this.name || '401-1',
        value: name,
      });
    },
  },
};
</script>
