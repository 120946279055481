<template>
  <div class="w-screen mx-auto">
    <div id="defaults" class="antialiased text-gray-900">
      <div v-if="admin" class="">
        <admin-navbar></admin-navbar>
      </div>
      <div class="mx-auto md:w-3/4 lg:w-2/3">
        <div
          class="flex flex-col items-center h-full my-2 mt-4 xl:flex-row xl:justify-around md:mt-8 xl:mt-12"
        >
          <season-card
            :season="seasons.easter"
            :option="option(seasons.easter)"
            @admin="adminBooking"
            @newbooking="newBooking"
            @refresh="refresh(season_id)"
          ></season-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from '@/components/admin-navbar.vue';
import SeasonCard from '@/components/season-card.vue';
import moment from 'moment';
import { store } from '@/store';

export default {
  components: {
    AdminNavbar,
    SeasonCard,
  },

  data() {
    return {
      admin: store.request.token.admin() || false,
      areweopen: {},
      seasons: {
        easter: {
          imageURL:
            'https://images.unsplash.com/photo-1553605422-58ca7598cca3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3129&q=80',
          // imageURL: 'https://source.unsplash.com/NV4YaqJ2eZY',
          title: 'Easter 2024',
          start: '2024-04-03',
          end: '2024-04-11',
          open: process.env.VUE_APP_BOOKING_FROM,
          close: process.env.VUE_APP_BOOKING_TO,
          bookings: 0,
        },
        summer: {
          imageURL:
            'https://images.unsplash.com/photo-1621789098261-433128ee8d1e?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fHN1bW1lciUyMGJhY2tncm91bmR8ZW58MHx8MHx8&auto=format&fit=crop&w=800',
          title: 'Summer 2023',
          start: '2023-07-31',
          end: '2023-08-25',
          open: process.env.VUE_APP_BOOKING_FROM,
          close: process.env.VUE_APP_BOOKING_TO,
          bookings: 0,
        },
      },
      season_id: 8,
      season_name: 'easter',
    };
  },

  created() {
    this.refresh(this.season_id);
    // For testing can use an env date to check for openness
    const today = moment();

    const DEBUG = localStorage.getItem('exg__debug_mode');

    // eslint-disable-next-line
    if (process.env.NODE_ENV === 'development' || DEBUG == 1) {
      this.areweopen = { open: true, state: 'open_now' };
    } else {
      this.$http.get(`areweopen/${today.format()}`).then(res => {
        this.areweopen = res.data;
      });
    }
  },

  methods: {
    adminBooking() {
      this.navigate_to('admin-booking');
    },

    newBooking() {
      this.navigate_to('booking-form');
    },

    option() {
      if (this.admin) return 'admin';

      if (this.areweopen.open) {
        // somewhere between opening and closing date so allow creation or mod.
        const request = store.request.get();
        if (!request.bookings || request.bookings.length === 0) {
          return 'new';
        }
        return store.request.locked() ? 'view' : 'edit';
      } else {
        // is it too early?
        if (this.areweopen.state === 'not_yet') return 'not_yet';

        // So, it must be too late
        // Definitely cannot create new bookings but if parent had made a
        // booking *and* it has been submitted, allow them to view it
        return store.request.locked() ? 'view' : 'closed';
      }
    },

    refresh(season) {
      if (!this.admin) return;

      //!  hardcoding!!!
      this.$http
        .get(`season/${season}/bookings`)
        .then(b => b.data.data)
        .then(bookings => {
          return (this.seasons[this.season_name].bookings = Array.isArray(
            bookings
          )
            ? bookings.length || 0
            : 0);
        })
        .catch(() => {
          return 0;
        });
    },

    navigate_to(route) {
      this.$http
        .get(`season/${this.season_id}/dates`)
        .then(dates => dates.data.data)
        .then(dates => {
          store.dates.set(
            dates.sort((d1, d2) => {
              const m1 = moment(d1.date);
              const m2 = moment(d2.date);
              return m1.isBefore(m2) ? -1 : m2.isBefore(m1) ? 1 : 0;
            })
          );
          this.$router.push({ name: route });
        });
    },
  },
};
</script>

<style scoped>
body * {
  outline: 0px solid tomato;
}
</style>
