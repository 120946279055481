<template>
  <div>
    <form-section>
      <template #form-header>
        <div class="absolute right-0 top-0">
          <span class="text-red-400">*</span>
          <span class="normal-case font-thin text-xxs sm:text-sm text-gray-500">
            indicates required
          </span>
        </div>
        <form-section-header>
          <template #title>
            Declaration
          </template>
        </form-section-header>
      </template>

      <template #form-body>
        <div id="permissions" class="px-2 lg:px-0 mb-3 lg:mb-0">
          <div class="w-full">
            <div v-for="(permission, idx) in permissions" :key="idx">
              <div class="my-4 text-xs sm:text-base">
                <yes-no-buttons
                  :name="permission.id"
                  @yes="clicked(idx)"
                  @no="clicked(idx)"
                >
                  <template #question>
                    <div class="text-xs sm:text-base">
                      <span
                        class="inline-block mr-6"
                        :class="{
                          mandatory: !permission.selected,
                        }"
                      >
                        {{ permission.label }}
                      </span>
                    </div>
                  </template>
                </yes-no-buttons>
              </div>
            </div>
          </div>

          <div class="h-1 md:my-4 bg-gray-300"></div>

          <div class="flex items-center">
            <yes-only-button :name="name" @selected="agreed">
              <template #question>
                <!-- prettier-ignore -->
                <span class="inline-block text-gray-900 pr-6" :class="{ 'mandatory': !selected, }" >
                I agree to the Terms and Conditions as detailed within this
                booking form and the
                <a
                  data-pdf="behaviour_policy"
                  class="underline hover:text-gray-500"
                  href="http://thumbsupclub.co.uk/policies/behaviour_policy.pdf"
                  target="_blank"
                >
                  behaviour policy</a
                >
              </span>
              </template>
            </yes-only-button>
          </div>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import YesNoButtons from '@/components/button-group-yes-no-row.vue';
import YesOnlyButton from '@/components/button-group-yes-only.vue';
import { store } from '@/store.js';

export default {
  components: {
    YesNoButtons,
    YesOnlyButton,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      name: 'permissions.permission_6',
      permissions: [
        {
          id: 'permissions.permission_1',
          label:
            'I give permission for photos and videos of my child to be taken for use within the club setting only e.g. to be shown to me as a parent at the end of a day',
          selected: !(
            store.booking.find('permissions.permission_1') === undefined
          ),
        },
        {
          id: 'permissions.permission_4',
          label:
            'I give permission for photos of my child to be used for external purposes, such as for fundraising for the club, or on the club website',
          selected: !(
            store.booking.find('permissions.permission_4') === undefined
          ),
        },
        {
          id: 'permissions.permission_2',
          label:
            "I give permission for my child's own sun cream to be applied by club staff",
          selected: !(
            store.booking.find('permissions.permission_2') === undefined
          ),
        },
        {
          id: 'permissions.permission_3',
          label: 'I give permission for my child to have his/her face painted',
          selected: !(
            store.booking.find('permissions.permission_3') === undefined
          ),
        },
        {
          id: 'permissions.permission_5',
          label:
            'I agree that I have advised club of any activities which my child should not do e.g. bouncy castles',
          selected: !(
            store.booking.find('permissions.permission_5') === undefined
          ),
        },
        {
          id: 'permissions.permission_7',
          label: 'Has your child visited Thumbs Up Club before?',
          selected: !(
            store.booking.find('permissions.permission_7') === undefined
          ),
        },
      ],
      selected: store.booking.find('permissions.permission_6'),
    };
  },

  created() {
    this.check_completed();
  },

  methods: {
    agreed(val) {
      this.selected = val;
      this.check_completed();
    },

    clicked(idx) {
      this.permissions[idx].selected = true;
      this.check_completed();
    },

    check_completed() {
      let done = this.selected;
      // If the main (last) button is not Yes then no point checking the others.
      if (done) {
        done = this.permissions.map(p => p.selected).every(p => p);
      }
      this.$emit('entry', this.section, done);
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  margin-left: 0px;
  color: #fc8181;
  content: '*';
}
</style>
