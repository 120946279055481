<template>
  <div
    class="flex items-center justify-center mr-4 md:mr-10 text-indigo-400 hover:text-indigo-600 cursor-pointer"
  >
    <div class="h-4 w-4 sm:h-6 sm:w-6 " @click="handle('closeView')">
      <close-icon type="outline" @click="handle('closeView')"></close-icon>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icons/close.vue';

export default {
  components: {
    CloseIcon,
  },

  methods: {
    handle(action) {
      this.$emit(action);
    },
  },
};
</script>
