import App from './App.vue';
import axios from 'axios';
import router from './router';
import { store } from '@/store.js';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import './registerServiceWorker';
import '@/assets/css/tailwind.css';
import '@/assets/css/styles.css';
import LogRocket from 'logrocket';

const setup = () => {
  if (process.env.VUE_APP_MODE && process.env.VUE_APP_MODE === 'staging') {
    return { protocol: 'https', server: 'dev.exegi.software' };
  } else {
    return process.env.NODE_ENV === 'production'
      ? { protocol: 'https', server: 'api.exegi.software' }
      : { protocol: 'https', server: 'api.exegi.software' };
  }
};

/**
 * Start logging the session as soon as possible
 */
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('9qgraa/easter-2024');
}

const SERVER_OPTS = setup();

const baseURL = `${SERVER_OPTS.protocol}://${SERVER_OPTS.server}/thumbsupclub/v1/`;

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  console.log(`API: ${baseURL}`);
}

// https://vuejs.org/v2/cookbook/adding-instance-properties.html
Vue.prototype.$http = axios.create({ baseURL });

// Refreshing the browser will bring us back here but will not have cleared
// local storage so (if there is a token already there) set up the Auth header.
if (store.request.token.get()) {
  Vue.prototype.$http.defaults.headers.common[
    'Authorization'
  ] = store.request.token.get();
}

//* Used for telling the dates section that a date of birth has been entered.
const eventBus = new Vue();

//* Used in several places to talk to the server
const connector = new Vue();
connector.getBooking = function(id) {
  return this.$http
    .get(`/bookings/${id}`)
    .then(rec => rec.data.data)
    .catch(e => e);
};

//TODO implement a /bookings/me endpoint in the server which will return all
//TODO bookings for the user specified in the token.
connector.getBookings = function(ids) {
  return Promise.all(ids.map(id => this.getBooking(id)));
};

export { connector, eventBus };

// Base settings
Vue.config.productionTip = false;
Vue.use(Vuelidate);

// Register base components
import FormSection from '@/components/form-section.vue';
import FormSectionHeader from '@/components/form-section-header.vue';
import FormDateInput from '@/components/form-date-input.vue';
import FormSelect from '@/components/form-select.vue';
import FormTextNoinput from '@/components/form-text-noinput.vue';
import FormTextInput from '@/components/form-text-input.vue';
import FormInputLabel from '@/components/form-input-label.vue';
import InfoAlert from '@/components/info-alert.vue';
import InfoWarning from '@/components/info-warning.vue';
import PageAccordion from '@/components/page-accordion.vue';

Vue.component('form-section', FormSection);
Vue.component('form-section-header', FormSectionHeader);
Vue.component('form-select', FormSelect);
Vue.component('form-text-input', FormTextInput);
Vue.component('form-text-noinput', FormTextNoinput);
Vue.component('form-date-input', FormDateInput);
Vue.component('form-input-label', FormInputLabel);
Vue.component('info-alert', InfoAlert);
Vue.component('info-warning', InfoWarning);
Vue.component('page-accordion', PageAccordion);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
