<template>
  <page-accordion>
    <header slot="title">Toileting</header>
    <section slot="content">
      <toileting-section></toileting-section>
    </section>
  </page-accordion>
</template>

<script>
import ToiletingSection from '@/components/sections/toileting-section.vue';

export default {
  components: {
    ToiletingSection,
  },
};
</script>
