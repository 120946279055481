<template>
  <div class="w-screen h-screen bg-gray-100">
    <div class="w-11/12 mx-auto mt-2 lg:mt-8 xl:mt-12 lg:w-3/5 xl:w-1/3">
      <div
        class="flex flex-col items-center pt-8 pb-12 bg-white rounded-lg shadow-xl"
      >
        <div class="flex flex-col items-center">
          <h2 class="text-xl text-center text-indigo-800 lg:text-3xl">
            Log in to TUC
          </h2>
          <!--
          -- Commented out until new families can be accepted.
          <h4 class="mt-2 text-sm italic text-indigo-700">
            Don't have an account yet?
            <a
              class="font-thin underline lg:font-normal"
              href="#"
              @click.stop="register"
              >Sign up here</a
            >
          </h4>
          -->
          <!-- 
            -- Update to comment above (dec 2023) 
                It was decided to admit new families from the waiting list. Rather than allowing anyone to register
                i.e. reinstating the code above, it was instead agreed that families would be invited. If they were
                still interested then I added their email to the user table but with status of 'new' 
                Then, first time they login the server API call /verify would recognise them as new and the UI would
                simply redirect them to the password reset page so they can enter their password and then login as normal.
          -->
        </div>
        <div v-if="loginError" class="flex mt-2 text-red-800">
          <svg
            class="w-6 h-6 p-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              class="fill-current"
              d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
            />
          </svg>
          <span class="ml-1 font-semibold">Invalid email or password</span>
        </div>
        <form class="flex flex-col items-center w-full mt-6">
          <div class="w-3/4">
            <form-input-label title="User name"></form-input-label>
            <div class="flex">
              <input
                v-model="userid"
                autocomplete="username"
                type="text"
                :class="classes"
                :disabled="showPassword"
                name="loginUser"
                placeholder="Email"
              />
              <button
                v-if="!showPassword"
                type="button"
                class="px-1 ml-3 text-blue-600 transition duration-300 border border-blue-600 rounded-md shadow-md shadow-blue-200 hover:text-blue-700 hover:bg-blue-200 hover:shadow-none"
                @click="checkUser"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="3.0"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button>
              <button
                v-else
                type="button"
                class="px-1 ml-3 text-gray-500 border border-gray-400 rounded-md cursor-not-allowed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="3.0"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button>
            </div>
            <p
              v-if="invalidUser"
              class="pl-2 font-semibold leading-loose tracking-wide text-orange-600"
            >
              Invalid user
            </p>
          </div>
          <div v-if="showPassword" class="w-3/4 mt-4 transition duration-300">
            <form-input-label title="Password"></form-input-label>
            <div class="flex content-center">
              <input
                ref="password"
                v-model="password"
                autocomplete="current-password"
                type="password"
                :class="classes"
                name="loginPassword"
                placeholder="Password"
              />
              <div class="text-blue-400" @click="togglePassword">
                <template v-if="showing">
                  <svg
                    class="inline-block w-8 h-8 px-1 py-1 ml-2 fill-current md:h-10 md:w-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
                    />
                  </svg>
                </template>
                <template v-else>
                  <svg
                    class="inline-block w-8 h-8 px-1 py-1 ml-2 fill-current md:h-10 md:w-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M 0.2 10 a 11 11 0 0 1 19.6 0 A 11 11 0 0 1 0.2 10 Z m 9.8 4 a 4 4 0 1 0 0 -8 a 4 4 0 0 0 0 8 Z m 0 -2 a 2 2 0 1 1 0 -4 a 2 2 0 0 1 0 4 Z"
                    />
                  </svg>
                </template>
              </div>
            </div>
            <div class="">
              <a
                class="text-sm text-gray-600 hover:underline"
                href="#"
                @click.stop="forgotten"
                >Forgot your password?</a
              >
            </div>
            <div class="flex mt-2 text-center md:mt-8">
              <div
                v-if="userid.length && password.length"
                class="px-8 py-2 text-white bg-blue-400 rounded shadow-lg hover:bg-blue-600"
                @click="doLogin"
              >
                Login
              </div>
              <div
                v-else
                class="px-8 py-2 text-gray-200 bg-gray-400 rounded shadow"
              >
                Login
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputLabel from '@/components/form-input-label.vue';
import { store } from '@/store.js';
import jwtdecode from 'jwt-decode';
import LogRocket from 'logrocket';
// import moment from 'moment';

export default {
  components: {
    FormInputLabel,
  },

  data() {
    return {
      invalidUser: false,
      loginError: false,
      password: '',
      showing: false,
      showPassword: false,
      userid: '',
    };
  },

  computed: {
    classes() {
      return [
        'w-full',
        'h-8',
        'md:h-10',
        'pl-2',
        'text-base',
        'md:text-lg',
        'text-blue-500',
        'rounded-md',
        'border md:border-2',
        'border-gray-300',
      ];
    },
  },

  created() {
    store.request.token.set('');
  },

  methods: {
    async checkUser() {
      const resp = (await this.$http.post(`verifyuser`, { id: this.userid }))
        .data;

      if (resp.success && resp.message !== 'invalid') {
        this.invalidUser = false;
        if (resp.message === 'active') {
          this.showPassword = true;
        } else {
          this.forgotten();
        }
      } else {
        this.invalidUser = true;
      }
    },

    async doLogin() {
      try {
        const resp = await this.$http.post(
          `connect`,
          {},
          { auth: { username: this.userid, password: this.password } }
        );

        // Attach a token to all future requests
        this.$http.defaults.headers.common['Authorization'] = resp.data.token;
        // Save the token so it can be used to extract data when needed.
        store.request.token.set(resp.data.token);
        // Get some of that data from the token.
        // eslint-disable-next-line
        const { role: user_role, sub: userid, name } = jwtdecode(
          resp.data.token
        );

        /**
         * Now we have useful info about who just logged in
         */
        if (process.env.NODE_ENV === 'production') {
          LogRocket.identify(String(this.userid), {
            id: userid,
            name,
            token: resp.data.token,
          });
        }

        // It's unlikely to happen IRL but two users sharing a PC could see
        // each others bookings. To avoid this, if there is a booking in
        // progress i.e. in storage, check it's user and if it's different to
        // the logging in user, wipe it out.
        if (store.request.get() && store.request.userid() !== userid) {
          store.request.create();
        }

        // Immediately after login check if there is a request in the db for
        // the logged in user. If there is, it needs to be loaded into local
        // storage so it will be shown and cannot be edited (except for the
        // case where the requestor is also an admin).
        const request = (await this.$http.get('request/me')).data.data;

        if (request.hasOwnProperty('bookings') && request.bookings.length > 0) {
          store.request.rebuild(request);
        }

        this.$router.push({ name: 'home-screen' });
      } catch (error) {
        store.request.token.set('');
        this.loginError = true;
      }
    },

    forgotten() {
      this.$router.push({ name: 'forgotten' });
    },

    register() {
      this.$router.push({ name: 'register' });
    },

    togglePassword() {
      // show/hide the password being entered
      const input = this.$refs['password'];
      input.type = input.type === 'text' ? 'password' : 'text';

      // set a flag to control which icon is used to toggle the password display
      this.showing = this.$refs['password'].type === 'text';
    },
  },
};
</script>
