<template>
  <form-section>
    <template #form-header>
      <form-section-header v-if="title">
        <template #title>
          <span :id="id">{{ title }}</span>
        </template>
        <template #info>{{ info }}</template>
      </form-section-header>
      <div
        v-else
        class="flex text-indigo-400 mt-1 md:mt-4 text-xs sm:text-sm md:text-md"
      >
        <span class="w-full md:w-3/4">{{ info }}</span>
        <div v-if="canhide">
          <span
            class="btn-pill w-16 md:block text-xs sm:text-sm"
            @click="hidden = !hidden"
            >{{ hidden ? 'show' : 'hide' }}</span
          >
        </div>
      </div>
    </template>

    <template #form-body>
      <div v-if="same" class="text-xs sm:text-sm text-red-500">
        These carers cannot be the same person or share the same mobile phone
      </div>
      <div v-if="title" class="h-3"></div>
      <div
        v-if="!hidden"
        class="flex flex-col md:flex-row md:flex-wrap w-full px-2 lg:px-0 md:mt-3"
      >
        <div class="md:w-1/3">
          <form-text-input
            v-if="hasAttr('name')"
            :name="name('name')"
            title="First Name"
            @user-entry="userInput"
          ></form-text-input>
        </div>
        <div class="md:w-1/3">
          <form-text-input
            v-if="hasAttr('surname')"
            :name="name('surname')"
            title="Last Name"
            @user-entry="userInput"
          ></form-text-input>
        </div>
        <div class="md:w-1/3">
          <form-telephone-input
            v-if="hasAttr('homePhone')"
            :name="name('homePhone')"
            title="Home phone"
            @user-entry="userInput"
          ></form-telephone-input>
        </div>
        <div class="md:w-1/3">
          <form-telephone-input
            v-if="hasAttr('workPhone')"
            :name="name('workPhone')"
            title="Work phone"
            @user-entry="userInput"
          ></form-telephone-input>
        </div>
        <div class="md:w-1/3">
          <form-telephone-input
            v-if="hasAttr('mobilePhone')"
            :name="name('mobilePhone')"
            title="Mobile"
            @user-entry="userInput"
          ></form-telephone-input>
        </div>
      </div>
    </template>
  </form-section>
</template>

<script>
import { store } from '@/store';
import FormTelephoneInput from '@/components/form-telephone-input.vue';

export default {
  components: {
    FormTelephoneInput,
  },

  props: {
    id: { type: String, default: '' },
    title: { type: String, default: '' },
    info: { type: String, default: '' },
    same: { type: Number, default: 0 },
    attrs: { type: Array, default: () => [] },
    hide: { type: Boolean, default: false },
  },

  data() {
    return {
      canhide: this.hide,
      hidden: this.hide,
      inputs: [],
    };
  },

  created() {
    this.inputs = this.attrs.map(attr => ({
      name: this.name(attr),
      input: store.booking.find(this.name(attr)) ? true : false,
    }));
    this.checkComplete();
  },

  methods: {
    hasAttr(attr) {
      return this.attrs.findIndex(elem => elem === attr) !== -1;
    },

    name(name) {
      return `${this.id}_${name}`;
    },

    userInput(...[name, value]) {
      this.inputs.find(f => f.name === name).input = value ? true : false;
      this.checkComplete();
    },

    checkComplete() {
      const required = this.inputs.filter(
        input => !input.name.endsWith('workPhone')
      );
      // eslint-disable-next-line
      this.$emit(
        'name-block',
        required.map(f => f.input).every(b => b)
      );
    },
  },
};
</script>
