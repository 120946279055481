<template>
  <div class="w-full lg:w-2/3">
    <div
      class="lg:rounded shadow bg-white text-orange-500 text-sm italic mt-2 mb-3 lg:my-1 px-2 py-1"
    >
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
