<template>
  <form-section>
    <template #form-header>
      <div class="absolute top-0 right-0">
        <span class="text-red-400">*</span>
        <span class="normal-case font-thin text-xxs sm:text-sm text-gray-500">
          indicates required
        </span>
      </div>
      <form-section-header>
        <!-- prettier-ignore -->
        <template #title>Medical Information</template>
      </form-section-header>
    </template>

    <template #form-body>
      <div class="flex flex-col w-full px-2 lg:px-0">
        <div class="w-1/3">
          <form-text-input
            name="gp.fullname"
            title="GP name"
            @user-entry="capture"
          ></form-text-input>
        </div>
        <div class="w-2/3">
          <form-text-input
            area
            name="gp.address"
            size
            title="GP address"
            @user-entry="capture"
          ></form-text-input>
        </div>
        <div class="w-1/3">
          <form-telephone-input
            name="gp.phone"
            title="GP phone"
            @user-entry="capture"
          ></form-telephone-input>
        </div>
      </div>
    </template>
  </form-section>
</template>

<script>
import { store } from '@/store';
import FormTelephoneInput from '@/components/form-telephone-input.vue';

export default {
  components: {
    FormTelephoneInput,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      entered: {
        'gp.fullname': false,
        'gp.address': false,
        'gp.phone': false,
      },
    };
  },

  created() {
    Object.keys(this.entered).forEach(k => {
      this.entered[k] = store.booking.find(k) ? true : false;
    });
    this.check_complete();
  },

  methods: {
    capture(...[name, val]) {
      this.entered[name] = val ? true : false;
      this.check_complete();
    },

    check_complete() {
      const done = Object.values(this.entered).every(b => b);
      this.$emit('entry', this.section, done);
    },
  },
};
</script>
