<template>
  <div class="flex items-center w-full md:my-1 md:pr-2">
    <div class="w-full">
      <label class="text-base text-gray-600 md:text-lg">
        <slot name="question"></slot>
      </label>
    </div>
    <div>
      <form-select-button
        ref="yes"
        disabled
        silent
        :name="name"
        :immediate="load(name)"
        :classes="classes"
        class="mr-1 md:mr-2"
        title="Yes"
        @selected="selected()"
      ></form-select-button>
    </div>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    FormSelectButton,
  },

  props: {
    name: { type: String, default: '' },
  },

  computed: {
    classes() {
      return 'w-16 h-10';
    },
  },

  methods: {
    load(id) {
      // Can get back undefined or value yes/no - only return true if "yes"
      // because undefined means no button clicked yet
      const val = store.booking.find(id);
      return val === undefined ? false : val;
    },

    selected() {
      this.$refs.yes.selected = !this.$refs.yes.selected;
      const value = this.$refs.yes.selected;

      this.$root.$emit('formEntry', {
        name: this.name || '401-1',
        value: value ? 'yes' : 'no',
      });

      this.$emit('selected', value);
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  margin-left: 2px;
  color: #fc8181;
  content: '*';
}
</style>
