'use strict';

import moment from 'moment';
import { store } from '@/store';

/**
 * Used to calculate an age based on the child DOB and the start date of the
 * current season.
 */
const getAge = dob => {
  return dob
    ? moment(store.dates.season.start()).diff(moment(dob), 'years')
    : 0;
};

/**
 * Convert an array of requested dates into a second array with one element per
 * date in th ecurrent season. For each requested date in the season the output
 * array will contain true, otherwise false.
 */
const season = store.dates.get();
const mapper = dates =>
  season.map(day => dates.some(dt => moment(day.date).isSame(dt)));

/**
 * Logic to set various flags based on specified conditions.
 * Each type of flag is processed by a function and these are chained together as
 * required by the set_flags middleware function.
 */
const behaviour = booking => {
  booking.flags.behaviour =
    booking['behaviour.options_1'] === 'yes' ? true : false;
  return booking;
};
const toilet = booking => {
  booking.flags.toilet = false;
  return booking;
};
const feeding = booking => {
  booking.flags.feeding = false;
  return booking;
};

/**
 * Expose functions that each take in an array of bookings, transform or augment
 * each booking in some way and then return the booking.
 */
export default {
  calculate_age: bookings => {
    return bookings.map(booking => {
      booking['participant.age'] = getAge(booking['participant.dob']);
      return booking;
    });
  },

  calculate_totals: bookings => {
    const dates = season.map(d => d.date);
    return bookings.reduce(
      (acc, b) => {
        const t = b['booking.dates'].map(dt => {
          return dates.findIndex(sdt => sdt === dt);
        });
        // t = [0,3,5] // where numbers are the location in the seasons array
        t.forEach(idx => {
          if (acc[idx]) {
            acc[idx] += 1;
          } else {
            acc[idx] = 1;
          }
        });
        return acc;
      },
      [0, 0, 0, 0, 0, 0]
    );
  },

  // This one is different. Partially apply with an array of payment receipts
  // and then use that to add receipt and paid on dates if they exist
  set_receipts: rx => bookings => {
    return bookings.reduce((acc, booking) => {
      const payment = rx.find(
        elem => elem.request_id === booking['request.id']
      );

      booking['payment'] = payment;

      // Filtering depends on a boolean type flag and that doesn't exist as
      // standard for payments. Create one here so the admin page can use it.
      booking['paid'] = payment && payment['paid_on'] ? 'yes' : 'no';

      acc.push(booking);
      return acc;
    }, []);
  },

  set_flags: bookings => {
    return bookings.map(booking => {
      booking.flags = {};
      return behaviour(feeding(toilet(booking)));
    });
  },

  set_requested_dates: bookings => {
    return bookings.reduce((acc, booking) => {
      booking['requested'] = mapper(booking['booking.dates']);
      acc.push(booking);
      return acc;
    }, []);
  },
};
