<template>
  <!--
  Yes. Dates are hard-coded for Summer 2021 because I didn't have time to do
  anything else and needed to get something functional when there are 20 days
  to squeeze into the UI.
  -->
  <div
    class="flex items-center justify-between w-full text-sm font-thin text-white bg-indigo-800 md:text-base"
  >
    <div class="grid w-full grid-cols-12 px-1">
      <div class="col-span-2"></div>
      <div class="col-span-3 ">
        <div class="grid grid-cols-6">
          <div class="col-span-1 text-sm text-center bg-transparent">Ep</div>
          <div class="col-span-1 text-sm text-center bg-transparent">Asth</div>
          <div class="col-span-1 text-sm text-center bg-transparent">Whc</div>
          <div class="col-span-1 text-sm text-center bg-transparent">Bite</div>
          <div class="col-span-1 text-sm text-center bg-transparent">New</div>
          <div class="col-span-1 text-sm text-center bg-transparent">Paid</div>
        </div>
      </div>
      <div class="col-span-6">
        <div class="flex justify-between text-sm">
          <div
            v-for="(requested, did) in dates"
            :key="did"
            class="w-12 text-center bg-transparent"
          >
            {{ requested.split('-')[2] }}
          </div>
        </div>
      </div>
      <div class="col-span-1"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { store } from '@/store';

const formatter = booking => {
  // Take an array filtered to include only accepted date and return an object
  // with one key-value pair per date
  const dt = d => accepted =>
    d.reduce((obj, d, idx) => {
      obj[`date${idx + 1}`] = accepted[idx] ? d : '';
      return obj;
    }, {});

  const obj = {
    name: booking['participant.name'],
    surname: booking['participant.surname'],
    age: booking['participant.age'],
    address: booking['address.address'].split('\n').join(','),
    postcode: booking['address.postcode'],
    email: booking.payment.userid,
    phone1: booking['carers.carer_one_mobilePhone'],
    phone2: booking['carers.emergency_contact_mobilePhone'],
  };

  return {
    ...obj,
    ...dt(booking['booking.dates'])(booking['accepted']),
  };
};

export default {
  filters: {
    format_date(d) {
      return moment(d).format('D');
    },
  },

  components: {},

  props: {
    approved: { type: Boolean, default: false },
    bookings: { type: Array, default: () => [] },
  },

  data() {
    return {
      dates: [], // in *this* component will be an array of dates

      exportFormatter: formatter,

      // Sortable columns are declared and tracked in here
      direction: {
        'participant.surname': 'asc',
        'participant.age': '',
      },

      // Keep track of which columns are currently being filtered
      filtering: {
        transport: false,
        'equipment.options_1': false,
        'behaviour.options_1': false,
        'permissions.permission_7': false,
        paid: false,
        dates: {},
      },

      // Define what must be true for a filter to return results in each of the
      // filterable columns
      filters: {
        transport: 'yes',
        'equipment.options_1': 'yes',
        'behaviour.options_1': 'yes',
        'permissions.permission_7': 'no',
        paid: 'yes',
      },

      showPaid: 'Y',

      toggled: false,
    };
  },

  created() {
    this.dates = store.dates.get().map(dt => dt.date);
  },

  methods: {
    filterClass(name, val = null) {
      if (name === 'dates') {
        if (this.filtering[name][val]) {
          return 'bg-green-200 text-green-800 border-green-600';
        }
      } else {
        if (this.filtering[name]) {
          return 'bg-green-200 text-green-800 border-green-600';
        }
      }
      return 'bg-white text-indigo-500';
    },

    sort(name) {
      //!
      //TODO There is a bug where clicking the age to sort, clicking surname
      //TODO and then back on age makes the age arrow the opposite of what it
      //TODO should be. It's because the grid component always toggles sort order
      //TODO even when the column has been sorted before.
      //TODO Leaving it for now because it's not major and I have loads to do!
      //!

      const opposite = old => (old === 'dsc' ? 'asc' : 'dsc');

      const prevDirection = this.direction[name];

      // Reset all icons to neutral
      Object.keys(this.direction).forEach(k => (this.direction[k] = ''));

      if (prevDirection === '') {
        // Clicked on a previously unclicked column.
        this.direction[name] = 'dsc';
      } else {
        // Toggle the icon of a selected column direction
        this.direction[name] = opposite(prevDirection);
      }

      this.$emit('sort', name);
    },

    toggleApproveAll() {
      this.toggled = !this.toggled;
      this.$emit('toggle', this.toggled);
    },

    // This is a filter within a filter. If user wants to filter based on whether
    // booking has been paid, they first click the paid filter and may then
    // switch between showing all those who have paid and all those who have not.
    // Clicking the main paid toggle simply removes all filtering.
    togglePaid() {
      this.showPaid = this.showPaid === 'Y' ? 'N' : 'Y';
      this.filters['paid'] = this.showPaid === 'Y' ? 'yes' : 'no';
      this.$emit('filter', 'paid', this.filters['paid']);
    },

    // First stab - used for Y/N columns so clicking filter will show only Y
    // Clicking again removes the filter for that column but doesn't touch any
    // other active filters.
    // Filtering is an all or nothing thing. If filtering then match against the
    // value declared in the filters object. If filtering is switched off then
    // tell the parent to ignore this column in filtering i.e. it is not
    // possible to filter for all Y or all N - either all Y or everything.
    filter(name) {
      this.filtering[name] = !this.filtering[name];
      this.$emit(
        'filter',
        name,
        this.filtering[name] ? this.filters[name] : 'off'
      );
    },

    // First stab - decided to allow filtering on one date only at any time.
    filterDate(idx) {
      // This resets all dates to false
      const reset = obj => {
        Object.keys(obj).forEach(k => (obj[k] = false));
        return obj;
      };

      const dt = this.dates[idx]; // e.g. 2020-02-10

      // The date to be filtered may not already have been clicked but if it has
      // toggle it.
      const toggle = this.filtering.dates[dt]
        ? !this.filtering.dates[dt]
        : true;

      // Reset the entire date filter object to false, apart from the one just
      // clicked
      this.filtering.dates = { ...reset(this.filtering.dates), [dt]: toggle };

      // Send the only selected date to the parent grid component
      const filterDate = Object.entries(this.filtering['dates'])
        .map(([k, v]) => (v ? k : ''))
        .filter(b => b);

      this.$emit('filter', 'dates', filterDate[0]);
    },
  },
};
</script>

<style>
body * {
  outline: 0px solid red !important;
}
</style>
