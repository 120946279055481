<template>
  <div class>
    <div
      class="relative hidden lg:block text-indigo-700 text-xs sm:text-base md:text-lg"
    >
      <slot name="title"></slot>
    </div>
    <div class="flex text-indigo-500 text-xs sm:text-sm md:text-base">
      <slot name="info"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
