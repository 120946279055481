<template>
  <div
    class="flex items-center justify-between w-full text-sm text-indigo-600 md:text-base"
    :class="zebra"
  >
    <div class="grid items-center w-full grid-cols-12 p-0">
      <div
        v-if="showEmail"
        class="flex items-center col-span-2 transition duration-300"
      >
        <button
          class="pr-2 text-indigo-500 hover:text-indigo-600"
          @click="showEmail = false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
            />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
        </button>
        <span class="">
          <a class="underline" :href="mailto">
            {{ payment.userid }}
          </a>
        </span>
      </div>
      <div v-else class="col-span-2 transition duration-300">
        <div class="flex flex-col">
          <div class="flex items-center ">
            <button
              class="pr-2 text-indigo-400 hover:text-indigo-600"
              @click="showEmail = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div class="flex justify-between w-full">
              <div class="flex justify-start">
                {{ booking['participant.surname'] }},
                {{ booking['participant.name'] }}
              </div>
              <div class="px-4">
                {{ booking['participant.age'] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- flags, paid -->
      <div class="col-span-3">
        <div class="grid grid-cols-6 ">
          <div
            class="col-span-1 text-center bg-transparent border-l border-r border-gray-500"
          >
            <span class="text-xl leading-loose">
              {{
                booking['disability.epilepsy'] === 'yes' ? '&#x2713;' : '&#160;'
              }}
            </span>
          </div>
          <div
            class="col-span-1 text-center bg-transparent border-r border-gray-500"
          >
            <span class="text-xl leading-loose">
              {{
                booking['disability.asthma'] === 'yes' ? '&#x2713;' : '&#160;'
              }}
            </span>
          </div>
          <div
            class="col-span-1 text-center bg-transparent border-r border-gray-500"
          >
            <span class="text-xl leading-loose">
              {{
                booking['equipment.options_1'] === 'yes' ? '&#x2713;' : '&#160;'
              }}
            </span>
          </div>
          <div
            class="col-span-1 text-center bg-transparent border-r border-gray-500"
          >
            <span class="text-xl leading-loose">
              {{
                booking['behaviour.options_1'] === 'yes' ? '&#x2713;' : '&#160;'
              }}
            </span>
          </div>
          <div
            class="col-span-1 text-center bg-transparent border-r border-gray-500"
          >
            <span class="text-xl leading-loose">
              {{
                booking['permissions.permission_7'] !== 'yes'
                  ? '&#x2713;'
                  : '&#160;'
              }}
            </span>
          </div>
          <div
            class="flex items-center justify-center w-full col-span-1 text-center border-r border-gray-500"
          >
            <div v-if="payment.paid_on" class="text-center">
              <div
                v-if="payment.receipt === 'bacs'"
                class="mx-1 text-xs text-white bg-green-500 border border-yellow-400 rounded-full"
              >
                BACS
              </div>
              <div
                v-else-if="payment.receipt === 'cv'"
                class="mx-1 text-xs text-white bg-green-500 border border-yellow-400 rounded-full"
              >
                CV
              </div>
              <div
                v-else-if="payment.receipt === null"
                class="px-2 py-1 mx-1 text-xs text-white bg-gray-500 border border-gray-500 rounded-full"
              >
                Other
              </div>
              <div
                v-else
                class="px-2 py-1 mx-1 text-xs text-white bg-indigo-600 border border-indigo-400 rounded-full hover:bg-indigo-200 hover:text-indigo-600"
              >
                <a :href="payment.receipt" class="" target="_blank">{{
                  paid_on
                }}</a>
              </div>
            </div>
            <div v-else class="w-1/6"></div>
          </div>
        </div>
      </div>

      <!-- dates -->
      <div class="col-span-6">
        <div class="flex justify-between h-full text-sm">
          <div
            v-for="(requested, did) in dates"
            :key="did"
            class="w-12 h-full text-center bg-transparent "
          >
            <admin-booking-button
              v-if="requested !== undefined"
              :date="did"
              :clicked="accepted[did]"
              :paid="bookingAccepted"
              :requested="requested"
              @chosen="choose"
            ></admin-booking-button>
            <div v-else><span>NA</span></div>
          </div>
        </div>
      </div>

      <!-- admin controls -->
      <div class="grid items-center col-span-1">
        <div class="grid grid-cols-4">
          <div class="col-span-1 bg-transparent"></div>
          <div class="flex items-center justify-center col-span-1 ">
            <div v-if="canAccept && !bookingAccepted">
              <admin-booking-button
                square
                :date="1"
                :paid="false"
                :requested="true"
                :clicked="approved"
                @ticked="setApproved"
              ></admin-booking-button>
            </div>
          </div>
          <div class="flex justify-center w-4 h-4 col-span-1">
            <view-edit-icon
              :id="booking.id"
              type="view"
              @selected="selectedBooking"
            />
          </div>
          <div class="flex justify-center w-4 h-4 col-span-1 ">
            <view-edit-icon
              :id="booking.id"
              type="edit"
              @selected="selectedBooking"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminBookingButton from '@/components/admin-booking-button.vue';
import ViewEditIcon from '@/assets/icons/view-edit.vue';
import moment from 'moment';

export default {
  filters: {
    flag(f) {
      return f ? 'T' : 'F';
    },
    flag_score(fs) {
      return Object.keys(fs).reduce((acc, f) => {
        acc += fs[f] ? 1 : 0;
        return acc;
      }, 0);
    },
    binary(b) {
      return Number(b) === 1 ? 'Y' : '';
    },
    reverse(v) {
      return v.toLowerCase() === 'yes' ? 'no' : 'yes';
    },
  },

  components: {
    AdminBookingButton,
    ViewEditIcon,
  },

  props: {
    booking: { type: Object, default: () => {} },
    idx: { type: Number, default: 0 },
  },

  data() {
    return {
      id: this.booking.id,
      dates: [], // in *this* component will be an array of booleans
      flags: {}, // keys: toilet, feeding, behaviour
      accepted: [], // array of date flags
      approved: false, // will be true when admin clicks tick box for the booking
      payment: this.booking.payment,
      showEmail: false,
    };
  },

  computed: {
    bookingAccepted() {
      return this.payment.amount_due ? true : false;
    },

    canAccept() {
      return this.accepted.some(b => b);
    },

    mailto() {
      return `mailto:${this.payment.userid}`;
    },

    paid_on() {
      return this.payment.paid_on
        ? moment(this.payment.paid_on).format('DD MMM')
        : '';
    },

    zebra() {
      return this.idx % 2 === 0 ? 'bg-white' : 'bg-indigo-200';
    },
  },

  created() {
    // Start with array of dates and a matching array of false for each date.
    // The length of these arrays is the number of days this season.

    this.dates = [...this.booking.requested];
    this.accepted = new Array(this.dates.length).fill(false);

    // Clone the days accepted array (which contains 0 or 1 values).
    // This array only contains values for every requested date so will always
    // be shorter than the previous 2 arrays.
    // So, for every actually requested day, remove a 0 or 1 from the accepted
    // array and use that to determine whether the date should be red or green.
    const accepted = [...this.booking.accepted];

    this.dates.forEach((requested, idx) => {
      if (requested) {
        this.accepted[idx] = accepted.shift() === 1 ? true : false;
      }
    });

    this.flags = this.booking.flags;
    //TODO Worry about Summer later but this code creates an array of 20 dates
    //TODO which causes major issues for the layout (which will need to be
    //TODO solved before Summer 2020)
    // const MAX = 20;
    // const padding = new Array(MAX - this.dates.length);
    // this.dates.push(...padding);
    //TODO end
  },

  methods: {
    choose(...args) {
      this.$set(this.accepted, args[0], args[1]);
      this.$emit('chosen', [this.booking, ...args]);
    },

    selectedBooking(...args) {
      this.$emit('selected', ...args);
    },

    // Setting the approved flag can happen by clicking the button in *this* row
    // -or- by clicking a button in the header.
    // Both actions call this method (the header click is sent to the grid which
    // calls this method on all rows with at least one accepted date).
    // The difference is that the event that triggers the controller to retrieve
    // all approved bookings should be sent only once per button click.
    // If it happens here, send the even. If it happened in the header, the grid
    // will send the event.
    setApproved(state, emit = true) {
      this.approved = state;
      if (emit) {
        this.$emit('acceptBooking');
      }
    },
  },
};
</script>

<style scoped>
body * {
  outline: 0px solid tomato !important;
}
</style>
