<template>
  <div :class="width">
    <form-input-label
      :mandatory="mandatory"
      :name="name"
      :info="info"
      title="Date of Birth"
    ></form-input-label>
    <div v-if="noinput">
      <form-text-noinput :value="toString(name)"></form-text-noinput>
    </div>
    <div v-else>
      <input
        type="text"
        :name="name"
        :value="load(name)"
        placeholder="dd/mm/yyyy"
        :class="css"
        @keyup.enter="enter"
        @blur="enter"
      />
      <div v-if="$v.dob.$model && !$v.dob.range">
        <span class="text-xs italic font-semibold text-red-400">
          Must be between {{ range.min.format('D MMM YYYY') }} and
          {{ range.max.format('D MMM YYYY') }}
        </span>
      </div>
      <div v-if="$v.dob.$model && !$v.dob.valid">
        <span class="text-xs italic font-semibold text-red-400">
          Not a valid date
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from './form-date-input';
import { eventBus } from '@/main.js';
import { helpers } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  extends: DateInput,

  props: {
    range: { type: Object, default: () => {} },
  },

  data() {
    return {
      dob: '',
      name: 'participant.dob',
    };
  },

  methods: {
    enter(ev) {
      this.dob = ev.target.value;
      this.$v.dob.$touch();
      if (!this.$v.dob.$anyError) {
        this.selected(this.dob);
        // As well as usual events, also tell the dates section if it can be enabled
        eventBus.$emit('dob-entry', this.dob ? true : false);
      }
    },
  },

  validations: {
    dob: {
      valid: dt => !helpers.req(dt) || moment(dt, 'D/M/YYYY', true).isValid(),
      range: function(dt) {
        if (!helpers.req(dt)) return true;
        if (!this.$v.dob.valid) return true;
        return moment(dt, 'D/M/YYYY', true).isBetween(
          this.range.min,
          this.range.max,
          null,
          '[]'
        );
      },
    },
  },
};
</script>
