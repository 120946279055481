<template>
  <modal>
    <div
      slot="header"
      class="px-2 md:px-4 md:py-4 bg-indigo-500 text-base md:text-xl text-white sm:rounded-t shadow-lg"
    >
      <slot name="header"></slot>
    </div>

    <div slot="body" class="px-2 md:mb-4 md:mt-8">
      <div class="flex flex-col">
        <label class="text-indigo-600">
          <div class="md:mx-4">
            <div class="md:mb-2">
              <slot name="helptext"></slot>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div slot="footer" class="flex justify-center">
      <div class="flex w-2/3 justify-around mt-2 mb-2 md:mb-4">
        <form-select-button
          allow-input
          silent
          immediate
          classes="md:w-20 md:h-12"
          title="OK"
          @selected="ok"
        ></form-select-button>
      </div>
    </div>
  </modal>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    FormSelectButton,
    Modal,
  },

  methods: {
    ok() {
      this.$emit('ok');
    },
  },
};
</script>
