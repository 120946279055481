<template>
  <div class="flex justify-center">
    <div v-if="requested" class="flex justify-center">
      <button
        v-if="clicked"
        class="w-4 h-4 font-semibold text-green-600 bg-green-300 border border-green-400 focus:outline-none lg:w-4 lg:h-4"
        :class="classes"
        @click="choose()"
      >
        <svg
          v-if="square"
          class="text-green-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path class="fill-current" d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
        </svg>
      </button>
      <button
        v-else
        class="w-4 h-4 font-semibold border focus:outline-none lg:w-4 lg:h-4 "
        :class="classes"
        @click="choose()"
      ></button>
    </div>
    <div v-else class="text-indigo-300">&#10008;</div>
  </div>
</template>

<script>
export default {
  props: {
    clicked: { type: Boolean, default: false },
    date: { type: Number, default: 0 },
    paid: { type: Boolean, default: false },
    requested: { type: Boolean, default: false },
    square: { type: Boolean, default: false },
  },

  data() {
    return {};
  },

  computed: {
    classes() {
      const classes = [];
      if (this.paid) classes.push('pointer-events-none');
      if (this.square) {
        classes.push('text-gray-500 bg-white border-gray-500');
      } else {
        classes.push('rounded-full text-red-500 bg-red-200 border-red-300');
      }
      return classes.join(' ');
    },
  },

  methods: {
    choose() {
      if (this.square) {
        this.$emit('ticked', !this.clicked);
      } else {
        if (!this.paid) {
          this.$emit('chosen', this.date, !this.clicked);
        }
      }
    },
  },
};
</script>
