<template>
  <div
    class="flex flex-col justify-center mx-4 mt-2 mb-6 border border-indigo-100 shadow-2xl"
  >
    <grid-header
      :approved="allApproved()"
      :bookings="filteredBookings"
      @sort="sort"
      @filter="filter"
      @toggle="toggleAcceptAll"
    ></grid-header>
    <grid-row
      v-for="(booking, idx) in filteredBookings"
      ref="gridrow"
      :key="booking.id"
      :booking="booking"
      :idx="idx"
      @acceptBooking="accept"
      @chosen="choose"
      @selected="selectedBooking"
    ></grid-row>

    <!-- Show the dates again -->
    <grid-footer
      :approved="allApproved()"
      :bookings="filteredBookings"
      class="mt-4"
    ></grid-footer>

    <!-- Totals -->
    <div v-if="false" class="grid grid-cols-12 px-1 pb-4 space-y-4 bg-red-500">
      <div class="col-span-5 bg-green-200" />
      <div class="flex items-center justify-end col-span-2 pr-4 space-x-4">
        <span class="text-xs font-semibold text-indigo-400 uppercase"
          >Requested:</span
        >
        <span
          class="w-10 font-semibold text-center text-indigo-800 bg-indigo-100 rounded-full shadow"
          >{{ totalRequested }}</span
        >
      </div>
      <!-- requested -->
      <div class="col-span-1">
        <div class="grid items-center grid-cols-5 text-sm">
          <div
            v-for="(tot, idx) in totals.slice(0, 5)"
            :key="idx"
            class="col-span-1 text-center text-indigo-700"
          >
            {{ tot }}
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="grid items-center grid-cols-5 text-sm">
          <div
            v-for="(tot, idx) in totals.slice(5, 10)"
            :key="idx"
            class="col-span-1 text-center text-indigo-700"
          >
            {{ tot }}
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="grid items-center grid-cols-5 text-sm">
          <div
            v-for="(tot, idx) in totals.slice(10, 15)"
            :key="idx"
            class="col-span-1 text-center text-indigo-700"
          >
            {{ tot }}
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="grid items-center grid-cols-5 text-sm">
          <div
            v-for="(tot, idx) in totals.slice(15, 20)"
            :key="idx"
            class="col-span-1 text-center text-indigo-700"
          >
            {{ tot }}
          </div>
        </div>
      </div>
      <!-- padding -->
      <div class="col-span-3" />
      <template v-if="showAccepted">
        <div class="flex items-center justify-end col-span-2 pr-4 space-x-4">
          <span class="text-xs font-semibold text-indigo-400 uppercase"
            >Accepted:</span
          >
          <span
            class="w-10 font-semibold text-center text-indigo-800 bg-indigo-100 rounded-full shadow"
            >{{ totalAccepted }}</span
          >
          <span
            v-if="totalAccepted > 0 && totalRequested > 0"
            class="w-16 font-semibold text-center text-indigo-800 bg-indigo-100 rounded-full shadow"
            >{{ Math.round((totalAccepted / totalRequested) * 100) }}%</span
          >
        </div>
        <!-- accepted -->
        <div class="col-span-1">
          <div class="grid items-center grid-cols-5 text-sm">
            <div
              v-for="(tot, idx) in accepted.slice(0, 5)"
              :key="idx"
              class="col-span-1 text-center text-indigo-700"
            >
              {{ tot }}
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="grid items-center grid-cols-5 text-sm">
            <div
              v-for="(tot, idx) in accepted.slice(5, 10)"
              :key="idx"
              class="col-span-1 text-center text-indigo-700"
            >
              {{ tot }}
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="grid items-center grid-cols-5 text-sm">
            <div
              v-for="(tot, idx) in accepted.slice(10, 15)"
              :key="idx"
              class="col-span-1 text-center text-indigo-700"
            >
              {{ tot }}
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="grid items-center grid-cols-5 text-sm">
            <div
              v-for="(tot, idx) in accepted.slice(15, 20)"
              :key="idx"
              class="col-span-1 text-center text-indigo-700"
            >
              {{ tot }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main.js';
import GridFooter from '@/components/admin-booking-grid-footer.vue';
import GridHeader from '@/components/admin-booking-grid-head.vue';
import GridRow from '@/components/admin-booking-grid-row.vue';
import middleware from '@/middleware/booking';
import { store } from '@/store.js';

const sum = arr => {
  return arr.reduce((acc, el) => {
    acc += el;
    return acc;
  }, 0);
};

export default {
  components: {
    GridFooter,
    GridHeader,
    GridRow,
  },

  props: {
    bookings: { type: Array, default: () => [] },
    searchKey: { type: String, default: '' },
    season: { type: Array, default: () => [] },
    // totals: { type: Array, default: () => [] },
  },

  data: function() {
    const columns = [
      'participant.name',
      'participant.surname',
      'participant.age',
      'transport',
      'equipment.options_1',
      'behaviour.options_1',
      'permissions.permission_7',
    ];
    let sortOrders = {};
    columns.forEach(function(key) {
      sortOrders[key] = 1;
    });

    return {
      accepted: [],
      filterKey: {},
      showEmail: false,
      sortKey: 'participant.surname',
      sortOrders,
      columns,
      totals: [],
      totalAccepted: 0,
      totalRequested: 0,
    };
  },

  computed: {
    filteredBookings: function() {
      // Always start with the entire set of bookings.
      let bookings = [...this.bookings];

      const searchKey = this.searchKey && this.searchKey.toLowerCase();
      const searchCols = [
        'participant.name',
        'participant.surname',
        'address.postcode',
      ];
      if (searchKey) {
        bookings = bookings.filter(function(row) {
          return searchCols.some(function(key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(searchKey) > -1
            );
          });
        });
      }

      // A date filter is treated differently so work on the other filters first
      const filters = Object.entries(this.filterKey).filter(
        ([k]) => k !== 'dates'
      );

      // Being practical - ignore filtering when a search is being made.
      // Could revisit but it seems a recipe for confusion right now.

      if (!searchKey && filters.length) {
        filters.forEach(filter => {
          if (filter[1]) {
            bookings = bookings.filter(row => row[filter[0]] === filter[1]);
          }
        });
      }

      // Now go back and filter the remaining bookings by booking date
      if (this.filterKey.dates) {
        bookings = bookings.filter(
          row => row['booking.dates'].indexOf(this.filterKey.dates) >= 0
        );
      }

      //* This is not ideal because of the side-effect from a computed prop.
      //* But the requested totals have to be updated based on the filtered
      //* bookings currently on screen.
      //* The accepted totals must *not* be updated though - these are maintained
      //* every time the user chooses a date to accept or un-accept. Trying to
      //* figure that out here was breaking everything.
      // eslint-disable-next-line
      this.totals = middleware.calculate_totals(bookings);
      // eslint-disable-next-line
      this.totalRequested = sum(this.totals);

      // Delayed sorting until now to work on the smallest data set possible.
      const normalise = val =>
        typeof val === 'string' ? val.toLowerCase() : val;
      const sortKey = this.sortKey;
      const order = this.sortOrders[sortKey] || 1;
      if (sortKey) {
        bookings = bookings.slice().sort(function(a, b) {
          a = normalise(a[sortKey]);
          b = normalise(b[sortKey]);
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }

      return bookings;
    },

    showAccepted() {
      return (
        this.totalAccepted > 0 &&
        this.totalRequested > 0 &&
        this.totalAccepted <= this.totalRequested
      );
    },
  },

  mounted() {
    // Totals must reflect the already accepted dates. The accepted array is
    // only populated (with 0 or 1) for the dates that were requested so it is
    // not a one-to-one map to the requested array which contains a boolean
    // for every possible day.
    // The logic is, work through the requested array. If element = true then
    // remove the first value from the accepted array and use that to determine
    // if that day was accepted or not.
    this.calculateTotals(this.bookings);
  },

  methods: {
    /**
     * One or more of the rows has been approved so pass an array of unique ids
     * straight back up to the top-level controller component via the global bus
     */
    accept() {
      eventBus.$emit(
        'approved',
        this.$refs['gridrow']
          .filter(row => row.approved)
          .filter(row => !row.payment.amount_due)
          .map(row => row.booking.booking_id)
      );
    },

    allApproved() {
      return this.bookings.map(b => b.payment.amount_due).every(b => b);
    },

    calculateTotals(bookings) {
      this.totals = middleware.calculate_totals(bookings);

      this.accepted = Array(this.season.length).fill(0);

      bookings.forEach(booking => {
        // Don't corrupt the original array!
        const accepted = [...booking.accepted];

        booking.requested.forEach((requested, idx) => {
          if (requested) {
            this.accepted[idx] += accepted.shift();
          }
        });
      });

      // eslint-disable-next-line
      // console.log(this.accepted);
      // Display totals and calculate percentage based on the above.
      this.totalAccepted = sum(this.accepted);
      this.totalRequested = sum(this.totals);
    },

    /**
     * A 'radio' button has been clicked to either accept or reject a requested
     * day. All days start out as rejected.
     */
    choose(args) {
      const key = 'accepted';

      // booking = entire booking as JSON object
      // date_id = index of clicked date in the seasons array
      // accepted = boolean
      const [booking, date_id, accepted] = args;

      if (accepted) {
        booking[key].push(this.season[date_id]);
        this.$set(this.accepted, date_id, this.accepted[date_id] + 1);
      } else {
        booking[key] = booking[key].filter(d => d !== this.season[date_id]);
        this.$set(this.accepted, date_id, this.accepted[date_id] - 1);
      }

      this.totalAccepted = sum(this.accepted);

      this.$http.post(
        `admin/accept/booking/${booking.booking_id}/date/${
          this.season[date_id]
        }?status=${accepted ? 1 : 0}`
      );
    },

    /**
     * React to an event from the header.
     * Setting the object triggers the computed property to run, which updates the
     * array of bookings being shown.
     *
     * name String The name of a booking object property -or- 'dates'
     * val  String 'yes', 'no' or 'off' for a prop -or- a date yyyy-mm-dd
     */
    filter(name, val) {
      if (val === 'off') {
        return this.$set(this.filterKey, name, undefined);
      }
      this.$set(this.filterKey, name, val);
    },

    selectedBooking(action, id) {
      // Find the booking and load it into the store, optionally setting
      // readonly mode (this overwrites the permission normally given to admin)
      const readonly = action === 'view';
      store.request.rebuild(
        this.bookings.filter(b => b.id === id),
        readonly
      );
      this.$router.push({ name: 'booking-form' });
    },

    sort(name) {
      this.sortBy(name);
    },

    sortBy: function(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },

    // Blanket reset to whatever state the header button is now in.
    // But, only when at least one date has been accepted.
    toggleAcceptAll(state) {
      this.$refs['gridrow']
        .filter(row => row.accepted.some(r => r))
        .forEach(r => r.setApproved(state, false));

      // settting false above blocks event emit so do it here now
      this.accept();
    },
  },
};
</script>

<style>
div {
  outline: 0px solid tomato;
}
th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #5a67d8;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #5a67d8;
}
</style>
