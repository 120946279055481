<template>
  <div :class="width">
    <form-input-label
      :mandatory="mandatory"
      :name="name"
      :info="info"
      :title="title"
      @info="showInfo"
    ></form-input-label>
    <div v-if="noinput">
      <form-text-noinput :value="toString()"></form-text-noinput>
    </div>
    <div v-else>
      <select
        :id="name"
        :name="name"
        :class="css"
        :disabled="noinput"
        @change="selected"
      >
        <option
          v-for="(option, idx) in options"
          :key="option.id"
          class="outline-none"
          :class="[idx === 0 ? 'text-gray-400' : 'text-gray-600']"
          :value="option.value"
          :selected="load(option.id)"
          >{{ option.text }}</option
        >
      </select>
    </div>
  </div>
</template>

<script>
import Booking from '@/models/booking.js';
import { store } from '@/store.js';

export default {
  props: {
    info: { type: String, default: '' },
    name: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    title: { type: String, default: '' },
    size: { type: String, default: '' },
    value: { type: String, default: '' },
  },

  data() {
    return {
      css: [],
      mandatory: false,
      update: false,
      requestLocked: store.request.locked(),
    };
  },

  computed: {
    noinput() {
      return this.requestLocked;
    },

    width() {
      if (this.size === 'sm') return 'sm:w-1/5 sm:mr-6';
      if (this.size === 'md') return 'sm:w-1/4 md:w-1/3 md:mr-8';
      if (this.size === 'lg') return 'sm:w-3/4 lg:w-4/5';
      return 'w-full m-0 p-0';
    },
  },

  watch: {
    //* See note on input method for reason for this watcher.
    update() {
      this.css = this.classes();
    },
  },

  created() {
    this.css = this.classes();
  },

  methods: {
    classes() {
      let base = [
        'bg-white',
        'w-full',
        'h-6',
        'sm:h-10',
        'pl-1',
        'sm:pl-2',
        'mb-2',
        'lg:mb-0',
        'text-xs',
        'sm:text-base',
        'md:text-lg',
        'outline:none',
        'rounded-none',
        'appearance-none',
        'border md:border-2',
        'border-gray-300',
      ];
      base.push(
        store.booking.find(this.name) ? 'text-blue-500' : 'text-gray-400'
      );
      this.mandatory = this.required() && !store.booking.find(this.name);
      if (this.requestLocked) base.push('cursor-not-allowed');
      return base;
    },

    load(id) {
      const db = store.booking.find(this.name);
      if (db) {
        const selected = this.options.filter(o => o.value === db)[0];

        return selected.id === id ? true : false;
      }
      return id === 1 ? true : false;
    },

    required() {
      return Booking.requiredFields.map(f => f.name).includes(this.name);
    },

    selected(ev) {
      // Toggle value just to force update the classes (basically to remove
      // required class if data is now present in this field).
      //* This is the only way I could find to force the classes to be refreshed
      //* after autofill in Chrome.
      this.update = !this.update;

      this.$emit('lea-selected', ev.target.selectedIndex);

      // This is trapped by sections containing mandatory fields
      this.$emit('user-entry', this.name, ev.target.selectedIndex);

      this.$root.$emit('formEntry', {
        name: this.name || '401-5',
        value: ev.target.value,
      });
    },

    showInfo(type) {
      this.$emit('info', type);
    },

    toString() {
      const db = store.booking.find(this.name);
      return this.options.filter(opt => opt.value === db)[0].text;
    },
  },
};
</script>
