<template>
  <page-accordion>
    <header slot="title">
      <div class="flex items-center">
        <!-- prettier-ignore -->
        <missing-icon v-if="!completed" class="h-4 w-4 text-red-500 mr-2"></missing-icon>
        <div>Which dates would you like to request?</div>
      </div>
    </header>
    <section slot="content">
      <dates-section
        v-if="dates"
        section="dates"
        :dates="dates"
        @entry="updateMandatory"
      ></dates-section>
    </section>
  </page-accordion>
</template>

<script>
import DatesSection from '@/components/sections/dates-section.vue';
import MissingIcon from '@/assets/icons/star.vue';
import { store } from '@/store.js';

export default {
  components: {
    DatesSection,
    MissingIcon,
  },

  data() {
    return {
      dates: undefined,
      mandatory: {
        dates: false,
      },
      season_id: 1,
    };
  },

  computed: {
    // Only exists to switch on/off the star icon in the banner.
    completed() {
      return Object.values(this.mandatory).every(b => b);
    },
  },

  mounted() {
    // Reminder: store.dates gets loaded in home-screen-controller
    this.dates = [...store.dates.get()];
  },

  methods: {
    // Each section is responsible for knowing when it's mandatory fields have
    // been entered and sends true/false after every user input.
    updateMandatory(...[name, complete]) {
      this.mandatory[name] = complete;
    },
  },
};
</script>
