<template>
  <div>
    <slot name="message"></slot>
    <yes-no-buttons name="biting_1" @yes="selected(0)" @no="selected(0)">
      <template #question>
        <span :class="buttonClass[0]" class="inline-block  w-11/12">
          Has your child had all their standard childhood vaccinations?</span
        >
      </template>
    </yes-no-buttons>

    <yes-no-buttons name="biting_2" @yes="selected(1)" @no="selected(1)">
      <template #question>
        <span :class="buttonClass[1]" class="inline-block  w-11/12">
          Is your child up to date with tetanus vaccinations?</span
        >
      </template>
    </yes-no-buttons>

    <yes-no-buttons name="biting_3" @yes="selected(2)" @no="selected(2)">
      <template #question>
        <span :class="buttonClass[2]" class="inline-block w-11/12">
          Has your child ever had, or been known to be at risk of, blood-borne
          viruses including HIV or hepatitis B/C?</span
        >
      </template>
    </yes-no-buttons>

    <yes-no-buttons name="biting_4" @yes="selected(3)" @no="selected(3)">
      <template #question>
        <span :class="buttonClass[3]" class="inline-block w-11/12">
          Has your child ever had a blood transfusion?</span
        >
      </template>
    </yes-no-buttons>

    <yes-no-buttons name="biting_5" @yes="selected(4)" @no="selected(4)">
      <template #question>
        <span :class="buttonClass[4]" class="inline-block w-11/12">
          Has your child travelled outside Europe in the past 6 months?</span
        >
      </template>
    </yes-no-buttons>
  </div>
</template>

<script>
import { store } from '@/store.js';
import YesNoButtons from '@/components/button-group-yes-no-row.vue';

export default {
  components: {
    YesNoButtons,
  },

  data() {
    return {
      // store.booking.find returns boolean if it has been input so only look
      // for undefined or boolean
      buttonState: Array.from(
        { length: 5 },
        (_, i) => !(store.booking.find(`biting_${++i}`) === undefined)
      ),
      buttonClass: {},
    };
  },

  created() {
    // Use the array of booleans to initialise a paired array of classes to
    // indicate mandatory. As soon as either accompanying button is clicked, the
    // classes will be cleared.
    this.buttonClass = this.buttonState.reduce((acc, btn, idx) => {
      acc[idx] = btn ? '' : ['mandatory', 'pl-2'];
      return acc;
    }, {});
  },

  methods: {
    selected(no) {
      this.buttonClass[no] = '';
    },
  },
};
</script>
