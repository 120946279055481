<template>
  <div id="top" class="relative flex flex-col">
    <div
      v-if="version.refresh"
      class="relative flex items-center justify-center w-full px-4 py-4 space-x-4 bg-red-500 md:px-0"
    >
      <div class="flex flex-col items-center">
        <span class="text-sm font-normal text-white md:text-base "
          >The booking app has been updated. Would you like to load the latest
          version now?</span
        >
        <span class="text-xs text-red-100"
          >If you click yes, you will be asked to login again. You will not lose
          anything you have already entered.</span
        >
      </div>
      <button
        class="px-3 py-1 text-sm font-semibold bg-white rounded-md hover:cursor-pointer"
        @click="reloadApp"
      >
        Yes
      </button>
      <button
        class="px-3 py-1 text-sm font-semibold text-gray-600 bg-gray-400 rounded-md hover:cursor-pointer"
        @click="noReload"
      >
        No
      </button>
    </div>
    <div
      class="relative flex items-center justify-between h-16 bg-gradient-to-r to-blue-400 from-indigo-500 sm:h-20 lg:h-24 xl:h-56"
    >
      <div
        class="relative flex items-center justify-between w-full h-16 sm:h-20 lg:h-24 xl:h-56"
      >
        <div class="flex items-center text-indigo-100 justify-left xl:mb-4">
          <router-link to="/home" class="mb-6 ml-2 sm:ml-4">
            <icon-logo></icon-logo>
          </router-link>
          <div class="mx-4 md:mx-8">
            <span
              class="inline-block pb-6 text-sm text-indigo-100 sm:font-thin sm:text-2xl md:text-2xl lg:text-5xl xl:text-6xl lg:font-thin"
              >{{ season }}</span
            >
          </div>
        </div>

        <booking-dropdown :user="user"></booking-dropdown>
      </div>
    </div>

    <!-- The app goes here -->
    <router-view />
  </div>
</template>

<script>
import BookingDropdown from '@/components/booking-dropdown.vue';
import IconLogo from '@/assets/icons/logo.vue';
import { store } from '@/store.js';

export default {
  filters: {
    greeting(val) {
      return val.split(' ')[0];
    },
  },

  components: {
    BookingDropdown,
    IconLogo,
  },

  data() {
    return {
      version: { refresh: false, ref: '' },
      season: process.env.VUE_APP_SEASON,
      user: '',
      token: store.request.token || null,
    };
  },

  watch: {
    token: {
      deep: true,
      immediate: true,
      handler: function() {
        this.user = store.request.token.get()
          ? store.request.token.username() || ''
          : '';
      },
    },
  },

  async mounted() {
    // Always check when mounted
    if (!store.version.get()) {
      const latest = await this.getLatestVersion();
      store.version.set(latest);
    }

    // Kick off the periodic check for updated app
    const MINS = 5;
    this.initVersionCheck(1000 * 60 * MINS);
  },

  methods: {
    initVersionCheck(frequency = 1000 * 60 * 2) {
      setInterval(() => {
        this.checkVersion();
      }, frequency);
    },

    async checkVersion() {
      const same = version => {
        return String(version) === String(store.version.get());
      };

      const latest = await this.getLatestVersion();

      // If no version in local storage -or- it is now different to what the
      // server says, show a dialogue to ask the user to choose between ignoring
      // or accepting the new version
      if (!same(latest)) {
        this.version.refresh = true;
        this.version.ref = latest;
      }
    },

    async getLatestVersion() {
      // Get latest version from the server (force bypass browser cache)
      const latest = (
        await this.$http.get(`/client/version?t=${new Date().getTime()}`)
      ).data.latest;
      return latest;
    },

    noReload() {
      // save the version even when no reload, otherwise it will ask the same
      // question on every cycle
      store.version.set(this.version.ref);
      this.version.refresh = false;
    },

    reloadApp() {
      /**
       * Not the perfect solution because it forces user to login again but
       * the only way I could find (quickly) that forces index.html to be
       * re-loaded from the server. This is essential because it contains the
       * names of the updated js and css bundles.
       */
      store.version.set(this.version.ref);
      this.version.refresh = false;
      location.assign(
        `https://easter2024.thumbsupclub.co.uk?t=${new Date().getTime()}`
      );
    },
  },
};
</script>

<style scoped>
.banner::after {
  /* background-image: url(assets/img/sunrise-cropped.jpg); */
  /* background-image: url('https://images.unsplash.com/photo-1565721213909-be13c4bee91f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OHw0MjU0MjAxNnx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'); */
  background-color: indigo;
  background-size: cover;
  background-position: 0% 10%;
  content: '';
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
</style>
