<template>
  <modal>
    <div slot="body" class="mb-2 md:mb-4">
      <div class="flex flex-col">
        <div class="text-sm text-gray-600 md:text-base">
          <div class="flex flex-col">
            <div
              class="flex items-center justify-between w-full px-2 py-2 text-xs font-bold text-center text-white bg-indigo-500 rounded-none shadow-lg md:px-4 md:py-2 md:text-xl md:rounded-t"
            >
              <slot name="header"></slot>
              <div class="w-4 h-4 cursor-pointer md:h-6 md:w-6" @click="close">
                <close-icon type="outline" @click="close"></close-icon>
              </div>
            </div>
            <div class="mx-4 mt-2 text-center md:mt-4">
              <p>
                When you click the button to pay, you will be taken to our
                external payment gateway.
              </p>
              <p class="mt-1">
                You will be asked for credit card details and a receipt will be
                sent to the email address we have on file for you.
              </p>
              <p class="mt-2">
                The cost of this booking will be &#163;{{
                  request.payment.amount_due
                }}
              </p>
            </div>
            <div class="flex justify-center">
              <!-- prettier-ignore -->
              <div v-if="stripe.connecting" class="mx-4 my-2 font-semibold text-indigo-400">Connecting...</div>
            </div>
            <div class="flex justify-center">
              <!-- prettier-ignore -->
              <div v-if="stripe.redirecting" class="mx-4 my-2 font-semibold text-indigo-400">
            Redirecting to payment gateway...
          </div>
            </div>
            <div class="flex justify-center">
              <div
                v-if="stripe.error"
                class="mx-4 my-2 font-semibold text-red-400"
              >
                Cannot connect to payment gateway. Please try again later.
              </div>
            </div>
            <div
              style="min-height:min-content"
              class="flex justify-around pt-2 mt-2 mb-2 md:mt-4 md:mb-4 md:pt-4"
            >
              <form-select-button
                allow-input
                silent
                classes="px-8 py-4"
                :class="payButton"
                title="Pay now"
                @selected="pay"
              ></form-select-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseIcon from '@/assets/icons/close.vue';
import FormSelectButton from '@/components/form-select-button.vue';
import Modal from '@/components/Modal.vue';
import stripeMixin from './mixins/stripe';

export default {
  components: {
    CloseIcon,
    FormSelectButton,
    Modal,
  },

  mixins: [stripeMixin],

  props: {
    request: { type: Object, default: () => {} },
  },

  data() {
    return {
      stripe: {
        connecting: false,
        progress: false,
        redirecting: false,
        error: false,
      },
    };
  },

  computed: {
    payButton() {
      if (this.stripe.progress) {
        return 'bg-gray-400 text-gray-500 pointer-events-none';
      } else {
        return 'hover:text-white hover:bg-indigo-400';
      }
    },
  },

  methods: {
    close() {
      this.$emit('ok');
    },

    async session() {
      let session;
      try {
        this.stripe.progress = true;
        this.session.connecting = true;
        session = await this.$http.post(`request/pay/${this.request.id}`);
      } catch (error) {
        session = undefined;
        this.stripe.error = true;
      }
      this.session.connecting = false;
      return session ? session.data.id : undefined;
    },

    async pay() {
      const sessionId = await this.session();
      if (!sessionId) {
        this.stripe.progress = false;
        return;
      }

      this.stripe.redirecting = true;

      try {
        // eslint-disable-next-line
        const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

        stripe
          .redirectToCheckout({
            sessionId,
          })
          .then(() => {
            this.stripe.redirecting = false;
            this.stripe.progress = false;
          })
          .catch(e => {
            // eslint-disable-next-line
            console.log(e);
          });
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message);
        this.stripe.redirecting = false;
        this.stripe.error = true;
      }
    },
  },
};
</script>
