<template>
  <div class="bg-gray-100 text-indigo-600 h-screen w-full">
    <div
      class="flex flex-col justify-top items-center h-screen w-1/2 mx-auto mt-32"
    >
      <h2 class="my-2 text-2xl font-thin ">Thank you for your payment</h2>
      <p class="text-base font-thin">
        Your payment has been processed. Check your email for the payment
        confirmation and receipt.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
