<template>
  <div
    class="h-10 px-2 mb-6 flex items-center rounded text-lg bg-gray-100 text-gray-600 font-medium border border-gray-400"
  >
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, default: '' },
  },
};
</script>
