<template>
  <modal>
    <div slot="body" class="mb-2 md:mb-4">
      <div class="flex flex-col">
        <div
          class="h-auto max-h-screen overflow-y-scroll text-sm text-gray-600 md:text-base"
        >
          <div class="z-50 flex flex-col overflow-y-scroll">
            <div
              class="w-full px-0 py-1 pl-2 text-sm font-bold text-white bg-indigo-500 rounded-none shadow-lg md:px-4 md:py-2 md:text-xl md:rounded-t"
            >
              <slot name="header"></slot>
            </div>
            <div class="block sm:hidden">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="mx-4 mt-2 md:mt-4" v-html="paragraphs[pno]"></div>
              <div
                style="min-height:min-content"
                class="flex justify-around py-2 mt-2 mb-2 bg-white md:mt-4 md:mb-4 md:pt-4"
              >
                <form-select-button
                  :allow-input="true"
                  silent
                  immediate
                  classes=""
                  title="Next"
                  @selected="next"
                ></form-select-button>
              </div>
            </div>
            <div class="hidden sm:block">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="mx-4 mt-2 md:mt-4" v-html="information" />
              <div
                style="min-height:min-content"
                class="flex justify-around py-2 mt-2 mb-2 bg-white md:mt-4 md:mb-4 md:pt-4"
              >
                <form-select-button
                  :allow-input="true"
                  silent
                  immediate
                  classes=""
                  title="OK"
                  @selected="ok"
                ></form-select-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    FormSelectButton,
    Modal,
  },

  data() {
    return {
      message: [
        `<div class="leading-none text-center text-indigo-500 font-base sm:leading-snug md:leading-normal">`,
        `<div class="flex flex-col items-center text-indigo-500">`,
        `<div class="mb-4 text-sm font-semibold text-indigo-600 sm:text-xl">
        TUC Easter Holiday Club 2024 will be running on the following dates: 
        <div class="mt-2 text-xs sm:text-lg">
        Wednesday 3rd April through to Thursday 11th April (weekdays only)
        </div>
        </div>`,
        `<div class="mb-4 font-normal text-indigo-600 underline">
        Unfortunately, due to being oversubscribed and only having access to the Sixth form building at Addington School to run club from this year, we are unable to welcome new attendees this Summer. 
        <div class="mt-2">
        <span class="font-bold">We are also unable to offer transport.</span>
        </div>
        </div>`,
        `<div class="mt-2">
        Bookings must be submitted before midnight on 11th February and are <span class="italic font-bold">not</span> processed on a first come first served basis as the club needs to manage each days staffing numbers with individual child’s needs.
        </div>`,
        `<div class="mt-2 text-xs font-bold uppercase md:mt-4 md:mb-2 md:tracking-wide md:leading-relaxed md:text-sm">
        Submitting your request can be done only once. Changes to your request are not possible after it has been submitted. If you are booking for more than one child please ensure that each of their details are provided on the same form before submitting it.
        </div>`,
        `<div class="mt-2">
        When registering for club dates, we are asking that you pick up to <strong>3</strong> dates that your child is available to attend but depending on the number of applications we get, we are anticipating that we will be able to offer up to 2 of these days per child.
        </div>`,
        `<div class="text-indigo-500">`,
        `<div class="mt-2">
        Confirmation of the days you have been allocated will be sent by email on Tuesday 20th February.  You will then have until midnight on 27th February to pay using a link you will be sent, otherwise we cannot honour the booking.
        </div>`,
        `<div class="mt-2 ">
        The club costs £30 per day per child (9:30am - 4:00pm).
        </div>`,
        `<div class="mt-2 italic">
         (Please note that whilst Club Management will always endeavour to honour all bookings made, they reserve the right to cancel any bookings made at any time if it is felt that the Club does not have enough staff to safely run the sessions with the number of children that are booked on.  We are always looking for additional staff so please feel free to support us with staff recruitment!)</span></div>`,
        `</div>`,
        `</div>`,
      ],

      pno: 0,
    };
  },

  computed: {
    information() {
      return this.message.join('');
    },

    paragraphs() {
      return [
        this.message.slice(0, 5).join(''),
        this.message.slice(6).join(''),
      ];
    },
  },

  methods: {
    ok() {
      this.$emit('ok');
    },

    // Only used at xs breakpoint - show next block of info text or go to booking
    next() {
      if (this.pno === this.paragraphs.length - 1) {
        return this.ok();
      }
      this.pno += 1;
    },
  },
};
</script>
