<template>
  <div :class="width">
    <form-input-label
      :mandatory="mandatory"
      :name="name"
      :info="info"
      :title="title"
    ></form-input-label>
    <div v-if="noinput">
      <form-text-noinput :value="toString(name)"></form-text-noinput>
    </div>
    <div v-else>
      <input
        type="text"
        :name="name"
        placeholder="dd/mm/yyyy"
        :class="css"
        @input="input"
        @keyup.enter="enter"
        @blur="enter"
      />
    </div>
  </div>
</template>

<script>
import Booking from '@/models/booking.js';
import moment from 'moment';
import { store } from '@/store.js';

export default {
  props: {
    info: { type: String, default: '' },
    options: { type: Object, default: () => {} },
    title: { type: String, default: '' },
    size: { type: String, default: '' },
  },

  data() {
    return {
      css: [],
      dateValue: '',
      json: store.booking.json || {},
      mandatory: false,
      requestLocked: store.request.locked(),
      preventDefault: false,
      update: false,
    };
  },

  computed: {
    disabled() {
      return this.options && this.options.disabled ? this.options.disabled : {};
    },

    dateProps() {
      return { class: this.css.join(' '), placeholder: 'dd/mm/yyyy' };
    },

    noinput() {
      return this.requestLocked;
    },

    width() {
      if (this.size === 'sm') return 'w-full sm:w-1/3 md:w-1/4 text-base';
      if (this.size === 'md')
        return 'w-full sm:w-1/2 md:w-1/3 md:mr-4 text-base';
      if (this.size === 'lg') return 'w-full md:mr-4';
      return 'w-full';
    },
  },

  watch: {
    update() {
      this.mandatory =
        this.required() && !store.booking.find(this.name) && !this.noinput;
    },
  },

  mounted() {
    this.css = this.classes();
    this.dateValue = new Date(this.json[this.name]);

    // When switching kids, updating the v-model will fire the input event
    // which then calls the selected() method. That then triggers an event for
    // the root element to update the state - stop this from happening because
    // it's not needed and may cause side effects.
    this.preventDefault = this.json[this.name] ? true : false;

    // Force the mandatory flag to it's correct start state
    this.update = !this.update;
  },

  methods: {
    classes() {
      let base = [
        'w-full',
        'h-6',
        'sm:h-10',
        'pl-1',
        'sm:pl-2',
        'mb-2',
        'lg:mb-0',
        'text-blue-500',
        'rounded-md',
        'border md:border-2',
        'border-gray-300',
        'text-xs',
        'sm:text-base',
        'md:text-lg',
        'placeholder-gray-400',
      ];
      return base;
    },

    enter(ev) {
      this.$emit('input', ev.target.value);
    },

    input(ev) {
      this.$emit('input', ev.target.value);
    },

    load(id) {
      const dt = store.booking.find(id);
      return dt ? moment(dt, 'YYYY-MM-DD').format('D/M/YYYY') : '';
    },

    toString(name) {
      const date = moment(store.booking.find(name));
      return date.format('D MMM YYYY');
    },

    required() {
      return Booking.requiredFields.map(f => f.name).includes(this.name);
    },

    selected(ev) {
      // See note in mounted() method
      if (this.preventDefault) {
        return (this.preventDefault = false);
      }

      // Force the mandatory class to be re-evaluated
      //* see form-text-input for explanation.
      this.update = !this.update;

      // Tell the parent something was entered (this event may or may not be
      // captured by the parent)
      this.$emit('user-entry', this.name, ev);

      // All input fields must tell the root component as well so it can update
      // the store object.
      this.$root.$emit('formEntry', {
        name: this.name || '401-3',
        value: ev ? moment(ev, 'D/M/YYYY').format('YYYY-MM-DD') : '-',
      });
    },
  },
};
</script>
