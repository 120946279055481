<template>
  <page-accordion>
    <header slot="title">
      <div class="flex items-center">
        <!-- prettier-ignore -->
        <missing-icon v-if="!completed" class="h-4 w-4 text-red-500 mr-2"></missing-icon>
        <div>Contact Information</div>
      </div>
    </header>
    <section slot="content">
      <carers-section
        section="carers"
        @entry="updateMandatory"
        @error="updateError"
      ></carers-section>
    </section>
  </page-accordion>
</template>

<script>
import CarersSection from '@/components/sections/carers-section.vue';
import MissingIcon from '@/assets/icons/star.vue';

export default {
  components: {
    CarersSection,
    MissingIcon,
  },

  data() {
    return {
      mandatory: {
        carers_one: false,
        carers_ec: false,
      },
      error: false,
    };
  },

  computed: {
    // Only exists to switch on/off the star icon in the banner.
    completed() {
      return !this.error && Object.values(this.mandatory).every(b => b);
    },
  },

  methods: {
    updateError(err) {
      this.error = err;
    },

    // Each section is responsible for knowing when it's mandatory fields have
    // been entered and sends true/false after every user input.
    updateMandatory(...[name, complete]) {
      this.mandatory[name] = complete;
    },
  },
};
</script>
