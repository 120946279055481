<template>
  <div
    class="w-11/12 mt-2 overflow-hidden bg-white rounded-lg shadow-2xl sm:mt-8 sm:w-5/6 xl:w-1/2"
  >
    <img
      class="object-cover w-full h-32 xl:h-48"
      :src="season.imageURL"
      alt=""
    />
    <div class="p-6 md:pb-12">
      <h4
        class="flex items-center justify-between mb-2 md:text-base lg:text-lg xl:text-2xl"
      >
        <span class="text-3xl font-semibold text-indigo-600">{{
          season.title
        }}</span>
        <span class="text-2xl font-semibold text-indigo-600"
          >{{ fmtDate(season.start) }} - {{ fmtDate(season.end) }}</span
        >
      </h4>
      <div
        class="text-xs font-semibold tracking-wide text-gray-600 uppercase sm:text-sm md:text-base lg:text-lg xl:text-xl"
      >
        <div class="flex items-center justify-between">
          <div>Bookings open</div>
          <div>
            {{ fmtDate(season.open, true)
            }}<span class="ml-2 text-xs text-gray-500 sm:text-sm">08:00</span>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>Bookings close</div>
          <div>
            {{ fmtDate(season.close, true) }}
            <span class="ml-2 text-xs text-gray-500 sm:text-sm">23:59</span>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center mt-4 md:mt-6">
        <button
          v-if="option === 'admin'"
          class="px-5 py-3 mr-6 rounded shadow-xl"
          :class="bookingButton"
          @click="admin"
        >
          <div class="flex items-center">
            <div class="mr-2 text-base font-bold tracking-wide uppercase">
              {{ season.bookings }}
            </div>
            <div class="text-xs font-semibold tracking-wide uppercase">
              bookings
            </div>
          </div>
        </button>
        <button
          v-if="season.bookings > 0 && option === 'admin'"
          class="relative outline-none focus:outline-none"
          @click="refresh"
        >
          <refresh-icon></refresh-icon>
        </button>

        <!-- Non-admin, make a new booking, manage booking or view it after submission -->
        <div
          v-if="option === 'closed' || option === 'not_yet'"
          class="flex flex-col justify-center h-10 px-4 text-center rounded md:h-12 exg--btn-inactive"
        >
          <div>
            {{ titles[option] }}
          </div>
        </div>

        <!-- Create, amend or view a booking -->
        <div v-else class="flex flex-col justify-center">
          <div v-if="option === 'new'">
            <season-card-button @click="newBooking">
              {{ titles.new }}
            </season-card-button>
          </div>
          <div v-if="option === 'edit'">
            <season-card-button @click="editBooking">
              {{ titles.edit }}
            </season-card-button>
          </div>
          <div v-if="option === 'view'">
            <season-card-button @click="viewBooking">
              {{ titles.view }}
            </season-card-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SeasonCardButton from '@/components/season-card-button.vue';
import RefreshIcon from '@/assets/icons/refresh.vue';

export default {
  components: {
    RefreshIcon,
    SeasonCardButton,
  },

  props: {
    season: { type: Object, default: () => {} },
    option: { type: String, default: '' },
  },

  data() {
    return {
      titles: {
        closed: 'Booking closed',
        new: 'Book now',
        not_yet: 'Opening soon',
        view: 'View booking',
        edit: 'Manage booking',
      },
    };
  },

  computed: {
    bookingButton() {
      return this.season.bookings > 0 ? 'exg--btn-active' : 'exg--btn-inactive';
    },
  },

  methods: {
    admin() {
      this.$emit('admin');
    },

    fmtDate(date, yy = false) {
      return moment(date).format(yy ? 'D MMM YYYY' : 'D MMM');
    },

    editBooking() {
      this.newBooking();
    },

    newBooking() {
      this.$emit('newbooking');
    },

    refresh() {
      this.$emit('refresh', this.season);
    },

    viewBooking() {
      this.newBooking();
    },
  },
};
</script>
