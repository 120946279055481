<template>
  <div>
    <admin-booking-grid
      :bookings="bookings"
      :search-key="searchQuery"
      :season="season"
      :totals="totals"
    ></admin-booking-grid>
  </div>
</template>

<script>
import AdminBookingGrid from '@/components/admin-booking-grid.vue';

export default {
  components: { AdminBookingGrid },

  props: {
    bookings: { type: Array, default: () => [] },
    season: { type: Array, default: () => [] },
    searchQuery: { type: String, default: '' },
    totals: { type: Array, default: () => [] },
  },

  data() {
    return {
      filterKey: '',
    };
  },

  methods: {},
};
</script>
