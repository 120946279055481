<template>
  <div
    :id="idName"
    class="text-white cursor-pointer"
    title="Download csv"
    @click="generate"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { saveAs } from 'file-saver';

import PapaParse from 'papaparse';

export default {
  name: 'JsonCSV',
  props: {
    /**
     * Json to download
     */
    data: { type: Array, required: true },
    /**
     * fields inside the Json Object that you want to export
     * if no given, all the properties in the Json are exported
     * Can either be an array or a function
     */
    fields: { type: Array, default: () => [], required: false },
    /**
     *
     */
    formatter: { type: Function, default: () => [] },
    /**
     * filename to export, default: data.csv
     */
    name: { type: String, default: 'data.csv' },
    /**
     * Delimiter for the CSV file
     */
    delimiter: { type: String, default: ',', required: false },
    /**
     * Should the module add SEP={delimiter}
     *
     * Useful for opening file with Excel
     */
    separatorExcel: { type: Boolean, default: false },
    /**
     * What will be the encoding of the file
     */
    encoding: { type: String, default: 'utf-8' },
    /**
     * Advanced options for Papaparse that is used to export to CSV
     */
    advancedOptions: { type: Object, default: () => {} },
    /**
     * Labels for columns
     *
     * Object or function
     */
    labels: { type: Object, default: () => {}, required: false },
  },

  computed: {
    // unique identifier
    idName() {
      const now = new Date().getTime();
      return 'export_' + now;
    },
    exportableData() {
      const filteredData = this.cleaningData();
      if (!filteredData.length) {
        return null;
      }

      return filteredData;
    },
  },
  methods: {
    labelsFunctionGenerator() {
      if (
        !_.isUndefined(this.labels) &&
        !_.isFunction(this.labels) &&
        !_.isObject(this.labels)
      ) {
        throw new Error('Labels needs to be a function(value,key) or object.');
      }

      if (_.isFunction(this.labels)) {
        return item => {
          let mapKeys = _.mapKeys(item, this.labels);
          return mapKeys;
        };
      }

      if (_.isObject(this.labels)) {
        return item => {
          return _.mapKeys(item, (item, key) => {
            return this.labels[key] || key;
          });
        };
      }

      return item => item;
    },

    fieldsFunctionGenerator() {
      if (
        !_.isUndefined(this.fields) &&
        !_.isFunction(this.fields) &&
        !_.isObject(this.fields) &&
        !_.isArray(this.fields)
      ) {
        throw new Error('Fields needs to be a function(value,key) or array.');
      }

      if (
        _.isFunction(this.fields) ||
        (_.isObject(this.fields) && !_.isArray(this.fields))
      ) {
        return item => {
          return _.pickBy(item, this.fields);
        };
      }

      if (_.isArray(this.fields)) {
        return item => {
          return _.pick(item, this.fields);
        };
      }
      return item => item;
    },

    cleaningData() {
      if (_.isUndefined(this.fields) && _.isUndefined(this.labels)) {
        return this.data;
      }

      const labels = this.labelsFunctionGenerator();
      const fields = this.fieldsFunctionGenerator();

      return _.map(this.data, item => labels(fields(item)));
    },

    generate() {
      this.$emit('export-started');
      // const dataExport = this.exportableData;
      const dataExport = [...this.data];

      if (!dataExport) {
        // eslint-disable-next-line
        console.error('No data to export');
        return;
      }

      // Formatter knows how to create the csv object from the passed in dataset
      const formatted = dataExport.map(this.formatter);

      let csv = PapaParse.unparse(
        formatted,
        Object.assign(
          {
            delimiter: this.delimiter,
            encoding: this.encoding,
          },
          this.advancedOptions
        )
      );
      if (this.separatorExcel) {
        csv = 'SEP=' + this.delimiter + '\r\n' + csv;
      }
      //Add BOM when UTF-8
      if (this.encoding === 'utf-8') {
        csv = '\ufeff' + csv;
      }
      this.$emit('export-finished');
      if (!this.testing) {
        let blob = new Blob([csv], {
          type: 'application/csv;charset=' + this.encoding,
        });
        saveAs(blob, this.name);
      }
    },
  },
};
</script>

<style scoped>
div {
  display: inline;
}
</style>
