<template>
  <div id="grid">
    <admin-booking-list
      :bookings="bookings"
      :season="season"
      :search-query="searchQuery"
      :totals="totals"
    ></admin-booking-list>
  </div>
</template>

<script>
import AdminBookingList from '@/pages/admin-booking-list.vue';
// -- other pages to do with admin bookings management go here

export default {
  components: {
    AdminBookingList,
  },
  props: {
    bookings: { type: Array, default: () => [] },
    season: { type: Array, default: () => [] },
    searchQuery: { type: String, default: '' },
    totals: { type: Array, default: () => [] },
  },
};
</script>
