<template>
  <div
    class="absolute top-0 z-50 w-screen h-full mx-auto mb-6 overflow-y-auto bg-white"
  >
    <div
      slot="header"
      class="w-full py-2 text-base text-center text-white bg-indigo-500 shadow-xl md:px-4 md:py-4 md:text-xl"
    >
      <slot name="header"></slot>
    </div>

    <div
      slot="body"
      class="w-full px-2 mx-auto sm:px-0 sm:w-3/4 md:mb-4 xl:w-1/3"
    >
      <div class="flex flex-col">
        <label class="text-indigo-600">
          <div class="md:mx-4">
            <div class="md:mb-2">
              <slot name="text"></slot>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div slot="footer" class="flex justify-center">
      <div class="flex justify-around w-1/4 mb-2 xl:w-1/6 md:mb-4">
        <form-select-button
          ref="yes"
          silent
          allow-input
          name="yes"
          class="w-12 h-8 mr-4 md:mr-2 sm:w-16 sm:h-10"
          title="Yes"
          @selected="selected('yes')"
        ></form-select-button>
        <form-select-button
          ref="no"
          silent
          allow-input
          name="no"
          class="w-12 h-8 mr-4 md:mr-2 sm:w-16 sm:h-10"
          title="No"
          @selected="selected('no')"
        ></form-select-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
// import Modal from '@/components/Modal.vue';

export default {
  components: {
    FormSelectButton,
    // Modal,
  },

  data() {
    return {};
  },

  methods: {
    selected(action) {
      this.$emit('selected', action);
    },
  },
};
</script>
