<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <template #title>
            Communication
          </template>
          <template #info>
            <div class="hidden lg:block flex flex-col">
              <p>Choose as many as apply</p>
            </div>
          </template>
        </form-section-header>
      </template>

      <template #form-body>
        <div class="flex flex-col w-full px-2 lg:px-0">
          <span class="text-gray-600 text-base md:text-lg mt-2 mb-2"
            >Does your child:</span
          >
          <div class="flex flex-col">
            <!-- Speech -->
            <div class="flex flex-col mb-2">
              <div class="w-full md:w-1/2">
                <form-select-button
                  active
                  classes=""
                  :immediate="options[0].selected"
                  :title="options[0].label"
                  :name="options[0].id"
                  pill
                  @selected="selectCommunicationStyle(0)"
                ></form-select-button>
              </div>
              <form-text-input
                v-if="options[0].selected"
                :area="true"
                class="mb-0"
                name="speech.other"
                title="Any other information?"
                placeholder=""
              ></form-text-input>
            </div>

            <!-- Signing -->
            <div class="flex flex-col md:mt-4 mb-2">
              <div class="w-full md:w-1/2">
                <form-select-button
                  active
                  classes=""
                  :immediate="options[1].selected"
                  :title="options[1].label"
                  :name="options[1].id"
                  pill
                  @selected="selectCommunicationStyle(1)"
                ></form-select-button>
              </div>
              <div v-if="options[1].selected" class="flex flex-col mt-2">
                <span class="text-gray-600 text-base md:text-lg mb-2"
                  >Type of signing</span
                >
                <div class="flex mb-2">
                  <div v-for="(opt, idx) in systems" :key="opt.label">
                    <form-select-button
                      active
                      classes="mr-4"
                      :immediate="systems[idx].selected"
                      :title="opt.label"
                      :name="opt.id"
                      pill
                      @selected="selectSigningSystem(idx)"
                    ></form-select-button>
                  </div>
                </div>
              </div>
              <div class="mt-0">
                <form-text-input
                  v-if="options[1].selected"
                  area
                  name="communication.other_signing"
                  title="Further details"
                  placeholder=""
                ></form-text-input>
              </div>
            </div>
          </div>

          <!-- Other communication type -->
          <div class="flex flex-col md:mt-4 mb-4">
            <div class="w-full md:w-1/2">
              <form-select-button
                active
                classes=""
                :immediate="options[2].selected"
                :title="options[2].label"
                :name="options[2].id"
                pill
                @selected="selectCommunicationStyle(2)"
              ></form-select-button>
            </div>
            <form-text-input
              v-if="options[2].selected"
              :area="true"
              class="mt-2"
              name="communication.other"
              title="Details of other communication techniques"
              placeholder=""
            ></form-text-input>
          </div>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    FormSelectButton,
  },

  data() {
    return {
      options: [
        {
          id: 'communication.options_1',
          label: 'Communicate with speech',
          selected: false,
        },
        {
          id: 'communication.options_2',
          label: 'Use signing',
          selected: false,
        },
        {
          id: 'communication.options_3',
          label: 'Communicate in another way',
          selected: false,
        },
      ],
      systems: [
        { id: 'communication.systems_2', label: 'Makaton', selected: false },
        { id: 'communication.systems_1', label: 'BSL', selected: false },
        { id: 'communication.systems_3', label: 'Other', selected: false },
      ],
    };
  },

  created() {
    // Inital selected state comes from the data source
    this.options.forEach(o => (o.selected = store.booking.find(o.id) || false));
    this.systems.forEach(s => (s.selected = store.booking.find(s.id) || false));
  },

  methods: {
    buttonStyle(idx) {
      const buttonClass = [
        'font-extrabold rounded-full border  mb-4 mr-4 px-4 py-1',
      ];
      const selectedClass = ['bg-green-200 text-green-400 border-green-100'];
      const defaultClass = ['bg-blue-200 text-blue-400 border-blue-100'];
      return this.options[idx].selected
        ? [...selectedClass, ...buttonClass]
        : [...defaultClass, ...buttonClass];
    },

    selectCommunicationStyle(idx) {
      this.options[idx].selected = !this.options[idx].selected;
    },

    selectSigningSystem(idx) {
      this.systems[idx].selected = !this.systems[idx].selected;
    },
  },
};
</script>
