<template>
  <div class="w-3 h-3 text-indigo-400 cursor-pointer md:w-4 md:h-4">
    <svg
      v-if="type === 'edit'"
      class="cursor-pointer fill-current hover:text-indigo-700"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      @click="click"
    >
      <path
        d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"
      />
    </svg>

    <svg
      v-if="type === 'view'"
      class="cursor-pointer fill-current hover:text-indigo-700"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      @click="click"
    >
      <path
        d="M 0.2 10 a 11 11 0 0 1 19.6 0 A 11 11 0 0 1 0.2 10 Z m 9.8 4 a 4 4 0 1 0 0 -8 a 4 4 0 0 0 0 8 Z m 0 -2 a 2 2 0 1 1 0 -4 a 2 2 0 0 1 0 4 Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: Number, default: 0 },
    type: { type: String, default: 'view' },
  },

  methods: {
    click() {
      this.$emit('selected', this.type, this.id);
    },
  },
};
</script>
