<script>
import FormTextInput from '@/components/form-text-input';
import { helpers } from 'vuelidate/lib/validators';
import Postcode from 'postcode';

export default {
  extends: FormTextInput,

  data() {
    return {
      postcode: '',
    };
  },

  validations: {
    postcode: {
      valid: pc => !helpers.req(pc) || Postcode.isValid(pc),
    },
  },

  methods: {
    enter(ev) {
      // Force css to be re-evaluated
      this.update = !this.update;

      this.postcode = ev.target.value;
      this.$v.postcode.$touch();

      if (this.$v.postcode.$anyError) {
        this.errmsg = 'Invalid postcode';
      } else {
        // will be null if not entered
        this.postcode = Postcode.toNormalised(this.postcode);

        // Clear the message from the parent text input component
        this.errmsg = '';

        // This is trapped by sections containing mandatory fields
        this.$emit('user-entry', this.name, this.postcode || '');

        this.$root.$emit('formEntry', {
          name: this.name || '401-5',
          value: this.postcode || '-',
        });
      }
    },
  },
};
</script>
