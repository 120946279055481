<template>
  <transition name="modal">
    <div class="fixed top-0 left-0 z-40 w-screen h-screen modal-mask">
      <div class="flex flex-col justify-start h-full sm:justify-center">
        <div :class="classes">
          <div class="overflow-hidden rounded-none modal-header sm:rounded-t">
            <slot name="header"></slot>
          </div>
          <div class="flex flex-col">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'base',
    },
  },

  data() {
    return {};
  },
  computed: {
    classes() {
      const base =
        'modal-container mx-auto rounded-none md:rounded shadow-md bg-white text-base z-50';
      const responsive = 'w-full md:w-5/6 lg:w-2/3 xl:w-6/12';
      return base + ' ' + responsive;
    },
  },
};
</script>

<style scoped>
.modal-cover {
  /* background-color: rgba(0, 0, 0, 1); */
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.2s ease;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s ease;
}

.modal-container {
  transition: all 0.2s ease;
}

/*
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
