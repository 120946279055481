<template>
  <div :class="buttonStyle()" @click="select">
    <span :name="name" class="inline-block">{{ title }}</span>
    <slot></slot>
  </div>
</template>

<script>
import { store } from '@/store.js';

export default {
  props: {
    active: { type: Boolean, default: false },
    allowInput: { type: Boolean, default: false },
    classes: { type: String, default: '' },
    silent: { type: Boolean, default: false },
    name: { type: String, default: '' },
    pill: { type: Boolean, default: false },
    immediate: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },

  data() {
    return {
      requestLocked: store.request.locked(),
      selected: this.immediate || false,
    };
  },

  computed: {
    noinput() {
      return this.allowInput ? false : this.requestLocked;
    },
  },

  methods: {
    buttonStyle() {
      // Base classes plus decide to show as a fully rounded (tag-style) button?
      const buttonClass = `flex flex-col justify-center items-center px-4 py-1 ${
        this.pill ? 'rounded-full' : 'rounded'
      }`;
      const selectedClass =
        ' font-light md:font-normal text-white bg-indigo-700 text-sm sm:text-base md:text-lg lg:text-xl shadow-2xl hover:cursor-pointer hover:bg-indigo-600';
      const defaultClass =
        ' font-light md:font-normal text-indigo-500 bg-indigo-200 text-sm sm:text-base md:text-lg lg:text-xl hover:cursor-pointer hover:bg-indigo-600 hover:text-white hover:shadow-xl';

      // Use a combination of the core classes and classes for each of the
      // states i.e. selected or not selected.
      let baseClass = buttonClass;
      if (this.noinput) {
        baseClass += ' cursor-not-allowed';
        baseClass += this.selected
          ? ' font-semibold bg-gray-600 text-gray-100'
          : ' bg-white text-gray-400 border border-gray-400';
      } else {
        baseClass += this.selected ? selectedClass : defaultClass;
      }
      return this.classes ? [this.mergeClasses(baseClass)] : [baseClass];
    },

    // Any base classes defined in this component may get overridden by classes
    // passed in as a prop. If there are duplicates then the passed in class
    // gets priority so merging should remove all clashes e.g. bg-white/bg-black
    mergeClasses(base) {
      const prefix = util => util.split('-')[0];
      const override = this.classes.split(' ').map(c => prefix(c));
      let filtered = base
        .split(' ')
        .filter(c => !override.includes(prefix(c)))
        .join(' ');
      return `${filtered} ${this.classes}`;
    },

    // Implemented for use by dates-section-date to control appearance but
    // could be used in other situations.
    deselect() {
      this.selected = false;
    },

    select() {
      if (this.noinput) return;

      // Some parents want to control the appearance of buttons as they transition
      // from allowing clicks to not allowing them - only switch state if the
      // parent component is not doing it for us.
      if (this.active) this.selected = !this.selected;

      this.$emit('selected');

      // Tell the root component about what just got selected/deselected
      if (!this.silent) {
        this.$root.$emit('formEntry', {
          name: this.name || `401-4:${this.title}`,
          value: this.selected ? 'yes' : 'no',
        });
      }
    },
  },
};
</script>

<style scoped>
.form-button:hover,
.form-button:hover .info {
  background-color: #c6f6d5; /* green-100 */
  border: 1px solid #c6f6d5; /* green-100 */
  color: #68d391; /* green-400 */
}
</style>
