<template>
  <div
    class="relative flex flex-row items-center justify-start w-full ml-4 text-xs text-center lg:justify-center sm:ml-0 sm:text-base"
  >
    <div class="w-1/3 mr-2 lg:w-auto lg:mr-0">
      <div class="h-8 sm:h-16 sm:w-48">
        <a href="#" :class="submitClass" class="" @click="handle('submit')">
          <span
            class="px-2 py-1 my-1 text-sm text-green-700 transition duration-200 bg-green-300 border rounded-lg md:text-base sm:px-6 sm:py-2 hover:bg-green-600 hover:text-green-100 hover:shadow-lg lg:text-lg"
            >Send request</span
          >
        </a>
      </div>
    </div>
    <div
      class="relative flex items-center justify-around w-2/3 lg:w-auto lg:absolute lg:right-0"
    >
      <div class="h-8 sm:h-16 sm:mr-2 lg:mr-12">
        <a href="#" :class="addClass" @click="handle('addChild')">
          <span class="text-sm exg--navbar-tab-text md:text-base"
            >Add child</span
          >
        </a>
      </div>
      <div class="h-8 sm:h-16 sm:mr-2 lg:mr-12">
        <a
          href="#"
          class="flex flex-col items-center justify-center w-full h-full exg--navbar-tab"
          @click="handle('clearForm')"
        >
          <span class="text-sm exg--navbar-tab-text lg:text-base"
            >Clear form</span
          >
        </a>
      </div>
      <div class="flex items-center justify-start">
        <navbar-close @closeView="handle('closeView')"></navbar-close>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarClose from '@/components/navbar-close.vue';

export default {
  components: {
    NavbarClose,
  },

  props: {
    canAddChild: { type: Boolean, default: false },
    canSubmit: { type: Object, default: () => {} },
  },

  data() {
    return {
      base: [
        'flex',
        'flex-col',
        'justify-center',
        'items-center',
        'h-full',
        'w-full',
      ],
    };
  },

  computed: {
    addClass() {
      return this.canAddChild
        ? [...this.base, 'exg--navbar-tab']
        : [
            ...this.base,
            'cursor-default',
            'pointer-events-none',
            'text-indigo-300',
          ];
    },
    submitClass() {
      return [...this.base, 'exg--navbar-tab'];
      // return this.canSubmit
      //   ? [...this.base, 'exg--navbar-tab']
      //   : [
      //       ...this.base,
      //       'cursor-default',
      //       'pointer-events-none',
      //       'text-indigo-300',
      //     ];
    },
  },
  methods: {
    handle(action) {
      this.$emit(action);
    },
  },
};
</script>
