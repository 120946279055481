<template>
  <form-section>
    <template #form-header>
      <form-section-header>
        <template #title>
          <div class="mt-2">
            Medication Consent
          </div>
        </template>
      </form-section-header>
    </template>

    <template #form-body>
      <div class="px-2 lg:px-0">
        <yes-no-buttons :name="name" @yes="clicked" @no="clicked">
          <template #question>
            <span class="inline-block mr-4" :class="{ mandatory: !selected }">
              I consent to my child being given their prescribed medication
              whilst at the club
            </span>
          </template>
        </yes-no-buttons>
      </div>
    </template>
  </form-section>
</template>

<script>
import YesNoButtons from '@/components/button-group-yes-no-row.vue';
import { store } from '@/store.js';

export default {
  components: {
    YesNoButtons,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      name: 'medication.consent',
      selected: store.booking.find('medication.consent') !== undefined,
    };
  },

  created() {
    // Section is complete as long as either button has been clicked.
    this.$emit('entry', this.section, this.selected);
  },

  methods: {
    clicked() {
      this.selected = true;
      this.$emit('entry', this.section, true);
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  margin-left: 1px;
  color: #fc8181;
  content: '*';
}
</style>
