<template>
  <div
    class="pb-4 w-full md:w-3/4 xl:w-3/5 px-2 pt-4 overflow flex flex-col mx-auto"
  >
    <div class="flex justify-between items-center">
      <h2
        class="text-sm lg:text-base self-center uppercase font-semibold text-indigo-700"
      >
        Questions and answers
      </h2>
      <div class="flex items-center">
        <navbar-close @closeView="dismiss"></navbar-close>
      </div>
    </div>
    <div class="flex flex-col items-left">
      <dl>
        <faq-item>
          <template slot="question">How do I get started?</template>
          <template slot="answer">
            <p>
              You will need to have created an account before you can book for
              the first time.<br />
              To create an account, click the link on the log in page and then
              enter an email address to receive a confirmation.<br />
              When you receive the email, click on the link it contains and you
              will be brought to a screen where you should enter your own name
              and choose a password.
            </p>
            <p class="font-bold italic my-1">
              Passwords must be 8-16 characters long and must contain at least
              one number, one lowercase character and one uppercase character.
            </p>
            <p>
              Once you have an account you can use it for all future Thumbs Up
              Club bookings.<br />Please note that making a booking does not
              guarantee a place. We are oversubscribed for every club we run and
              must allocate spaces to accomodate as many children as we can, as
              fairly as possible.
            </p>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            How do I make a booking?
          </template>
          <template slot="answer">
            <span>
              <p>
                The booking form asks for a lot of information about you and
                your child/children. Some of this information is required in all
                cases and some is optional.
              </p>
              <p>
                You will not be able to submit your booking until all the
                required fields have been completed. If you try to submit the
                booking before completing all the required fields, you will be
                shown a message detailing which fields are still missing and
                where to find them in the form.
              </p>
              <p>
                Although the optional fields are not essential for you to make a
                booking, it is important to review all the sections of the form,
                and fill in as much relevant information as possible.
              </p>
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            Can I book for more than one child?
          </template>
          <template slot="answer">
            <span>
              <p>
                Yes. As soon as you have started to enter the first child, you
                will see the option to add a child to the booking. All of the
                details of all children must be fully completed before
                submitting the booking.<br />
                The dates requested for each child do not have to be the same.
              </p>
              <p>
                It is important to note that you can only ever submit one
                booking - for one or more children - so please be certain that
                all children have been correctly entered before submitting the
                booking.
              </p>
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            My child has been known to bite. Will this affect them getting a
            place at club?
          </template>
          <template slot="answer">
            <span>
              No. it means that we can be as prepared as we can for that
              possibility. We now provide staff with added protective wear for
              their safety. Please ensure that if your child is known to bite,
              you fill in the added details as fully as possible.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            What are the reasons I might not get transport?
          </template>
          <template slot="answer">
            <span>
              We use an external company for our club transport. The costs
              involved are very high even with the &#163;10 per day contribution
              from families. We have had to be tight on our radius for transport
              for a) costs and b) avoiding keeping children on the bus for an
              extended period of time. If your child behaves in an unsafe manner
              either towards themselves, other children or the driver/escort,
              transport services may also be withdrawn.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            What happens if I am allocated a space but not offered transport?
          </template>
          <template slot="answer">
            <span>
              In this instance, it is out of TUC control and we will be happy to
              refund you for the places you have paid for.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            Since booking, my child has been allocated a hospital appointment,
            what should I do?
          </template>
          <template slot="answer">
            <span>
              A medical appointment would be the only instance in which we will
              provide a refund on production of a letter. We cannot guarantee we
              can offer another space in lieu of this as we get hugely
              oversubscribed each club run.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            I can see you have a half day on the last day. Am I able to book
            other days as half days?
          </template>
          <template slot="answer">
            <span>
              No, we only offer full day sessions. The last day of holiday club
              is a half day to allow the packing of resources and cleaning to be
              done before we give the keys back to school.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            Why isn't there a save button on the booking form?
          </template>
          <template slot="answer">
            <span>
              Everything you type into the booking form is saved automatically.
              You can come back to the form as many times as you like and change
              or add more information. You can even close your browser window
              and nothing will be lost.<br />Some of the boxes must be completed
              before the booking can be submitted - these are indicated by a red
              * next to the box to be filled in. Once all of the required
              information has been completed, you will be given the chance to
              'submit' the booking.<br />
              <span class="my-1 font-bold italic">
                It is important to note that submitting the booking will lock it
                - you will NOT be able to make any changes after this point </span
              ><br />
              After submitting your booking, you will receive an email
              confirmation and can view the booking at any time but, as noted
              above, no further changes will be possible.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">
            How much time do I have to make a booking?
          </template>
          <template slot="answer">
            <span>
              The booking site will usually be open for around 2 weeks, and
              around 2 months before club is due to start. All bookings made
              during that 2 week period will be considered equally i.e. there is
              no advantage to be gained in booking early. However, once the site
              has been closed, no further bookings will be taken, under any
              circumstances.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question">What happens after I book?</template>
          <template slot="answer">
            <span>
              All bookings made up to the closing date for booking are stored
              securely on our system. After booking has closed, a senior member
              of the team will evaluate the bookings for each day and allocate
              spaces based on specific needs and staffing levels (amongst other
              criteria).
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question"
            >How much does it cost and how do I pay?</template
          >
          <template slot="answer">
            <span>
              The current cost is £30 per day, per child for a full day and £15
              per child when club is running for a half-day. If you need
              transport for your child to come to club, this costs an additional
              £10 per day, per child.<br />
              Once your places have been allocated, you will receive an email to
              notify you that payment is now due. <br />
              <span class="my-1 italic font-bold"
                >You will then have seven days in which to pay.</span
              ><br />
              To pay, log back in to view your booking and you will see a Pay
              Now button. Clicking this will allow you to choose whether to pay
              by credit card or Childcare Vouchers. If you choose vouchers, you
              must arrange to make the voucher payment within 7 days.
              <br />
              If paying by card, you will be transferred to an external payment
              provider. All payment processing is handled by them - we do not
              have any record of your card details on our system. Once you have
              completed the payment you will receive an email confirmation.
              Should you wish to view the payment receipt online, you can log in
              again to the TUC booking system and will see a link to it, stored
              with your booking.
            </span>
          </template>
        </faq-item>

        <faq-item>
          <template slot="question"
            >I have some questions, who can I ask before I book?</template
          >
          <template slot="answer">
            <span>
              Not a problem. Please email
              <a href="mailto:enquiries@thumbsupclub.co.uk"
                ><span class="font-semibold underline"
                  >enquiries@thumbsupclub.co.uk</span
                ></a
              >
              and one of our volunteers will get back to you as soon as they
              can.
            </span>
          </template>
        </faq-item>
      </dl>
    </div>
  </div>
</template>

<script>
import NavbarClose from '@/components/navbar-close.vue';
import FaqItem from '@/components/faq-item.vue';

export default {
  components: {
    FaqItem,
    NavbarClose,
  },

  methods: {
    dismiss() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
