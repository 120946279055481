<template>
  <div class="relative">
    <div class="absolute top-0 right-0">
      <span class="text-red-400">*</span>
      <span class="normal-case font-thin text-xxs sm:text-sm text-gray-500">
        indicates required
      </span>
    </div>
    <form-name-block
      id="carers.carer_one"
      :attrs="attrs.all"
      info="Primary contact"
      :same="same"
      @name-block="carerOneEntered"
    ></form-name-block>
    <form-name-block
      id="carers.carer_two"
      :attrs="attrs.all"
      info="Secondary contact"
      hide
    ></form-name-block>
    <form-name-block
      id="carers.emergency_contact"
      :attrs="attrs.all"
      :same="same"
      info="Additional Emergency contact"
      @name-block="ecEntered"
    ></form-name-block>
    <form-name-block
      id="carers.social_worker"
      :attrs="attrs.socialWorker"
      info="Social Worker"
      class="mb-6"
      hide
    ></form-name-block>
  </div>
</template>

<script>
import FormNameBlock from '@/components/form-name-block.vue';
import { store } from '@/store';

export default {
  components: {
    FormNameBlock,
  },

  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      attrs: {
        all: ['name', 'surname', 'homePhone', 'workPhone', 'mobilePhone'],
        socialWorker: ['name', 'surname', 'workPhone', 'mobilePhone'],
      },
      same: 0,
    };
  },

  methods: {
    carerOneEntered(done) {
      this.$nextTick(() => {
        if (done) {
          // all carer one data is present so pass that flag up to the parent but
          // then validate against the other carer and possibly reset the parent
          this.$emit('entry', `${this.section}_one`, true);

          // set flag to display error
          this.same = this.samePerson();

          this.$emit('error', this.same === 1 ? true : false);
        } else {
          this.$emit('entry', `${this.section}_one`, false);
        }
      });
    },

    ecEntered(done) {
      this.$nextTick(() => {
        if (done) {
          // all carer one data is present so pass that flag up to the parent but
          // then validate against the other carer and possibly reset the parent
          this.$emit('entry', `${this.section}_ec`, true);

          // set flag to display error
          this.same = this.samePerson();

          this.$emit('error', this.same === 1 ? true : false);
        } else {
          this.$emit('entry', `${this.section}_ec`, false);
        }
      });
    },

    /**
     * Return true if both names of both carers have been entered AND
     * (name1=name2 AND surname1=surname2)
     */
    samePerson() {
      const carers = [
        store.booking.find('carers.carer_one_name'),
        store.booking.find('carers.emergency_contact_name'),
        store.booking.find('carers.carer_one_surname'),
        store.booking.find('carers.emergency_contact_surname'),
        store.booking.find('carers.carer_one_mobilePhone'),
        store.booking.find('carers.emergency_contact_mobilePhone'),
      ];
      return (carers[0] === carers[1] && carers[2] === carers[3]) ||
        carers[4] === carers[5]
        ? 1
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
