<template>
  <page-accordion>
    <header slot="title">Feeding and equipment</header>
    <section slot="content">
      <feeding-assistance-section></feeding-assistance-section>
      <equipment-section></equipment-section>
    </section>
  </page-accordion>
</template>

<script>
import FeedingAssistanceSection from '@/components/sections/feeding-assistance-section.vue';
import EquipmentSection from '@/components/sections/equipment-section.vue';

export default {
  components: {
    EquipmentSection,
    FeedingAssistanceSection,
  },
};
</script>
