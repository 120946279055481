<template>
  <form-section>
    <template #form-header>
      <div
        class="hidden lg:block text-indigo-400 mt-4 text-xs sm:text-sm md:text-base"
      >
        <span>Education</span>
      </div>
    </template>

    <template #form-body>
      <div
        id="school"
        class="flex flex-col lg:flex-row lg:w-full px-2 lg:px-0 lg:mt-3"
      >
        <form-text-input
          area
          name="school.name"
          size="lg"
          placeholder="School name and address"
          title="School"
          @user-entry="capture"
        ></form-text-input>
      </div>
    </template>
  </form-section>
</template>

<script>
import { store } from '@/store.js';

export default {
  props: {
    section: { type: String, default: '' },
  },

  data() {
    return {
      entered: {
        'school.name': false,
      },
    };
  },

  created() {
    Object.keys(this.entered).forEach(k => {
      this.entered[k] = store.booking.find(k) ? true : false;
    });
    this.check_complete();
  },

  methods: {
    capture(...[name, val]) {
      this.entered[name] = val ? true : false;
      this.check_complete();
    },

    check_complete() {
      const done = Object.values(this.entered).every(b => b);
      this.$emit('entry', this.section, done);
    },
  },
};
</script>
