<template>
  <div id="start"
       class="z-0 w-full h-full mx-auto">
    <div v-if="!infoModal">
      <div v-if="posting"
           class="items-center w-full h-16 bg-orange-600 shadow-lg shadow-b">
        <div class="relative flex flex-row items-center justify-start h-full ml-4 text-xs text-center lg:justify-center sm:ml-0 sm:text-base">
          <span class="block text-xs font-semibold text-white uppercase sm:text-sm md:text-base lg:text-lg">Processing - please wait...</span>
        </div>
      </div>
      <div v-else>
        <nav-bar :can-add-child="canAddChild"
                 :can-submit="formComplete"
                 :season="season"
                 :locked="locked"
                 @addChild="addChild"
                 @clearForm="clearModal = true"
                 @logout="logout"
                 @pay="payNow = true"
                 @show-receipt="showReceipt"
                 @submit="submit"
                 @voucher="payByVoucher = true"></nav-bar>
      </div>

      <div id="pages"
           class="w-11/12 h-full pt-1 mx-auto md:pt-0 sm:w-11/12 lg:w-3/4">
        <identification-page v-if="show('id')"
                             :key="keys.id"
                             :children="children"
                             @remove="removeChild"
                             @switch="switchChild"></identification-page>

        <carers-page v-if="show('carers')"
                     :key="keys.carers"></carers-page>

        <medical-page v-if="show('medical')"
                      :key="keys.medical"></medical-page>

        <feeding-page v-if="show('feeding')"
                      :key="keys.feeding"></feeding-page>

        <behaviour-page v-if="show('behaviour')"
                        :key="keys.behaviour"></behaviour-page>

        <toileting-page v-if="show('toileting')"
                        :key="keys.toileting"></toileting-page>

        <general-page v-if="show('general')"
                      :key="keys.general"></general-page>

        <declaration-page v-if="show('declaration')"
                          :key="keys.declaration"></declaration-page>

        <dates-page v-if="show('dates')"
                    :key="keys.dates"></dates-page>

        <div class="flex-row justify-end px-2 my-10 lg:flex lg:px-0">
          <a class="btn-pill"
             href="#top">Return to top</a>
        </div>
      </div>
    </div>

    <modal-info v-if="infoModal"
                @ok="infoModal = false">
      <div slot="header">
        <div class="flex items-center w-2/3 text-white">
          <div class="">
            <svg class="h-4 fill-current sm:h-5 md:h-6 lg:h-8"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z" />
            </svg>
          </div>
          <span class="ml-4 text-xs font-normal sm:text-base md:text-lg">Important information - please read carefully</span>
        </div>
      </div>
    </modal-info>

    <div @click="submitModal = false">
      <modal-yes-no v-if="submitModal"
                    @selected="submitResponse">
        <div slot="header">
          <div class="flex items-center self-center justify-center py-4 text-center text-red-600 bg-red-100 rounded-md sm:mx-0">
            <svg class="h-6 fill-current"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z" />
            </svg>
            <span class="ml-1 text-sm font-normal sm:ml-4 md:text-lg">Warning: this action cannot be undone</span>
          </div>
        </div>
        <div slot="text">
          <div class="flex flex-col items-center text-base">
            <div class="flex flex-col items-center font-light text-indigo-800">
              <p class="mt-6 font-normal">
                Changes to your request will not be possible after it has been
                submitted.
              </p>
              <p class="mt-2">
                You can only submit your booking request once. Before
                submitting, please ensure it includes all the children you wish
                to book for and that you have provided all the relevant
                information for each child.
              </p>
              <p class="mt-4">
                As a reminder, costs are &#163;30 per full day.
              </p>
              <p class="mt-2">
                You will receive an email confirming which dates you have been
                allocated on 20th February 2024.
              </p>
              <p class="mt-2">
                You will then have <strong>7 days</strong> to pay in full, by
                credit card or Childcare Vouchers.
              </p>
              <p class="mt-4 italic font-semibold">
                Our cancellation policy states that no refunds will be given.
              </p>
            </div>
            <p class="mt-4 mb-4 md:mb-0">Do you want to submit the request?</p>
          </div>
        </div>
      </modal-yes-no>
    </div>

    <div @click="clearModal = false">
      <modal-yes-no v-if="clearModal"
                    @selected="clearResponse">
        <div slot="header"
             class="flex justify-center">
          <span class="uppercase">Are you sure?</span>
        </div>
        <div slot="text">
          <div class="flex flex-col items-center justify-center">
            <p class="mt-1 text-center md:mt-2">
              You are about to clear the information you have entered and will
              then need to start again.
            </p>
            <p class="mt-1 mb-4 md:mt-4 md:mb-2">
              Are you sure you want to do this?
            </p>
          </div>
        </div>
      </modal-yes-no>
    </div>

    <div @click="incompleteModal = false">
      <modal-help v-if="incompleteModal"
                  @selected="incompleteModal = false">
        <div slot="header">
          <span>Booking not yet completed</span>
        </div>
        <div slot="helptext">
          <p>
            The following sections have missing information:
          </p>
          <ul class="pt-4">
            <li v-for="section in Object.keys(formComplete)"
                :key="section"
                class="ml-4">
              <span>{{ section | fmtSection }}</span>
              <span>&nbsp;({{ formComplete[section].length }} missing)</span>
            </li>
          </ul>
          <br />
          <p>
            Required fields are shown with
            <span class="text-red-400">&nbsp;*</span> next to them.
          </p>
        </div>
      </modal-help>
    </div>

    <div v-if="payNow">
      <modal-pay-dialogue :request="bookingRequest"
                          @ok="payNow = false">
        <div slot="header">
          <div class="text-center">Pay for {{ season }} booking</div>
        </div>
      </modal-pay-dialogue>
    </div>

    <div v-if="payByVoucher">
      <modal-voucher-dialogue :request="bookingRequest"
                              @ok="paidByVoucher()">
        <div slot="header">
          <div>Use voucher to pay for {{ season }} booking</div>
        </div>
      </modal-voucher-dialogue>
    </div>
  </div>
</template>

<script>
// Pages
import BehaviourPage from '@/pages/behaviour-page.vue';
import CarersPage from '@/pages/carers-page.vue';
import DeclarationPage from '@/pages/declaration-page.vue';
import DatesPage from '@/pages/dates-page.vue';
import FeedingPage from '@/pages/feeding-page.vue';
import GeneralPage from '@/pages/general-page.vue';
import IdentificationPage from '@/pages/identification-page.vue';
import MedicalPage from '@/pages/medical-page.vue';
import ToiletingPage from '@/pages/toileting-page.vue';

// Components
import ModalHelp from '@/components/modal-help.vue';
import ModalInfo from '@/components/modal-info.vue';
import ModalPayDialogue from '@/components/modal-pay-dialogue.vue';
import ModalVoucherDialogue from '@/components/modal-voucher-dialogue.vue';
import ModalYesNo from '@/components/modal-yes-no.vue';
import NavBar from '@/components/navbar.vue';

import { store } from '@/store.js';

export default {
  filters: {
    fmtSection(section) {
      return section.substr(0, 1).toUpperCase() + section.substr(1);
    },
  },

  components: {
    BehaviourPage,
    CarersPage,
    DatesPage,
    DeclarationPage,
    FeedingPage,
    GeneralPage,
    IdentificationPage,
    MedicalPage,
    ModalHelp,
    ModalInfo,
    ModalPayDialogue,
    ModalVoucherDialogue,
    ModalYesNo,
    NavBar,
    ToiletingPage,
  },

  // A request can contain 1-many bookings. It is created and maintained in the
  // controller though - so this component can be as ignorant as possible of the
  // data source.
  props: {
    canAddChild: { type: Boolean, default: false },
    bookingRequest: {
      type: Object,
      default: () => ({
        bookings: [],
      }),
    },
    formComplete: { type: Object, default: () => { } },
    locked: { type: Boolean, default: false },
    posting: { type: Boolean, default: false },
  },

  data() {
    return {
      admin: true,
      children: [],
      clearModal: false,
      incompleteModal: false,
      infoModal: false,
      keys: {},
      season: 'Easter 2024', //TODO .env
      pages: {
        behaviour: true,
        carers: true,
        dates: true,
        declaration: true,
        feeding: true,
        general: true,
        id: true,
        medical: true,
        toileting: true,
      },
      payNow: false,
      payByVoucher: false,
      requestSubmitted: false,
      submitModal: false,
    };
  },

  computed: {
    classes() {
      return 'text-sm md:text-base px-2 h-8 md:h-10 rounded-lg shadow-xl text-green-100 bg-green-700';
    },

    disabled() {
      return 'text-sm md:text-base px-2 h-8 md:h-10 rounded-lg shadow-none text-gray-200 bg-gray-400';
    },
  },

  watch: {
    bookingRequest: {
      deep: true,
      immediate: true,
      handler(request) {
        this.children = request.bookings;
      },
    },
  },

  created() {
    //* Note - store.booking is always the current on-screen booking.
    //* The booking is also stored in an array which contains 1-n booking
    //* objects. This is for the case where >1 child is being booked.
    //* Submitting will send all bookings to the server.

    // Open the helpful information modal when no data has yet been entered
    this.infoModal = this.showInfoModal();

    // Attach a key to every form field so a re-render can be forced
    this.keys = this.setKeys();

    // //TODO look again at form expiry and token expiry together
    // localStorage.setItem('expireAt', new Date(new Date().getTime() + 3000));
    // setTimeout(() => {
    //   this.$router.push('/login');
    // }, 60 * 60 * 1000);

    // Listen for user input anywhere in the app
    this.$root.$on('formEntry', args => this.$emit('updateBooking', args));
  },

  methods: {
    addChild() {
      this._clearCurrentBooking();
    },

    // Do enough to clear the current child details but not the entire request
    //eslint-disable-next-line
    _clearCurrentBooking(force = false) {
      this.$emit('clear', force, this.forceRender);
    },

    // Really, really start all over again.
    clearResponse(ans) {
      if (ans === 'yes') this._clearCurrentBooking(true);
    },

    logout() {
      this.$router.push('/login');
    },

    /**
     * Force refresh of everything to get the payment status. There is possibly
     * a way to achieve this without forcing user to login again but this seems
     * most secure.
     */
    paidByVoucher() {
      this.payByVoucher = false;
      this.logout();
    },

    removeChild(id) {
      this.$emit('remove', id, this.forceRender);
    },

    setKeys() {
      let seed = 1;
      return Object.keys(this.pages).reduce((acc, k) => {
        acc[k] = seed++;
        return acc;
      }, {});
    },

    show(page) {
      return this.pages[page];
    },

    showInfoModal() {
      // show if new booking only
      if (this.requestSubmitted) return false;

      const request = store.request.get();
      return !request ||
        !Object.prototype.hasOwnProperty.call(request, 'bookings') ||
        request.bookings.length === 0
        ? true
        : false;
    },

    showReceipt() {
      window.open(this.bookingRequest.payment.receipt_url, '_blank');
    },

    switchChild(id) {
      this.$emit('switch', id, this.forceRender);
    },

    submit() {
      if (Object.keys(this.formComplete).length === 0) {
        this.submitModal = true;
      } else {
        this.incompleteModal = true;
      }
    },

    submitResponse(ans) {
      if (ans === 'yes') {
        this.$emit('submit', this.forceRender);
      }
    },

    /**
     * Changing the bound key of each page will force them to each be re-rendered
     * which is what we want after clearing the entered data.
     * https://michaelnthiessen.com/force-re-render/
     */
    forceRender() {
      Object.keys(this.keys).forEach(k => (this.keys[k] += 1));
    },
  },
};
</script>

<style scoped>
* {
  outline: 0px solid tomato;
}

.primary {
  fill: #9ae6b4;
}

.secondary {
  fill: #38a169;
}
</style>
