<template>
  <div class="flex justify-between">
    <label
      :for="name"
      class="inline-flex items-end p-0 m-0 text-xs text-gray-600 md:items-center sm:text-base md:text-lg md:inline-flex md:mb-1"
      :class="{ mandatory: mandatory }"
      >{{ title }}</label
    >
    <button
      v-if="info"
      class="mr-2 outline-none focus:outline-none focus:bg-white focus:border-gray-400"
    >
      <span
        class="inline-flex text-indigo-500 hover:text-indigo-700"
        @click="showInfo"
      >
        <svg
          class="h-4 fill-current sm:h-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z"
          />
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    info: { type: String, default: '' },
    mandatory: { type: Boolean, default: false },
    name: { type: String, default: '' },
    title: { type: String, default: '' },
  },
  methods: {
    showInfo() {
      this.$emit('info', this.info);
    },
  },
};
</script>

<style scoped>
.mandatory:after {
  display: inline;
  margin-left: 3px;
  color: #fc8181;
  content: '*';
}
</style>
