<template>
  <div
    class="relative w-full flex flex-row justify-start lg:justify-center ml-4 lg:ml-0 items-center text-xs sm:text-base text-center"
  >
    <div class="w-auto mr-2 lg:mr-0">
      <div class="h-8 sm:h-16 sm:w-64">
        <a
          href="#"
          class="flex flex-col justify-center items-center h-full w-full exg--navbar-tab"
          @click="handle('updateBooking')"
        >
          <span
            class="exg--navbar-tab-text text-xs sm:text-sm md:text-base lg:text-lg"
            >Save changes</span
          >
        </a>
      </div>
    </div>
    <div class="flex items-center justify-start absolute right-0">
      <navbar-close @closeView="handle('closeView')"></navbar-close>
    </div>
  </div>
</template>

<script>
import NavbarClose from '@/components/navbar-close.vue';

export default {
  components: {
    NavbarClose,
  },

  methods: {
    handle(action) {
      this.$emit(action);
    },
  },
};
</script>
