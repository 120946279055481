<template>
  <div :class="buttonStyle" @click="select">
    <span :id="id" :name="name" class="px-4 md:px-2 block overflow-hidden">{{
      title
    }}</span>
    <slot></slot>
  </div>
</template>

<script>
import { store } from '@/store.js';

export default {
  props: {
    active: { type: Boolean, default: false },
    classes: { type: String, default: '' },
    id: { type: Number, default: 0 },
    silent: { type: Boolean, default: false },
    name: { type: String, default: '' },
    pill: { type: Boolean, default: false },
    immediate: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },
  data() {
    return {
      selected: this.immediate || false,
    };
  },

  computed: {
    buttonStyle() {
      // Base classes plus decide to show as a fully rounded (tag-style) button?
      const buttonClass = `flex flex-col px-1 overflow-scroll rounded-full text-center justify-center items-center `;
      const selectedClass = 'bg-indigo-400 text-white shadow-lg';
      const defaultClass =
        'bg-indigo-200 text-indigo-100 hover:text-indigo-100 hover:bg-indigo-400';

      // Use a combination of the core classes and classes for each of the
      // states i.e. selected or not selected.
      const baseClass = this.selected
        ? `${buttonClass} ${selectedClass}`
        : `${buttonClass} ${defaultClass}`;

      return this.classes ? [this.mergeClasses(baseClass)] : [baseClass];
    },
  },

  mounted() {
    this.selected = this.id === store.booking.data.id;
  },

  methods: {
    // Any base classes defined in this component may get overridden by classes
    // passed in as a prop. If there are duplicates then the passed in class
    // gets priority so merging should remove all clashes e.g. bg-white/bg-black
    mergeClasses(base) {
      const prefix = util => util.split('-')[0];
      const override = this.classes.split(' ').map(c => prefix(c));
      let filtered = base
        .split(' ')
        .filter(c => !override.includes(prefix(c)))
        .join(' ');
      return `${filtered} ${this.classes}`;
    },

    // Implemented for use by dates-section-date to control appearance but
    // could be used in other situations.
    deselect() {
      this.selected = false;
    },

    select() {
      // Some parents want to control the appearance of buttons as they transition
      // from allowing clicks to not allowing them - only switch state if the
      // parent component is not doing it for us.
      if (this.active) this.selected = !this.selected;

      this.$emit('selected');
    },
  },
};
</script>
