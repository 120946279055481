<template>
  <div class="flex flex-col justify-end">
    <div class="relative z-50">
      <button
        class="relative z-40 block w-8 h-8 mb-0 mr-4 overflow-hidden border-2 border-indigo-200 rounded-full shadow-2xl sm:w-12 sm:h-12 sm:mr-6 xl:mr-12 md:mr-8 lg:w-16 lg:h-16 xl:w-24 xl:h-24 focus:outline-none focus:border-indigo-600"
        @click="isOpen = !isOpen"
      >
        <img
          class="object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1583337130417-3346a1be7dee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80"
          alt="Your avatar"
        />
      </button>
      <button
        v-if="isOpen"
        tabindex="-1"
        class="fixed inset-0 w-full h-full bg-black opacity-50 cursor-default"
        @click="isOpen = false"
      ></button>
      <div
        v-if="isOpen"
        class="absolute right-0 w-48 mt-1 overflow-hidden bg-white rounded-lg shadow-2xl"
      >
        <div
          v-if="user"
          class="px-4 py-2 font-semibold text-indigo-400 bg-gray-100 border-b border-indigo-200 "
        >
          {{ user }}
        </div>
        <router-link to="/faq">
          <p
            class="px-4 py-1 text-indigo-400 hover:bg-indigo-500 hover:text-indigo-100"
          >
            FAQ
          </p>
        </router-link>
        <a
          href="mailto:support@thumbsupclub.co.uk"
          class="block px-4 py-1 text-indigo-400 hover:bg-indigo-500 hover:text-indigo-100"
          >Support</a
        >
        <a
          v-if="user"
          href=""
          class="block px-4 py-1 text-indigo-400 hover:bg-indigo-500 hover:text-indigo-100"
          >Sign out</a
        >
        <div
          class="flex justify-center w-full mt-2 bg-gray-100 border-t border-gray-300"
          @click="reloadWindow"
        >
          <span class="inline-block text-xs text-gray-600">
            <a class="hover:text-gray-700" href="#"
              >Exegi Software {{ new Date().getFullYear() }}</a
            >
            (v{{ version }})</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isOpen: false,
      version: require('../../package.json').version,
    };
  },

  methods: {
    reloadWindow() {
      window.location.reload();
    },
  },
};
</script>
