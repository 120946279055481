<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <template #title>
            Equipment
          </template>
          <template #info>
            Please choose any equipment used by your child
          </template>
        </form-section-header>
      </template>

      <template #form-body>
        <div
          class="flex flex-col flex-wrap md:flex-row px-2 mb-6 lg:px-0 md:mt-8"
        >
          <div class="flex flex-wrap">
            <div v-for="(opt, idx) in options" :key="opt.label">
              <form-select-button
                active
                :immediate="options[idx].selected"
                classes="mr-4 mb-2"
                :title="opt.label"
                :name="opt.id"
                pill
                @selected="selectAssistance(idx)"
              ></form-select-button>
            </div>
          </div>
          <form-text-input
            v-if="options[5].selected"
            area
            title="Other equipment details"
            name="equipment.details"
            placeholder="Please describe"
          ></form-text-input>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    FormSelectButton,
  },

  data() {
    return {
      options: [
        { id: 'equipment.options_1', label: 'Wheelchair', selected: false },
        { id: 'equipment.options_2', label: 'Frame', selected: false },
        { id: 'equipment.options_3', label: 'Bed', selected: false },
        { id: 'equipment.options_4', label: 'Hoist', selected: false },
        { id: 'equipment.options_5', label: 'Buggy', selected: false },
        { id: 'equipment.options_6', label: 'Other', selected: false },
      ],
    };
  },

  created() {
    // Inital selected state comes from the data source
    this.options.forEach(o => (o.selected = store.booking.find(o.id) || false));
  },

  methods: {
    load(id) {
      return store.booking.find(id) || false;
    },

    selectAssistance(idx) {
      this.options[idx].selected = !this.options[idx].selected;
    },
  },
};
</script>
