<template>
  <!--
  Yes. Dates are hard-coded for Summer 2021 because I didn't have time to do
  anything else and needed to get something functional when there are 20 days
  to squeeze into the UI.
  -->
  <div class="sticky top-0">
    <div
      class="flex justify-between w-full text-sm font-thin text-white bg-indigo-800 md:text-base"
    >
      <div class="grid w-full grid-cols-12 px-1">
        <div class="col-span-2">
          <div v-if="showEmail">
            <div class="bg-transparent">Email</div>
          </div>
          <div v-else>
            <div class="flex justify-end text-sm">
              <span class="pr-3 bg-transparent">Age</span>
            </div>
          </div>
        </div>

        <!-- flags, paid -->
        <div class="col-span-3">
          <div class="grid grid-cols-6">
            <div
              class="flex items-center justify-center col-span-1 text-sm text-center border-r border-white hover:cursor-pointer hover:bg-orange-400"
              :class="filterClass('transport')"
              @click="filter('transport')"
            >
              <span class="">Ep</span>
            </div>
            <div
              class="col-span-1 text-center bg-transparent border-r border-indigo-600 hover:cursor-pointer hover:bg-orange-400"
              :class="filterClass('equipment.options_1')"
              @click="filter('equipment.options_1')"
            >
              <span class="text-sm">Asth</span>
            </div>
            <div
              class="col-span-1 text-center bg-transparent border-r border-indigo-600 hover:cursor-pointer hover:bg-orange-400"
              :class="filterClass('behaviour.options_1')"
              @click="filter('behaviour.options_1')"
            >
              <span class="text-sm">Whc</span>
            </div>
            <div
              class="col-span-1 text-center bg-transparent hover:cursor-pointer hover:bg-orange-400"
              :class="filterClass('permissions.permission_7')"
              @click="filter('permissions.permission_7')"
            >
              <span class="text-sm">Bite</span>
            </div>
            <div
              class="col-span-1 text-center bg-transparent hover:cursor-pointer hover:bg-orange-400"
              :class="filterClass('permissions.permission_7')"
              @click="filter('permissions.permission_7')"
            >
              <span class="text-sm">New</span>
            </div>
            <div class="col-span-1 text-center bg-transparent">
              <span class="text-sm">
                Paid
              </span>
            </div>
          </div>
        </div>

        <!-- dates -->
        <div class="col-span-6">
          <div class="flex justify-between text-sm">
            <div
              v-for="(requested, did) in dates"
              :key="did"
              class="w-12 text-center bg-transparent"
            >
              {{ requested.split('-')[2] }}
            </div>
          </div>
        </div>

        <!-- admin controls -->
        <div class="col-span-1">
          <div class="grid grid-cols-4">
            <div class="col-span-1 bg-transparent"></div>
            <div class="flex items-center justify-center col-span-1 ">
              <admin-booking-button
                v-if="!approved"
                square
                :date="1"
                :paid="false"
                :requested="true"
                :clicked="toggled"
                @ticked="toggleApproveAll"
              ></admin-booking-button>
            </div>
            <div class="col-span-1 bg-transparent"></div>
            <div class="flex items-center justify-center w-5 h-5 col-span-1">
              <div class="w-5 h-5">
                <download-csv
                  :data="bookings"
                  :formatter="exportFormatter"
                  name="exported.csv"
                >
                </download-csv>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminBookingButton from '@/components/admin-booking-button.vue';
import { parseISO, format } from 'date-fns';
import DownloadCsv from '@/components/download-csv.vue';
import moment from 'moment';
import { store } from '@/store';
// import FormSortButtons from '@/components/form-sort-buttons.vue';

const formatter = booking => {
  if (booking['participant.surname'] === 'Burridge') {
    // eslint-disable-next-line
    console.log(JSON.stringify(booking, null, 2));
  }
  // Note - keys of this object are stringified as column headings in the export.
  const obj = {
    name: booking['participant.name'],
    surname: booking['participant.surname'],
    age: booking['participant.age'],
    epilepsy: booking['disability.epilepsy'].startsWith('y') ? 'Y' : '',
    diabetes: booking['disability.diabetes'].startsWith('y') ? 'Y' : '',
    // asthma: booking['disability.asthma'].startsWith('y') ? 'Y' : '',
    address: booking['address.address'].split('\n').join(','),
    postcode: booking['address.postcode'],
    email: booking.payment.userid,
    phone1: booking['carers.carer_one_mobilePhone'],
    phone2: booking['carers.emergency_contact_mobilePhone'],
  };

  const fmt = dt => format(parseISO(dt), 'dd/MM');
  const season = store.dates.get().map(dt => fmt(dt.date));
  const seasonValues = season.reduce((obj, d) => {
    obj[d] = '';
    return obj;
  }, {});

  for (const dt of booking['booking.dates']) {
    const idx = season.indexOf(fmt(dt));
    if (idx !== -1) {
      seasonValues[fmt(dt)] = booking['accepted'][idx] ? 'A' : 'R';
    }
  }

  // Return the object containing column headings and values.
  return {
    ...obj,
    ...seasonValues,
  };
};

export default {
  filters: {
    format_date(d) {
      return moment(d).format('D');
    },
  },

  components: {
    AdminBookingButton,
    DownloadCsv,
    // FormSortButtons,
  },

  props: {
    approved: { type: Boolean, default: false },
    bookings: { type: Array, default: () => [] },
    showEmail: { type: Boolean, default: false },
  },

  data() {
    return {
      dates: [], // in *this* component will be an array of dates

      exportFormatter: formatter,

      // Sortable columns are declared and tracked in here
      direction: {
        'participant.surname': 'asc',
        'participant.age': '',
      },

      // Keep track of which columns are currently being filtered
      filtering: {
        transport: false,
        'equipment.options_1': false,
        'behaviour.options_1': false,
        'permissions.permission_7': false,
        paid: false,
        dates: {},
      },

      // Define what must be true for a filter to return results in each of the
      // filterable columns
      filters: {
        transport: 'yes',
        'equipment.options_1': 'yes',
        'behaviour.options_1': 'yes',
        'permissions.permission_7': 'no',
        paid: 'yes',
      },

      showPaid: 'Y',

      toggled: false,
    };
  },

  computed: {
    isFiltering() {
      const keys = Object.keys(this.filtering).filter(k => k !== 'dates');
      return keys.some(Boolean);
    },
  },

  created() {
    this.dates = store.dates.get().map(dt => dt.date);
  },

  methods: {
    filterClass(name, val = null) {
      if (name === 'dates') {
        if (this.filtering[name][val]) {
          return 'bg-orange-400 text-white font-semibold';
        }
      } else {
        if (this.filtering[name]) {
          return 'bg-orange-400 text-white font-semibold';
        }
      }
      return 'text-white';
    },

    sort(name) {
      //!
      //TODO There is a bug where clicking the age to sort, clicking surname
      //TODO and then back on age makes the age arrow the opposite of what it
      //TODO should be. It's because the grid component always toggles sort order
      //TODO even when the column has been sorted before.
      //TODO Leaving it for now because it's not major and I have loads to do!
      //!

      const opposite = old => (old === 'dsc' ? 'asc' : 'dsc');

      const prevDirection = this.direction[name];

      // Reset all icons to neutral
      Object.keys(this.direction).forEach(k => (this.direction[k] = ''));

      if (prevDirection === '') {
        // Clicked on a previously unclicked column.
        this.direction[name] = 'dsc';
      } else {
        // Toggle the icon of a selected column direction
        this.direction[name] = opposite(prevDirection);
      }

      this.$emit('sort', name);
    },

    toggleApproveAll() {
      this.toggled = !this.toggled;
      this.$emit('toggle', this.toggled);
    },

    // This is a filter within a filter. If user wants to filter based on whether
    // booking has been paid, they first click the paid filter and may then
    // switch between showing all those who have paid and all those who have not.
    // Clicking the main paid toggle simply removes all filtering.
    togglePaid() {
      this.showPaid = this.showPaid === 'Y' ? 'N' : 'Y';
      this.filters['paid'] = this.showPaid === 'Y' ? 'yes' : 'no';
      this.$emit('filter', 'paid', this.filters['paid']);
    },

    // First stab - used for Y/N columns so clicking filter will show only Y
    // Clicking again removes the filter for that column but doesn't touch any
    // other active filters.
    // Filtering is an all or nothing thing. If filtering then match against the
    // value declared in the filters object. If filtering is switched off then
    // tell the parent to ignore this column in filtering i.e. it is not
    // possible to filter for all Y or all N - either all Y or everything.
    filter(name) {
      this.filtering[name] = !this.filtering[name];
      this.$emit(
        'filter',
        name,
        this.filtering[name] ? this.filters[name] : 'off'
      );
    },

    // First stab - decided to allow filtering on one date only at any time.
    filterDate(idx) {
      // This resets all dates to false
      const reset = obj => {
        Object.keys(obj).forEach(k => (obj[k] = false));
        return obj;
      };

      const dt = this.dates[idx]; // e.g. 2020-02-10

      // The date to be filtered may not already have been clicked but if it has
      // toggle it.
      const toggle = this.filtering.dates[dt]
        ? !this.filtering.dates[dt]
        : true;

      // Reset the entire date filter object to false, apart from the one just
      // clicked
      this.filtering.dates = { ...reset(this.filtering.dates), [dt]: toggle };

      // Send the only selected date to the parent grid component
      const filterDate = Object.entries(this.filtering['dates'])
        .map(([k, v]) => (v ? k : ''))
        .filter(b => b);

      this.$emit('filter', 'dates', filterDate[0]);
    },
  },
};
</script>

<style>
body * {
  outline: 0px solid red !important;
}
</style>
