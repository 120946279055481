<template>
  <div
    ref="button"
    class="flex flex-col items-center w-40 h-24 mb-2 mr-2 rounded md:w-32 md:h-24 md:mr-4 md:mb-4"
    :class="buttonStyle"
    @click="select"
  >
    <div :name="name" class="mt-1 text-base">{{ fmtDate }}</div>
    <div class="">
      <span class="text-xs">&#163;{{ cost }}</span>
    </div>
    <div
      v-if="date.text"
      ref="info"
      class="flex items-center justify-center px-1 text-center"
    >
      <div class="w-full text-xs font-hairline">{{ date.text }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { store } from '@/store.js';

export default {
  props: {
    accepted: { type: Boolean, default: false },
    date: { type: Object, default: () => {} },
  },

  data() {
    return {
      enabled: true,
      name: this.date.date,
      requestLocked: store.request.locked(),
      selected: this.date.on || false,
    };
  },

  computed: {
    // This prop is a bit of a hack to allow for the case where the request is
    // locked i.e. the user has submitted it *and* the last date for bookings
    // has passed. I wanted to be able to distinguish between dates that were
    // requested (show as gray after submission and before the date when all
    // confirmation emails are sent), requested but not accepted (show as white
    // after the confirmation date) and accepted (show as green as soon as they
    // are accepted i.e. on the confirmation date)
    bookingsConfirmed() {
      if (!process.env.VUE_APP_CONF_DATE) return false;
      const today = process.env.VUE_APP_DEV_TODAY
        ? moment(process.env.VUE_APP_DEV_TODAY)
        : moment();
      return today.isAfter(process.env.VUE_APP_CONF_DATE);
    },

    cost() {
      // return this.date.text ? '15' : '30';
      return this.date.cost / 100;
    },

    fmtDate() {
      return moment(this.date.date).format('ddd, MMM D');
    },

    buttonStyle() {
      if (this.requestLocked) {
        let classes = ' cursor-not-allowed';

        if (this.bookingsConfirmed) {
          if (this.accepted) {
            classes += ' font-semibold bg-green-500 text-white';
          } else {
            classes += ' border ';
            classes += this.selected
              ? ' bg-orange-300 text-orange-700 border-orange-700'
              : ' bg-white text-gray-400 border-gray-400';
          }
        } else {
          if (this.accepted) {
            classes += ' font-semibold bg-green-500 text-white';
          } else {
            classes += this.selected
              ? ' font-semibold bg-gray-600 text-gray-100'
              : ' bg-white text-gray-400 border border-gray-400';
          }
        }
        return classes;
      }

      // Looks blue even if disabled
      if (this.selected) return 'exg--btn-selected';
      // Not selected, but can it be? Either pale blue or gray.
      return this.enabled ? 'exg--btn-active' : 'exg--btn-inactive';
    },

    // Has the date already been chosen? Switch it on if it has...
    initialOn() {
      return this.date.on ? true : false;
    },
  },

  methods: {
    /**
     * Force will be true when the parent section detects that the date of
     * birth has been removed. It will call the disable(true) method which
     * must then take care of resetting all dates, date UI etc.
     */
    disable(force = false) {
      if (force) {
        // Switch off the date component (will also disable below)
        this.selected = false;
      }

      // If disabled got set on a selected button it would cause the child
      // component to fail.
      if (!this.selected) this.enabled = false;
    },

    enable() {
      this.enabled = true;
    },

    select() {
      // Clicking will work always for admins or before submission for parents
      if (this.requestLocked) return;

      // Once a maximum number of dates have been chosen, every date is disabled.
      // From then, only selected dates can be deselected.
      if (!this.enabled && !this.selected) return;

      this.selected = !this.selected;

      // Tell the parent to add to or subtract from the no of booked dates
      this.$emit(this.selected ? 'selected' : 'deselected', this.date);
    },
  },
};
</script>
