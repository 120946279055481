<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <!-- prettier-ignore -->
          <template #title>Toileting</template>
          <template #info>
            <div class="hidden lg:block flex flex-col">
              <p>Please select as appropriate</p>
            </div>
          </template>
        </form-section-header>
        <info-warning>
          <template #text>
            <div class="flex justify-center text-xs md:text-base">
              <span
                >Please provide nappies or pads if your child uses them</span
              >
            </div>
          </template>
        </info-warning>
      </template>

      <template #form-body>
        <div
          id="toileting"
          class="flex flex-col flex-wrap md:flex-row px-2 lg:px-0 md:mb-4"
        >
          <div class="flex flex-wrap">
            <div v-for="(opt, idx) in options" :key="opt.label">
              <form-select-button
                active
                classes="mr-4 mb-2"
                :immediate="options[idx].selected"
                :title="opt.label"
                :name="opt.id"
                pill
                @selected="selectToileting(idx)"
              ></form-select-button>
            </div>
          </div>

          <form-text-input
            :area="true"
            name="toileting.special_equipment"
            title="Is there a need for a specific routine or special equipment?"
            placeholder="Please describe"
          ></form-text-input>

          <form-text-input
            :area="true"
            name="toileting.extra_info"
            title="Anything else you feel we should be aware of?"
            placeholder="Please give details"
          ></form-text-input>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    FormSelectButton,
  },

  data() {
    return {
      options: [
        {
          id: 'toileting.options_1',
          label: 'Fully independent',
          selected: false,
        },
        {
          id: 'toileting.options_2',
          label: 'Wears pads, full assistance required',
          selected: false,
        },
        {
          id: 'toileting.options_3',
          label: 'Requires hoisting',
          selected: false,
        },
        {
          id: 'toileting.options_4',
          label: 'Requires a bed for changing',
          selected: false,
        },
        {
          id: 'toileting.options_5',
          label: 'Some physical support with wiping, dressing etc.',
          selected: false,
        },
        {
          id: 'toileting.options_6',
          label: 'Verbal prompts only (i.e. to wash hands)',
          selected: false,
        },
        {
          id: 'toileting.options_7',
          label: 'Toilet trained with occassional accidents',
          selected: false,
        },
      ],
    };
  },

  created() {
    // Inital selected state comes from the data source
    this.options.forEach(o => (o.selected = store.booking.find(o.id) || false));
  },

  methods: {
    selectToileting(idx) {
      this.options[idx].selected = !this.options[idx].selected;
    },
  },
};
</script>
