import AdminController from '@/controllers/admin-controller.vue';
import BookingFormController from '@/controllers/booking-form-controller.vue';
import Faq from '@/views/Faq.vue';
import Forgotten from '@/views/Forgotten.vue';
import HomeScreenController from '@//controllers/home-screen-controller.vue';
import LoginForm from '@/views/login-form.vue';
import PasswordSet from '@/views/PasswordSet.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import Register from '@/views/Register.vue';
import Success from '@/views/Success.vue';
import Router from 'vue-router';
import Vue from 'vue';

import jwtdecode from 'jwt-decode';
import { store } from '@/store.js';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'root',
      beforeEnter(to, from, next) {
        localStorage.removeItem('token');
        next('/login');
      },
    },
    {
      path: '/login',
      name: 'login-form',
      component: LoginForm,
      beforeEnter(to, from, next) {
        localStorage.removeItem('token');
        next();
      },
    },
    { path: '/home', name: 'home-screen', component: HomeScreenController },
    {
      path: '/booking',
      name: 'booking-form',
      component: BookingFormController,
      beforeEnter(to, from, next) {
        return store.request.token.get() ? next() : next(false);
      },
    },
    { path: '/faq', name: 'faq', component: Faq },
    {
      path: '/admin/booking',
      name: 'admin-booking',
      component: AdminController,
      beforeEnter(to, from, next) {
        const token = store.request.token.get();
        if (!token || jwtdecode(token).role !== 'admin') {
          alert('Unauthorized access');
          next('/login');
        } else {
          next();
        }
        next();
      },
    },
    { path: '/register', name: 'register', component: Register },
    { path: '/forgot', name: 'forgotten', component: Forgotten },
    { path: '/welcome', component: PasswordSet },
    { path: '/reset', component: PasswordReset },

    // Stripe callback endpoints
    {
      path: '/success',
      name: 'success',
      component: Success,
    },
  ],
});
