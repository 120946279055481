<template>
  <navbar-container>
    <!-- booking can be amended -->
    <!-- If user is admin then locked will always be false -->
    <template v-if="!locked" slot="tabs">
      <template v-if="!admin">
        <!-- If a parent gets here then they are still working on the form -->
        <navbar-parent-edit
          :can-add-child="canAddChild"
          :can-submit="canSubmit"
          @submit="submit"
          @addChild="addChild"
          @clearForm="clearForm"
          @closeView="closeView"
        ></navbar-parent-edit>
      </template>

      <!-- If admin gets here then they have chosen to edit a booking -->
      <template v-else>
        <navbar-admin-edit
          @updateBooking="updateBooking"
          @closeView="closeView"
        ></navbar-admin-edit>
      </template>
    </template>
    <template v-else slot="tabs">
      <!-- Booking is locked -->
      <template v-if="paid">
        <!-- After parent has paid give access to the Stripe receipt (admin or parent) -->
        <navbar-booking-paid
          :receipt="hasReceipt"
          @showReceipt="showReceipt"
          @closeView="closeView"
        ></navbar-booking-paid>
      </template>
      <template v-else>
        <template v-if="admin || !canBePaid">
          <!-- booking has been submitted but not yet allocated dates -or- admin viewing -->
          <div class="flex w-full justify-end items-center">
            <navbar-close @closeView="closeView"></navbar-close>
          </div>
        </template>
        <template v-else>
          <!-- Pay for booking - dates have been allocated by admin and email sent -->
          <navbar-parent-pay
            @payNow="payNow"
            @payVoucher="payVoucher"
            @closeView="closeView"
          >
          </navbar-parent-pay>
        </template>
      </template>
    </template>
  </navbar-container>
</template>

<script>
import { eventBus } from '@/main.js';
import NavbarBookingPaid from '@/components/navbar-booking-paid.vue';
import NavbarContainer from '@/components/navbar-container.vue';
import NavbarClose from '@/components/navbar-close.vue';
import NavbarAdminEdit from '@/components/navbar-admin-edit.vue';
import NavbarParentEdit from '@/components/navbar-parent-edit.vue';
import NavbarParentPay from '@/components/navbar-parent-pay.vue';
import { store } from '@/store.js';

export default {
  components: {
    NavbarBookingPaid,
    NavbarContainer,
    NavbarClose,
    NavbarAdminEdit,
    NavbarParentEdit,
    NavbarParentPay,
  },

  props: {
    canAddChild: { type: Boolean, default: false },
    canSubmit: { type: Object, default: () => {} },
    season: { type: String, default: '' },
    locked: { type: Boolean, default: false },
  },

  data() {
    return {
      admin: store.request.token.admin(),
      canBePaid: store.payment.canBePaid(),
      hasReceipt: store.payment.hasReceipt(),
      isOpen: !this.locked,
      paid: store.payment.hasBeenPaid(),
    };
  },

  methods: {
    addChild() {
      this.$emit('addChild');
    },

    clearForm() {
      this.$emit('clearForm');
    },

    closeView() {
      // Clear the listener otherwise it will catch the event multiple times.
      eventBus.$off();
      const route = this.admin ? 'admin-booking' : 'home-screen';
      this.$router.push({ name: route });
    },

    logout() {
      this.$emit('logout');
    },

    payNow() {
      this.$emit('pay');
    },

    payVoucher() {
      this.$emit('voucher');
    },

    showReceipt() {
      this.$emit('show-receipt');
    },

    updateBooking() {
      // Safety check only - should not be able to get here unless admin.
      if (this.admin) {
        eventBus.$emit('admin-update-booking');
      }
    },

    submit() {
      this.$emit('submit');
    },
  },
};
</script>
