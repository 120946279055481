<template>
  <div>
    <div class="">
      <admin-navbar>
        <template v-slot:search>
          <form id="search">
            <div class="flex items-center">
              <div class="mr-2">
                <span class="font-semibold text-indigo-500">Search</span>
              </div>
              <div class="h-full">
                <input
                  v-model="searchQuery"
                  class="px-1 text-indigo-400 border-2 border-gray-400 rounded-md"
                  name="query"
                />
              </div>
            </div>
          </form>
        </template>

        <template v-slot:tabs>
          <div
            v-if="approved.length > 0"
            class="flex items-center justify-center w-48 h-10 exg--navbar-tab"
            @click="approveBookings"
          >
            <span class="exg--navbar-tab-text">{{ title }}</span>
          </div>
        </template>
      </admin-navbar>
    </div>
    <admin-booking
      v-if="booking_route && bookings.length"
      :bookings="bookings"
      :season="season"
      :search-query="searchQuery"
      :totals="totals"
    ></admin-booking>

    <Modal v-if="approving">
      <div slot="header">
        <div
          class="flex items-center justify-around px-2 py-1 text-xl font-semibold text-orange-100 bg-orange-400"
        >
          <span>Approve bookings - Summer 2023</span>
        </div>
      </div>
      <div
        v-if="processing > 0"
        slot="body"
        class="flex flex-col items-center justify-center text-center"
      >
        <h4 class="inline-flex my-6 font-semibold text-indigo-500 uppercase">
          <span>Processing {{ processing }}/{{ approved.length }}</span>
        </h4>
        <h5 class="inline-flex mb-6 font-semibold text-indigo-300 uppercase">
          <span>Please wait...</span>
        </h5>
      </div>
      <div
        v-else
        slot="body"
        class="flex flex-col items-center justify-center text-center"
      >
        <h4 class="inline-flex mt-6 font-semibold text-indigo-500 uppercase">
          You are about to approve {{ approved.length }} booking{{
            approved.length === 1 ? '' : 's'
          }}
        </h4>
        <div
          class="px-6 py-2 mt-6 text-lg font-semibold text-red-400 uppercase "
        >
          <span>Are you sure?</span>
        </div>
        <div class="flex items-center">
          <div
            class="px-16 py-2 my-6 mr-12 font-semibold text-white uppercase bg-green-500 rounded hover:bg-green-600 "
            @click="process()"
          >
            <span>Yes</span>
          </div>
          <div
            class="px-16 py-2 my-6 text-base font-semibold text-white uppercase bg-red-400 rounded hover:bg-red-600"
            @click="approving = false"
          >
            <span>No</span>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import AdminBooking from '@/views/admin-booking.vue';
import AdminNavbar from '@/components/admin-navbar.vue';
import { eventBus } from '@/main.js';
import middleware from '@/middleware/booking';
import Modal from '@/components/Modal.vue';
import { store } from '@/store.js';
// -- other admin functions go here

const BOOKING_ROUTE = 'admin-booking';

export default {
  components: {
    AdminBooking,
    AdminNavbar,
    Modal,
  },

  data() {
    return {
      // Keep track of the ids of approved bookings
      approved: [],

      // Display modal overlay when admin clicks the approve button
      approving: false,

      // Control which of the admin views to go to, based on the route used to
      // get here
      booking_route: this.$route.name === BOOKING_ROUTE,

      bookings: [],
      processing: 0,
      season: store.dates.get().map(dt => dt.date),
      season_id: 8,
      searchQuery: '',
      totals: [],
    };
  },

  computed: {
    title() {
      const count = this.approved.length;
      return `Accept ${count} booking${count > 1 ? 's' : ''}`;
    },
  },

  async mounted() {
    // All bookings are returned here, passed through some middleware to add
    // admin-only elements and then used to render the admin screen.
    if (this.booking_route) {
      try {
        // Gets an array of all bookings only
        const payload = await this.$http.get(
          `season/${this.season_id}/bookings`
        );
        const bookings = payload.data.data;

        // Partially apply a function with receipts (at the request level - all
        // requests)
        const set_receipts = middleware.set_receipts(payload.data.receipts);

        // Chain together transformations which supplement each booking with
        // requested dates, childrens ages, flags/scores and payment data
        this.bookings = set_receipts(
          middleware.set_flags(
            middleware.set_requested_dates(middleware.calculate_age(bookings))
          )
        );

        // Create an array of totals shown after the list of bookings
        this.totals = middleware.calculate_totals(bookings);
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        this.$router.push({ name: 'login-form' });
      }
    }

    // Admin will actually have requested this from within the main navbar.vue
    // component so using the event bus here as a way to connect two components
    // which are not in the same dependency tree.
    eventBus.$on('admin-update-booking', () => {
      const json = { ...store.request.json() };
      const id = json.bookings[0]['booking_id'];

      // Passing these FK back in the PUT request crashes it.
      delete json.bookings[0]['request.id'];
      delete json.bookings[0]['booking_id'];

      this.$http
        .put(`bookings/${id}`, json)
        .then(() => {
          // eslint-disable-next-line
          console.log('request updated');
        })
        .catch(e => {
          // eslint-disable-next-line
          console.log('error updating request', e.message);
        })
        .finally(() => {
          // Using an event bus doesn't clear events after consumption.
          eventBus.$off();
          this.$router.push({ name: 'home-screen' });
        });
    });

    /**
     * Listen for the grid to send a list of the PKs being accepted. As soon as
     * there is an entry, the approve button gets enabled.
     */
    eventBus.$on('approved', rows => {
      this.approved = [...rows];
    });
  },

  methods: {
    approveBookings() {
      this.approving = true;
    },

    async process() {
      this.processing = 0;
      await this._sendApprovals([...this.approved]);
      this.$router.push({ name: 'home-screen' });
    },

    async _sendApprovals(ids) {
      if (ids.length === 0) return;

      const send = async id => {
        return await this.$http.post(`admin/accept/booking/${id}`);
      };

      const id = ids.shift();

      await send(id);
      this.processing += 1;
      await this._sendApprovals(ids);
    },
  },
};
</script>
