<template>
  <div>
    <form-section>
      <template #form-header>
        <form-section-header>
          <!-- prettier-ignore -->
          <template #title>Feeding</template>
          <template #info>
            <span class="hidden lg:block">
              Please choose as many as apply
            </span>
          </template>
        </form-section-header>
        <info-alert>
          <template #text>
            <div class="flex flex-col items-center w-full text-xs md:text-base">
              <span class="w-full">
                Lunchboxes must be provided by parents/carers and labelled
              </span>
              <span class="font-extrabold">
                Please note there is no facility for heating food or
                refrigeration of lunch packs.
              </span>
            </div>
          </template>
        </info-alert>
      </template>

      <template #form-body>
        <div class="flex flex-col flex-wrap md:flex-row px-2 lg:px-0 md:mt-6">
          <div class="flex flex-wrap">
            <div
              v-for="(opt, idx) in options"
              :key="opt.label"
              class="flex md:flex-row flex-col items-center"
            >
              <form-select-button
                active
                :immediate="options[idx].selected"
                classes="mr-4 mb-2"
                :title="opt.label"
                :name="opt.id"
                pill
                @selected="selectAssistance(idx)"
              >
              </form-select-button>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="idx === 3 && options[3].selected"
                class="px-2 py-1 rounded-lg bg-green-200 text-green-500 font-semibold italic"
                v-html="gastronomy"
              ></div>
              <!-- eslint-enable -->
            </div>
          </div>
          <form-text-input
            :area="true"
            class="mt-4"
            name="feeding.more_info"
            :title="title('moreInfo')"
          ></form-text-input>
        </div>
      </template>
    </form-section>
  </div>
</template>

<script>
import FormSelectButton from '@/components/form-select-button.vue';
import { store } from '@/store.js';

export default {
  components: {
    FormSelectButton,
  },

  data() {
    return {
      gastronomy: '',
      options: [
        {
          id: 'feeding.options_1',
          label: 'Fully independent',
          selected: false,
        },
        {
          id: 'feeding.options_2',
          label: 'Assistance in opening packets/cartons etc.',
          selected: false,
        },
        {
          id: 'feeding.options_3',
          label: 'Spoon feeding required',
          selected: false,
        },
        {
          id: 'feeding.options_4',
          label: 'Gastrostomy / NG tube',
          selected: false,
        },
      ],
    };
  },

  created() {
    // Inital selected state comes from the data source
    this.options.forEach(o => (o.selected = store.booking.find(o.id) || false));
    this.gastronomy = `You may be asked to discuss your child's feeding plan with the management team on your child's first day.`;
  },

  methods: {
    selectAssistance(idx) {
      this.options[idx].selected = !this.options[idx].selected;
    },

    title(name) {
      return {
        allergiesDetails: 'Description of allergy/intolerance',
        moreInfo: 'Anything else you feel we should be aware of?',
      }[name];
    },
  },
};
</script>
